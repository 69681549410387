import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtom, useSetAtom } from "jotai";
import { calculateContainerCurrentWidth } from "src/adapters/algoStep0";
import { Color, Flex, Text } from "src/elements";

import { algoS2FreezerItemLabelCdtsRegex } from "@CommonUtils/pog/container";
import {
  planogramFixturesModeAtom,
  planogramHoveredContainerAtom,
} from "src/components/PlanogramExtensions/store/atoms";
import { InnerWrapper } from "../../common/InnerWrapper";
import { DragBounds, DragModifications, Scaleable, Snappable } from "../../store/types";
import { Draggable } from "../Draggable/Draggable";
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";

interface Props extends Scaleable {
  bounds?: DragBounds;
  snappables: Snappable[];
  isEditable?: boolean;
  isContainersFullDetails: boolean;
  containersAtom: PrimitiveAtom<PlanogramContainer[]>;
  pogAtom: PrimitiveAtom<Pog>;
}

export const Containers = ({
  scaleX,
  scaleY,
  bounds,
  snappables,
  isEditable,
  isContainersFullDetails,
  containersAtom,
  pogAtom,
}: Props) => {
  const [containers, setContainers] = useAtom(containersAtom);
  const [pog] = useAtom(pogAtom);
  const [fixturesMode] = useAtom(planogramFixturesModeAtom);
  const setHoveredContainer = useSetAtom(planogramHoveredContainerAtom);

  const getSnappables = (containerToIgnore: PlanogramContainer) => {
    const containersSnappables: Snappable[] = [];

    containers
      .filter((container) => container.uniqueId !== containerToIgnore.uniqueId)
      .forEach((container) => {
        containersSnappables.push({
          xLeft: container.xLeft,
          xRight: container.xRight,
          yBottom: container.yBottom,
          yTop: container.yTop,
        });
      });

    return [...snappables, ...containersSnappables];
  };

  const onModify = (container: PlanogramContainer, modifications: DragModifications) => {
    setContainers((containers) => {
      container.xRight = modifications.xRight;
      container.yBottom = modifications.yBottom;
      container.yTop = modifications.yTop;
      container.xLeft = modifications.xLeft;

      // Container has algo target width requirements.
      if (container.algoCurrentTargetWidth !== undefined) {
        container.algoCurrentTargetWidth = calculateContainerCurrentWidth(container, pog.planogram);
      }

      // Add modified container as last to the list to make sure the container gets priority in HTML structure.
      return [...containers.filter((c) => c.uniqueId !== container.uniqueId), container];
    });
  };

  return (
    <InnerWrapper isTranslucent={fixturesMode} className="containers">
      {containers.map((container, containerNo) => {
        const isInvalid =
          container.algoMinTargetWidth !== undefined &&
          container.algoCurrentTargetWidth !== undefined &&
          container.algoCurrentTargetWidth < container.algoMinTargetWidth;

        let label = container.label;

        if (!isContainersFullDetails) {
          const cdts = algoS2FreezerItemLabelCdtsRegex.exec(container.label);
          if (cdts?.groups) {
            label = cdts.groups.cdt2;
          }
        }

        return (
          <Draggable
            key={containerNo}
            uniqueId={container.uniqueId}
            xLeft={container.xLeft}
            xRight={container.xRight}
            yBottom={container.yBottom}
            yTop={container.yTop}
            scaleX={scaleX}
            scaleY={scaleY}
            color={container.color}
            bounds={bounds}
            snappables={getSnappables(container)}
            isDraggable={!fixturesMode && isEditable}
            isResizeable={!fixturesMode && isEditable}
            isHoverSensitive={isEditable || !isContainersFullDetails}
            isTranslucent={fixturesMode}
            onModify={(modifications) => {
              onModify(container, modifications);
            }}
            onEnter={() => setHoveredContainer(container)}
            onLeave={() => setHoveredContainer(null)}
            overflow="hidden"
          >
            <Flex direction="column" justify="center">
              <Text
                variant="caption2"
                color={isInvalid ? Color.red : Color.white}
                textAlign="center"
              >
                {label}
              </Text>

              {container.algoCurrentTargetWidth !== undefined &&
                container.algoMinTargetWidth !== undefined && (
                  <Text variant="caption2" color={isInvalid ? Color.red : Color.primary}>
                    <Text variant="caption2" color="inherit">
                      {container.algoCurrentTargetWidth}
                    </Text>

                    <Text variant="caption2" color="inherit">
                      /
                    </Text>

                    <Text variant="caption2" color="inherit">
                      {container.algoMinTargetWidth}
                    </Text>
                  </Text>
                )}
            </Flex>
          </Draggable>
        );
      })}
    </InnerWrapper>
  );
};
