import styled from "styled-components";

import { StatPanel } from "./components/StatPanel";
import { Color } from "../Color/Color";

const ROW_PADDING = "10px";

export const ControlPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${ROW_PADDING};
  border-radius: 4px;
  border: 1px solid ${Color.lightGrayHover};
`;

export const ControlPanelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${ROW_PADDING};

  :first-of-type {
    padding-bottom: ${ROW_PADDING};
  }

  :not(:first-of-type) {
    border-top: 1px solid ${Color.lightGrayHover};
    padding-top: ${ROW_PADDING};
  }
`;

export const ControlPanelButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export { StatPanel };
