export const RoutePostUsers = "/users";

export interface PayloadPostUsers {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResponsePostUsers {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  userType: "MERCHANT_USER";
  merchantId: number;
  createdBy: null;
  createdAt: string;
  updatedAt: string;
}
