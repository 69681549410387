export const RouteGetStoresCategoriesList = "/stores/categories/list";

export interface ParamsGetStoresCategoriesList {
  storeId: number;
}

export type ResponseGetStoresCategoriesList = {
  id: number;
  code: string;
  guideline_id: number;
}[];
