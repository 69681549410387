import { DragBounds } from "../../../store/types";

interface Props extends DragBounds {
  bounds: DragBounds;
}

export const moveBoundsChecker = ({ xLeft, xRight, yBottom, yTop, bounds }: Props) => {
  if (!bounds) {
    return { xLeft, xRight, yBottom, yTop };
  }

  const width = xRight - xLeft;
  const height = yTop - yBottom;

  // Horizontal move bounds.
  if (xLeft < bounds.xLeft) {
    xLeft = bounds.xLeft;
    xRight = bounds.xLeft + width;
  } else if (xRight > bounds.xRight) {
    xRight = bounds.xRight;
    xLeft = bounds.xRight - width;
  }

  // Vertical move bounds.
  if (yBottom < bounds.yBottom) {
    yBottom = bounds.yBottom;
    yTop = bounds.yBottom + height;
  } else if (yTop > bounds.yTop) {
    yTop = bounds.yTop;
    yBottom = bounds.yTop - height;
  }

  return {
    xLeft,
    xRight,
    yBottom,
    yTop,
  };
};

export const resizeBoundsChecker = ({
  xLeft,
  xRight,
  yBottom,
  yTop,
  bounds: { xLeft: boundsXLeft, xRight: boundsXRight, yBottom: boundsYBottom, yTop: boundsYTop },
}: Props) => {
  // Horizontal move bounds.
  if (xLeft < boundsXLeft) {
    xLeft = boundsXLeft;
  } else if (xRight > boundsXRight) {
    xRight = boundsXRight;
  }

  // Vertical move bounds.
  if (yBottom < boundsYBottom) {
    yBottom = boundsYBottom;
  } else if (yTop > boundsYTop) {
    yTop = boundsYTop;
  }

  return {
    xLeft,
    xRight,
    yBottom,
    yTop,
  };
};
