import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { storePlanogramRulesDataAtom } from "./atoms";

export const useStorePlanogramRulesModal = () => {
  const { openModal } = useModals();
  const setStorePlanogramRulesData = useSetAtom(storePlanogramRulesDataAtom);

  const openStorePlanogramRules = (data: {
    merchflowId: number;
    basePogId: number;
    isBasePogFavorited: boolean;
  }) => {
    setStorePlanogramRulesData(data);
    openModal("ModalStorePlanogramRules");
  };

  return { openStorePlanogramRules };
};
