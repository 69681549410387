import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtomValue } from "jotai";

import { EditShelf } from "./components/EditShelf";
import { NoShelf } from "./components/NoShelf";
import { planogramSelectedShelfAtom } from "src/components/PlanogramExtensions/store/atoms";

interface Props {
  pogAtom: PrimitiveAtom<Pog>;
}

export const NewShelf = ({ pogAtom }: Props) => {
  const selectedShelf = useAtomValue(planogramSelectedShelfAtom);

  return selectedShelf ? (
    <EditShelf shelf={selectedShelf} pogAtom={pogAtom} />
  ) : (
    <NoShelf pogAtom={pogAtom} />
  );
};
