import React, { useEffect, useState } from "react";

export const useHoverClickable = (
  ref: React.RefObject<HTMLElement>,
  additional?: {
    onEnter?: (event: PointerEvent) => void;
    onLeave?: (event: PointerEvent) => void;
  },
) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isActiveHelper, setIsActiveHelper] = useState(false);

  const onEnter = (event: PointerEvent) => {
    setIsHovered(true);
    setIsActive(isActiveHelper);

    if (additional?.onEnter) {
      additional.onEnter(event);
    }
  };

  const onLeave = (event: PointerEvent) => {
    setIsHovered(false);
    setIsActive(false);

    if (additional?.onLeave) {
      additional.onLeave(event);
    }
  };

  const onDown = () => {
    setIsActive(true);
    setIsActiveHelper(true);
  };

  const onUp = () => {
    setIsActive(false);
    setIsActiveHelper(false);
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onUnfocus = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    ref.current?.addEventListener("pointerenter", onEnter);
    ref.current?.addEventListener("pointerleave", onLeave);
    ref.current?.addEventListener("pointerdown", onDown);
    ref.current?.addEventListener("pointerup", onUp);
    ref.current?.addEventListener("focus", onFocus);
    ref.current?.addEventListener("blur", onUnfocus);

    return () => {
      ref.current?.removeEventListener("pointerenter", onEnter);
      ref.current?.removeEventListener("pointerleave", onLeave);
      ref.current?.removeEventListener("pointerdown", onDown);
      ref.current?.removeEventListener("pointerup", onUp);
      ref.current?.removeEventListener("focus", onFocus);
      ref.current?.removeEventListener("blur", onUnfocus);
    };
  }, [ref]);

  return { isHovered, setIsHovered, isActive, setIsActive, isFocused };
};
