import React, { useRef } from "react";
import styled from "styled-components";

import { Flex } from "../Box/Box";
import { InnerInput } from "./components/InnerInput";
import { InputContainer } from "./components/InputContainer";
import { InputOuterContainer } from "./components/InputOuterContainer";
import { InputTitle } from "./components/InputTitle";
import { useHoverClickable } from "src/utils/hoverClickable";

const ColorInputElement = styled(Flex)`
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  align-self: center;
  justify-self: center;
`;

const ColorInputPicker = styled("input")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const ColorInputBubble = ({
  color,
  setColor,
}: {
  color: string;
  setColor: (color: string) => void;
}) => {
  const refTimeout = useRef<NodeJS.Timeout | null>(null);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value;

    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
    }

    refTimeout.current = setTimeout(() => {
      setColor(color);
    }, 20);
  };

  return (
    <Flex position="relative" cursor="pointer">
      <ColorInputElement background={color}></ColorInputElement>

      <ColorInputPicker type="color" value={color} onChange={onChange} />
    </Flex>
  );
};

interface Props {
  color: string;
  setColor: (color: string) => void;
  variant?: "bubble" | "input";
  width?: string;
  isRequired?: boolean;
}

export const ColorInput = ({
  color,
  setColor,
  variant = "bubble",
  width = "120px",
  isRequired,
}: Props) => {
  const refInput = useRef<HTMLInputElement>(null);
  const { isHovered, isFocused } = useHoverClickable(refInput);

  if (variant === "input") {
    return (
      <InputOuterContainer width={width}>
        <InputContainer
          width={width}
          isHovered={isHovered}
          isFocused={isFocused}
          isDisabled={false}
          isBorderless={false}
        >
          <InnerInput
            refInput={refInput}
            type="text"
            value={color}
            setValue={() => {}}
            isDisabled={false}
            isSoftDisabled={true}
            isFocusedAutomatically={false}
          />

          <Flex position="absolute" right="5px">
            <ColorInputBubble color={color} setColor={setColor} />
          </Flex>

          <InputTitle label="Color" isRequired={isRequired} />
        </InputContainer>
      </InputOuterContainer>
    );
  }

  return <ColorInputBubble color={color} setColor={setColor} />;
};
