import { Page } from "src/components";

import { StoreSummaryList } from "./components/StoreSummaryList";

const StoreSummaryPage = () => {
  return (
    <Page>
      <StoreSummaryList />
    </Page>
  );
};

export default StoreSummaryPage;
