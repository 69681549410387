import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { ReportData } from "src/hooks/usePowerBiReport";
import { omniAtom } from "src/utils/atoms";

const prefix = "merchandise";

export const emptyContainersAtom = omniAtom({
  key: "step4Pog/containerAtom",
  value: [] as PlanogramContainer[],
});

export const powerBiReportsAtom = omniAtom({
  prefix,
  key: "powerBiReports",
  value: null as ReportData | null,
});
