export const RouteGetMerchflows_MerchflowId_HighlightsBasePogs =
  "/merchflows/:merchflowId/highlights/base-pogs";

export const RoutePutMerchflows_MerchflowId_HighlightsBasePogs =
  "/merchflows/:merchflowId/highlights/base-pogs";

export type PayloadPutMerchflows_MerchflowId_HighlightsBasePogs = Highlight[];

export type ResponseGetMerchflows_MerchflowId_HighlightsBasePogs = Highlight[];

export type ResponsePutMerchflows_MerchflowId_HighlightsBasePogs = unknown;

export interface Highlight {
  id: string;
  enabled: boolean;
  name: string;
  color: string;
  formula: string;
}
