import * as THREE from "three";
import { getPositionRelativeToMesh } from "../store/utils";
import { useEffect } from "react";

interface Props {
  scene: THREE.Scene;
  parentMesh: THREE.Mesh;
  width: number;
  height: number;
  depth: number;
  wallThickness: number;
}

export const Base = ({ parentMesh, width, height, depth, wallThickness }: Props) => {
  useEffect(() => {
    const geometry = new THREE.BoxGeometry(width, height, depth);
    const material = new THREE.MeshBasicMaterial({
      color: "gray",
    });

    const base = new THREE.Mesh(geometry, material);
    const position = getPositionRelativeToMesh({
      mesh: parentMesh,
      width,
      height,
      depth,
      x: -wallThickness,
      y: 0,
      z: -wallThickness,
    });
    base.position.set(position.x, position.y - height, position.z);
    parentMesh.add(base);

    return () => {
      parentMesh.remove(base);
    };
  }, []);

  return null;
};
