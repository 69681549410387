import { RequireAtLeastOne } from "@CommonUtils/typesHelpers";

export const RoutePostMerchflows_MerchflowId_ExportsSingle =
  "/merchflows/:merchflowId/exports/single";

export type PayloadPostMerchflows_MerchflowId_ExportsSingle = {
  type: "psa" | "pdf";
} & RequireAtLeastOne<{
  store_pog_id?: number;
  base_pog_id?: number;
}>;

export interface ResponsePostMerchflows_MerchflowId_ExportsSingle {
  download_url: string;
}
