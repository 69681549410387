import { Button, Color, Flex, Text } from "src/elements";
import { pages } from "src/utils";
import { useNavigation } from "src/utils/navigation";

const Page404 = () => {
  const { navigate } = useNavigation();

  return (
    <Flex height="100vh" column justify="center" align="center" gap="100px" padding="50px" flexGrow>
      <Flex align="center" flexGrow>
        <Text variant="h2" color={Color.textSecondary}>
          404 - Page Not Found
        </Text>
      </Flex>

      <Button onClick={() => navigate(pages.home)} width="200px">
        Back to Home
      </Button>
    </Flex>
  );
};

export default Page404;
