import React, { useRef } from "react";
import { Transition } from "src/utils";
import styled from "styled-components";

import { Color, Flex, Link, Icon, Text } from "src/elements";
import { IconType } from "src/elements/Icon";

import { Dropdown } from "./components/Dropdown";
import { themes } from "./store/themes";
import { ButtonColor, ButtonDropdownOption, ButtonSize, ButtonVariant } from "./store/types";
import { useHoverClickable } from "src/utils/hoverClickable";
import { AnchorLayer } from "../AnchorLayer/AnchorLayer";
import { useAnchorLayer } from "../AnchorLayer/store/hooks";

const ButtonElement = styled.button<{
  backgroundColor: string;
  borderColor: string;
  size: ButtonSize;
  isDisabled: boolean;
  isLoading: boolean;
  width?: string;
  height?: string;
  padding?: string;
}>`
  position: relative;
  display: flex;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ borderColor }) => borderColor};
  border-radius: 4px;
  padding: 0;
  user-select: none;
  transition:
    background-color ${Transition.fast},
    border-color ${Transition.fast};
  pointer-events: ${({ isDisabled, isLoading }) => (isDisabled || isLoading) && "none"};
  cursor: ${({ isDisabled }) => !isDisabled && "pointer"};

  a {
    z-index: 1;
  }
`;

type ButtonIconProps =
  | {
      name: IconType;
      size?: number;
    }
  | false;

interface Props {
  type?: "button" | "submit";
  color?: ButtonColor | false;
  size?: ButtonSize;
  variant?: ButtonVariant | false;
  isDisabled?: boolean;
  isEnabled?: boolean;
  isLoading?: boolean;
  isSuccessful?: boolean;
  width?: string;
  height?: string;
  iconRight?: ButtonIconProps;
  iconLeft?: ButtonIconProps;
  dropdown?: ButtonDropdownOption[];
  url?: string | false;
  urlInNewTab?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button = ({
  type,
  color: _color = "primary",
  size = "default",
  variant: _variant = "default",
  isDisabled: _isDisabled = false,
  isEnabled,
  isLoading = false,
  isSuccessful = false,
  width,
  height,
  iconRight,
  iconLeft,
  dropdown,
  url,
  urlInNewTab,
  children,
  onClick: _onClick,
}: Props) => {
  const refButton = useRef<HTMLButtonElement>(null);
  const { isHovered, setIsHovered, isActive, setIsActive } = useHoverClickable(refButton);
  const { layerState, isRendering, refLayer, hideAnchorLayer } = useAnchorLayer({
    isUsingHover: dropdown && true,
    refTrigger: refButton,
  });

  const variant = _variant || "default";
  const isInverted = variant === "inverted";
  const isHoverInverted = variant === "borderOnly";
  const isBorderless = variant === "borderless";
  const isTextHidden = isLoading || isSuccessful;
  const isDisabled = isEnabled === false || _isDisabled;

  const color = _color || "primary";

  const padding =
    (children === undefined && "3px") ||
    (size === "small" && "7px 10px") ||
    (size === "default" && "9px 16px") ||
    (size === "big" && "12px 20px") ||
    undefined;

  const backgroundColor =
    (isDisabled && isBorderless && Color.lightGray) ||
    (isDisabled && isInverted && Color.white) ||
    (isDisabled && themes[color].disabled) ||
    (isHovered && isHoverInverted && Color.white) ||
    (isActive && themes[color].active) ||
    (isHovered && themes[color].hover) ||
    ((isInverted || isHoverInverted) && Color.white) ||
    (isBorderless && Color.transparent) ||
    themes[color].default;

  const textVariant = (size === "small" && "caption2") || (size === "default" && "small1") || "h4";

  const textColor =
    (isDisabled && isBorderless && themes[color].disabled) ||
    (isDisabled && isInverted && themes[color].disabled) ||
    (isDisabled && themes[color].textDisabled) ||
    (isHovered && isHoverInverted && themes[color].default) ||
    (isActive && themes[color].textActive) ||
    (isHovered && themes[color].textHover) ||
    ((isInverted || isHoverInverted) && (themes[color].textInverted || themes[color].default)) ||
    (isBorderless && themes[color].default) ||
    themes[color].text;

  const borderColor =
    (isBorderless && Color.transparent) ||
    (isDisabled && themes[color].disabled) ||
    (isHovered && isHoverInverted && textColor) ||
    (isHovered && backgroundColor) ||
    ((isInverted || isHoverInverted) && themes[color].default) ||
    backgroundColor;

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isDisabled && !isLoading && _onClick) {
      _onClick(event);
    }
  };

  const onClickOption = (option: ButtonDropdownOption) => {
    if (option.onClick) {
      option.onClick();
      setIsHovered(false);
      setIsActive(false);
      hideAnchorLayer();
    }
  };

  return (
    <ButtonElement
      type={type}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      size={size}
      isDisabled={isDisabled}
      isLoading={isTextHidden}
      width={width}
      height={height}
      onClick={(event) => onClick(event)}
      ref={refButton}
    >
      <Flex
        width="100%"
        position="relative"
        padding={padding}
        gap="5px"
        align="center"
        justify="center"
      >
        {iconLeft && (
          <Flex visibility={isTextHidden ? "hidden" : "visible"}>
            <Icon name={iconLeft.name} size={iconLeft.size || 14} color={textColor} />
          </Flex>
        )}

        {children && (
          <Flex flexGrow={1} justify="center" visibility={isTextHidden ? "hidden" : "visible"}>
            <Text whiteSpace="nowrap" variant={textVariant} color={textColor}>
              <React.Fragment>{children}</React.Fragment>
            </Text>
          </Flex>
        )}

        {(iconRight || dropdown) && (
          <Flex visibility={isTextHidden ? "hidden" : "visible"}>
            <Icon
              name={iconRight ? iconRight.name : isHovered ? "triangleUp" : "triangleDown"}
              size={iconRight ? iconRight.size : 14}
              color={textColor}
            />
          </Flex>
        )}

        {(isLoading || isSuccessful) && (
          <Flex
            position="absolute"
            width="100%"
            height="100%"
            top="0"
            left="0"
            justify="center"
            align="center"
          >
            <Icon
              name={(isLoading && "spinner") || "checkmark"}
              size={
                (size === "big" && 23) || (size === "default" && 18) || (size === "small" && 14)
              }
              color={textColor}
            />
          </Flex>
        )}
      </Flex>

      {dropdown && (
        <AnchorLayer
          refLayer={refLayer}
          isRendering={isRendering}
          layerState={layerState}
          isUsingParentWidth
          hideAnchorLayer={hideAnchorLayer}
        >
          <Dropdown
            dropdown={dropdown.map((option) => ({
              ...option,
              onClick: option.onClick ? () => onClickOption(option) : undefined,
            }))}
            borderColor={borderColor}
            textColor={themes[color].textInverted || themes[color].default}
          />
        </AnchorLayer>
      )}

      {url && <Link to={url} inNewTab={urlInNewTab} isAbsolute />}
    </ButtonElement>
  );
};
