import { useAtom, useSetAtom } from "jotai";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useMerchflowStoresApi } from "src/api/merchflows/stores";
import {
  merchflows_MerchflowId_Stores_StoreCode_Atom,
  Stores_StoreCode_storePogIndexAtom,
} from "../store/atoms";

export const useStores_storeCodeData = () => {
  const { merchflowId: _merchflowId, storeCode: _storeCode } = useParams<{
    merchflowId: string;
    storeCode: string;
  }>();

  const { getMerchflowStorePogsApi } = useMerchflowStoresApi();

  const [searchParams] = useSearchParams();

  const [data, setData] = useAtom(merchflows_MerchflowId_Stores_StoreCode_Atom);
  const setIndex = useSetAtom(Stores_StoreCode_storePogIndexAtom);

  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);

  const storeCode = _storeCode!;
  const merchflowId = Number(_merchflowId);

  const loadStorePogs = (page: number) => {
    getMerchflowStorePogsApi({
      merchflowId,
      storeCode,
      pagination: {
        page,
        size: 100,
      },
      setData,
    });
  };
  return {
    setIndex,
    data,
    setData,
    page,
    setPage,
    loadStorePogs,
    storeCode,
    merchflowId,
  };
};
