import { useEffect, useRef } from "react";

import { getRequest } from "src/utils";
import { useToasts } from "../Toasts";

export const VersionChecker = () => {
  const { toast } = useToasts();

  const refVersionChecker = useRef<null | NodeJS.Timer>(null);
  const refVersion = useRef<null | string>(null);

  useEffect(() => {
    if (!refVersionChecker.current) {
      refVersionChecker.current = setInterval(
        () => {
          checkVersion();
        },
        10 * 60 * 1000,
      );
    }
  }, []);

  const checkVersion = async () => {
    try {
      const oldVersion = refVersion.current;
      const newVersion = (await getRequest("/version.txt")).data as string;

      if (oldVersion !== null && oldVersion !== newVersion) {
        toast({
          title: "New OWA version available",
          message: newVersion,
          duration: "infinite",
          actions: [
            {
              label: "Update",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      }

      refVersion.current = newVersion;
    } catch (error) {
      /* ignored */
    }
  };

  return null;
};
