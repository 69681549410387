export const RouteGetMerchflow_merchflowId_FlowUpdate_storePogTriggerId_ =
  "/merchflow/:merchflowId/flow-update/:storePogTriggerId";

export type ResponseGetMerchflow_merchflowId_FlowUpdate_storePogTriggerId_ = {
  success_result_count: number;
  partial_result_count: number;
  fail_result_count: number;
  in_progress_count: number;
  type: "STORE_PROP" | "FLOW_UPDATE";
  error_details?: object[];
};
