import { Color, Flex, Grid } from "src/elements";
import styled from "styled-components";

const PegboardHole = styled(Flex)`
  position: relative;

  &:after {
    display: block;
    content: "";
    position: absolute;
    left: -1px;
    top: -1px;
    width: 2px;
    aspect-ratio: 1;
    background-color: ${Color.textSecondary};
  }
`;

interface Props {
  pegboardWidthWithoutOffsets: number;
  pegboardHeightWithoutOffsets: number;
  holesGapHorizontal: number;
  holesGapVertical: number;
}

export const PegboardHoles = ({
  pegboardWidthWithoutOffsets,
  pegboardHeightWithoutOffsets,
  holesGapHorizontal,
  holesGapVertical,
}: Props) => {
  const holesHorizontal = Math.floor(pegboardWidthWithoutOffsets / holesGapHorizontal);
  const holesVertical = Math.floor(pegboardHeightWithoutOffsets / holesGapVertical);

  return (
    <Grid width="100%" columns={`repeat(${holesHorizontal}, 1fr)`}>
      {Array.from(Array(holesHorizontal * holesVertical)).map((_row, i) => (
        <PegboardHole key={i} />
      ))}
    </Grid>
  );
};
