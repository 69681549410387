import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { importPsaModalDataAtom } from "./atoms";
import { Category } from "@CommonApi/categories/list";

export const useImportPsaModal = () => {
  const { openModal } = useModals();
  const setImportPsaModalData = useSetAtom(importPsaModalDataAtom);

  const openImportPsaModal = (category: Category) => {
    setImportPsaModalData(category);
    openModal("ModalImportPsa");
  };

  return { openImportPsaModal };
};
