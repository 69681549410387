import { PlanogramItem, Pog } from "@CommonTypes/merchflow/pog/pog";
import { getNewOwaShelfId } from "src/components/Planogram/types/PlanogramShelf";
import { Color } from "src/elements";

export const getNewProductPossibleData = (pog: Pog) => {
  const variants: string[] = [];
  const subcategories: string[] = [];
  const cdt1: { [key: string]: number }[] = [];
  const cdt2: { [key: string]: number }[] = [];
  const cdt3: { [key: string]: number }[] = [];

  for (const bay of pog.planogram.bays) {
    for (const shelf of bay.shelves) {
      for (const item of shelf.items) {
        variants.push(item.variant);
        subcategories.push(item.subCategory);
        //@ts-ignore
        cdt1[item.cdt1] = item.cdt1Priority;
        //@ts-ignore
        cdt2[item.cdt2] = item.cdt2Priority;
        //@ts-ignore
        cdt3[item.cdt3] = item.cdt3Priority;
      }
    }
  }

  for (const item of pog.unrangedItems) {
    variants.push(item.variant);
    subcategories.push(item.subCategory);
    //@ts-ignore
    cdt1[item.cdt1] = item.cdt1Priority;
    //@ts-ignore
    cdt2[item.cdt2] = item.cdt2Priority;
    //@ts-ignore
    cdt3[item.cdt3] = item.cdt3Priority;
  }

  return {
    variants: Array.from(new Set(variants)).sort(),
    subcategories: Array.from(new Set(subcategories)).sort(),
    cdt1,
    cdt2,
    cdt3,
  };
};

export const getNewProductItemFromPog = (pog: Pog) => {
  const data = getNewProductPossibleData(pog);

  const planogramItem: PlanogramItem = {
    // Hidden fields.
    shelf: null,
    uniqueId: getNewOwaShelfId(),
    zCappingFlag: false,
    inCoreRange: false,
    dndFlag: false,
    newItemFlag: true,

    // Identifiers.
    id: "",
    productCode: "new_",
    name: "",
    upc: "new_",
    brand: "",
    variant: data.variants[0],
    categoryCode: "Convenience",
    subCategory: data.subcategories[0],
    size: "1",
    color: Color.greenSmoke,

    // Style.
    orientation: "FRONT",
    validOrientations: ["FRONT"],
    merchandisingStyle: "UNIT",

    // Unit dimensions.
    unitWidth: 0,
    unitHeight: 0,
    unitDepth: 0,

    // Tray dimensions.
    trayWidth: 0,
    trayHeight: 0,
    trayDepth: 0,
    unitsTrayWide: 1,
    unitsTrayDeep: 1,
    unitsTrayHigh: 1,
    noOfUnitsInTray: 1,

    // Case dimensions.
    caseWidth: 0,
    caseHeight: 0,
    caseDepth: 0,
    unitsCaseWide: 1,
    unitsCaseDeep: 1,
    unitsCaseHigh: 1,
    noOfUnitsInCase: 1,

    // Display dimensions.
    displayWidth: 0,
    displayHeight: 0,
    displayDepth: 0,

    // Dividers
    dividerWidth: 0,
    dividerHeight: 0,

    // PCT dimensions.
    squeezeWidthPct: 0,
    squeezeHeightPct: 0,
    squeezeDepthPct: 0,
    overhangSqueezeDepthFlag: false,

    // CDT.
    cdt1: Object.keys(data.cdt1)[0],
    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    cdt1Priority: data.cdt1[Object.keys(data.cdt1)[0]],
    cdt2: Object.keys(data.cdt2)[0],
    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    cdt2Priority: data.cdt2[Object.keys(data.cdt2)[0]],
    cdt3: Object.keys(data.cdt3)[0],
    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    cdt3Priority: data.cdt3[Object.keys(data.cdt3)[0]],

    // Facings.
    facings: 1,
    minFacings: 1,
    maxFacings: 100,
    facingsRows: 1,
    minFacingsRows: 1,
    maxFacingsRows: 1,

    // Quantities.
    minDaysOfSupply: 3,
    maxDaysOfSupply: 30,
    minOnShelf: 2,
    quantity: 0.1,
    price: 1,
    profit: 0,
    salesAmount: 0.1,
  };

  return planogramItem;
};

export const validateNewProduct = (planogramItem: PlanogramItem) => {
  if (!planogramItem.productCode) {
    return "Product code missing.";
  }

  if (!planogramItem.name) {
    return "Name missing.";
  }

  if (!planogramItem.color) {
    return "Color missing.";
  }

  if (!planogramItem.unitWidth) {
    return "Unit width can't be 0.";
  }

  if (!planogramItem.unitHeight) {
    return "Unit height can't be 0.";
  }

  if (!planogramItem.unitDepth) {
    return "Unit depth can't be 0.";
  }

  if (!planogramItem.price) {
    return "Price can't be 0.";
  }

  if (planogramItem.merchandisingStyle === "TRAY") {
    if (
      !planogramItem.trayWidth ||
      !planogramItem.trayHeight ||
      !planogramItem.trayDepth ||
      !planogramItem.unitsTrayWide ||
      !planogramItem.unitsTrayDeep ||
      !planogramItem.unitsTrayHigh ||
      !planogramItem.noOfUnitsInTray
    ) {
      return "Tray dimensions can't be 0.";
    }
  }

  if (planogramItem.merchandisingStyle === "CASE") {
    if (
      !planogramItem.caseWidth ||
      !planogramItem.caseHeight ||
      !planogramItem.caseDepth ||
      !planogramItem.unitsCaseWide ||
      !planogramItem.unitsCaseDeep ||
      !planogramItem.unitsCaseHigh ||
      !planogramItem.noOfUnitsInCase
    ) {
      return "Case dimensions can't be 0.";
    }
  }

  return true;
};
