export const RouteGetMerchflow_merchflowId_FlowUpdateChangeSummary =
  "/merchflow/:merchflowId/flow-update/change-summary";

export type ParamsGetMerchflow_merchflowId_FlowUpdateChangeSummary = {
  new_pog_id: number;
  old_pog_id: number;
};

export interface ResponseGetMerchflow_merchflowId_FlowUpdateChangeSummary {
  change_summary: ChangeSummary[];
  new_pog_id: number;
  old_pog_id: number;
}

export interface ChangeSummary {
  product_code: string;
  change_type: "add" | "remove" | "update";
  merch_style: "UNIT" | "TRAY" | "CASE";
  old_facings: number;
  new_facings: number;
}
