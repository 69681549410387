import { Color, Text } from "src/elements";
import styled from "styled-components";

const TabElement = styled.div<{ isActive: boolean }>`
  width: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  flex-grow: 1;
  padding: 10px;
  border-style: solid;
  border-color: ${({ isActive }) => (isActive && Color.primary) || Color.transparent};
  border-width: 0 1px 0 1px;
  background-color: ${({ isActive }) => isActive && Color.white};
  user-select: none;
  cursor: pointer;

  &:first-of-type {
    border-left: 0;
  }

  ::after {
    content: "";
    display: ${({ isActive }) => (isActive ? "block" : "none")};
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 1px;
    background: ${Color.white};
  }
`;

interface Props<T> {
  tab: T;
  isActive: boolean;
  setActiveTab: (tab: T) => void;
}

export const Tab = <T,>({ tab, isActive, setActiveTab }: Props<T>) => {
  const selectTab = () => {
    setActiveTab(tab);
  };

  return (
    <TabElement isActive={isActive} onClick={selectTab}>
      <Text variant="small1" color={Color.primaryActive}>
        <>{tab}</>
      </Text>
    </TabElement>
  );
};
