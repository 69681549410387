import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Button, Color, Flex, Icon, Pagination, Scroller, SubgridTable, Text } from "src/elements";

import { ResponseMerchFlowDetails } from "@CommonApi/merchandise";
import { SubgridColumn } from "src/elements/SubgridTable/store/types";
import { useMerchflowPSAReviewModal } from "src/modals/MerchflowPSAReview/store/hooks";
import { useMerchflowReviewModal } from "src/modals/MerchflowReview/store/hooks";
import { useMerchflowReviewUpdateModal } from "src/modals/MerchflowReviewUpdate/store/hooks";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { useNavigation } from "src/utils/navigation";
import { usePaginationPage } from "src/elements/Pagination";
import {
  ResponseGetMerchflowsReviews,
  RouteGetMerchflowsReviews,
} from "@CommonApi/merchflows/reviews";
import { useInlineLoaders } from "src/components/InlineLoader";
import { Page, PageStatus, Tooltip } from "src/components";
import { useApi } from "src/api";
import { useMenu } from "src/components/Menu";
import { useViolationsReportModal } from "src/modals/ViolationsReport/store/hooks";
import { ReviewStatus } from "@CommonTypes/merchflow/ReviewStatus";
import { useMerchflowReviewUpdateModalV1 } from "src/modals/MerchflowReviewUpdateV1/store/hooks";
import { useIsFeatureFlagEnabled } from "src/components/FeatureFlagProvider/store/utils";
import { useHoverClickable } from "src/utils/hoverClickable";
import { useMerchflowNotesModal } from "src/modals/MerchflowNotes/store/hooks";
import { useToasts } from "src/components/Toasts";
import { MerchflowHeader } from "src/components/merchflows/MerchflowHeader/MerchflowHeader";

const NotesCell = ({
  merchflowId,
  notes,
  setData,
}: {
  merchflowId: number;
  notes: string;
  setData: Dispatch<SetStateAction<ResponseGetMerchflowsReviews | null>>;
}) => {
  const refCell = useRef(null);

  const { isHovered } = useHoverClickable(refCell);
  const { openMerchflowNotesModal: _openMerchflowNotesModal } = useMerchflowNotesModal();

  const openMerchflowNotesModal = () => {
    _openMerchflowNotesModal({
      merchflowId,
      notes,
      onSave: updateMerchflowNotesAfterSuccessfulSave,
    });
  };

  const updateMerchflowNotesAfterSuccessfulSave = (notes: string) => {
    setData((data) =>
      data
        ? {
            ...data,
            data: data.data.map((merchflowRow) =>
              merchflowRow.id === merchflowId ? { ...merchflowRow, notes: notes } : merchflowRow,
            ),
          }
        : null,
    );
  };

  return (
    <Flex width="100%" position="relative" ref={refCell}>
      <Text variant="small2">{notes}</Text>

      {isHovered && (
        <Flex position="absolute" right="0" onClick={openMerchflowNotesModal}>
          <Icon name="edit" />
        </Flex>
      )}
    </Flex>
  );
};

export const PageMerchflows = () => {
  const { toast } = useToasts();
  const { openMenu } = useMenu();
  const { getMerchflowsReviewsApi } = useApi();
  const { navigate } = useNavigation();
  const { openMerchflowReviewModal } = useMerchflowReviewModal();
  const { openMerchflowPSAReviewModal } = useMerchflowPSAReviewModal();
  const { isInlineLoading } = useInlineLoaders();
  const { openViolationsReportModal } = useViolationsReportModal();

  const { openMerchflowReviewUpdateModalV1 } = useMerchflowReviewUpdateModalV1();
  const { openMerchflowReviewUpdateModal } = useMerchflowReviewUpdateModal();

  const [data, setData] = useState<ResponseGetMerchflowsReviews | null>(null);
  const [page, setPage] = usePaginationPage();
  const isLoadingFlows = isInlineLoading(RouteGetMerchflowsReviews);
  const featureFlagUpdateApiV2 = useIsFeatureFlagEnabled("updateApiV2");

  useEffect(() => {
    loadFlows();
  }, [page]);

  const getColumns = (setData: Dispatch<SetStateAction<ResponseGetMerchflowsReviews | null>>) =>
    [
      {
        id: "id",
        header: "ID",
      },
      {
        id: "name",
        header: "Name",
      },
      {
        id: "retailer_category_code",
        header: "Category",
      },
      {
        id: "category_code",
        header: "Sub-Category",
      },
      {
        id: "go_live_date",
        header: "Go-Live Date",
        renderer: ({ row: { go_live_date: goLiveDate } }) =>
          moment(goLiveDate).format("DD/MM/YYYY"),
      },
      {
        id: "deactivation_date",
        header: "Deactivation Date",
        renderer: ({ row: { deactivation_date: deactivationDate } }) =>
          (deactivationDate && moment(deactivationDate).format("DD/MM/YYYY")) || "",
      },
      {
        id: "status",
        header: "Status",
        renderer: ({ row: { status } }) => (
          <>
            {status === "BASE_POG_GENERATED" && "Base POG generated"}
            {status === "STORE_POG_GENERATED" && "Store POG generated"}
            {status === "STORE_POG_EXPORTED" && "Store POG exported"}
          </>
        ),
      },
      {
        id: "created_at",
        header: "Date Created",
        renderer: ({ row: { created_at: createdAt } }) => moment(createdAt).format("DD/MM/YYYY"),
      },
      {
        id: "updated_at",
        header: "Date Modified",
        renderer: ({ row: { updated_at: updatedAt } }) => moment(updatedAt).format("DD/MM/YYYY"),
      },
      {
        id: "notes",
        header: "Notes",
        renderer: ({ row: { id: merchflowId, notes } }) => (
          <NotesCell merchflowId={merchflowId} notes={notes} setData={setData} />
        ),
      },
      {
        id: "core_range",
        header: "Core range",
        renderer: (data) => data.row.config.option,
      },
      {
        id: "actions",
        header: "Actions",
        renderer: ({
          row: merchflow,
          row: { is_violated: isViolated, need_update: needUpdate },
        }) => (
          <Flex gap="8px" align="center">
            <Button
              size="small"
              variant="borderless"
              iconRight={{ name: "more" }}
              onClick={(event) => openMerchflowMoreMenu(event, merchflow)}
            />

            {isViolated && (
              <Flex onClick={() => openViolationsReportModal(merchflow.id)}>
                <Icon name="alert" color={Color.red} size={14} />

                <Tooltip>Planogram violation detected</Tooltip>
              </Flex>
            )}

            {needUpdate && (
              <Flex onClick={() => handleUpdateFlowReview(merchflow)}>
                <Icon name="alert" color={Color.yellow} size={14} />

                <Tooltip>Planogram update needed</Tooltip>
              </Flex>
            )}
          </Flex>
        ),
      },
    ] satisfies SubgridColumn<ResponseMerchFlowDetails>[];

  const loadFlows = async () => {
    setData(await getMerchflowsReviewsApi({ page, size: 20 }));
  };

  const viewTemplates = (merchflowId: number) => {
    navigate(routeToUrl(pages.merchflowTemplates, { merchflowId }));
  };

  const viewStores = (merchflowId: number) => {
    navigate(routeToUrl(pages.merchflows_merchflowId_StoresAll, { merchflowId }));
  };

  const showContinueWarningToast = (message: string) => {
    toast({ title: "Can't continue merchflow", message, type: "warning" });
  };

  const continueMerchflow = (merchflow: ResponseMerchFlowDetails) => {
    switch (merchflow.status) {
      case ReviewStatus.DRAFT:
      case ReviewStatus.S0_GENERATED:
      case ReviewStatus.S1_GENERATED:
      case ReviewStatus.S2_GENERATED:
        showContinueWarningToast("Not implemented");
        break;
      case ReviewStatus.BASE_POG_GENERATED:
        if (merchflow.latest_base_pog_id) {
          navigate(
            routeToUrl(pages.merchflows_merchflowId_BasePog_basePogId_, {
              merchflowId: merchflow.id,
              basePogId: merchflow.latest_base_pog_id,
            }),
          );
        } else {
          showContinueWarningToast("Base pog id not found");
        }
        break;
      case ReviewStatus.STORE_POG_GENERATED:
      case ReviewStatus.STORE_POG_EXPORTED:
        viewStores(merchflow.id);
        break;
    }
  };

  const handleUpdateFlowReview = (review: ResponseMerchFlowDetails) => {
    if (featureFlagUpdateApiV2) {
      openMerchflowReviewUpdateModal({ merchflowId: review.id });
    } else {
      openMerchflowReviewUpdateModalV1({ merchflowId: review.id });
    }
  };

  const openMerchflowMoreMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    merchflow: ResponseMerchFlowDetails,
  ) => {
    openMenu({
      isRelativeToElement: true,
      event,
      menu: {
        options: [
          ...(merchflow.status === ReviewStatus.STORE_POG_GENERATED ||
          merchflow.status === ReviewStatus.STORE_POG_EXPORTED
            ? [
                {
                  label: "Update",
                  onClick: () => handleUpdateFlowReview(merchflow),
                },
              ]
            : []),
          { label: "View Stores", onClick: () => viewStores(merchflow.id) },
          {
            label: "View Templates",
            onClick: () => viewTemplates(merchflow.id),
          },
          {
            label: "Violation Report",
            onClick: () => openViolationsReportModal(merchflow.id),
          },
          { label: "Continue", onClick: () => continueMerchflow(merchflow) },
        ],
      },
    });
  };

  return (
    <Page>
      <MerchflowHeader />

      <Flex column gap="10px" flexGrow>
        <Flex justify="right" gap="10px">
          <Button
            color="greenSmoke"
            iconRight={{ name: "plus" }}
            onClick={openMerchflowReviewModal}
          >
            Create
          </Button>

          <Button
            color="greenSmoke"
            iconRight={{ name: "plus" }}
            onClick={openMerchflowPSAReviewModal}
          >
            Create from PSA
          </Button>
        </Flex>

        <Flex flexGrow>
          {isLoadingFlows && <PageStatus label="Loading flows..." icon="spinner" />}

          {!isLoadingFlows && data && (
            <Scroller>
              <SubgridTable columns={getColumns(setData)} data={data.data} />
            </Scroller>
          )}
        </Flex>

        <Pagination page={page} setPage={setPage} totalPages={data?.totalPages} isUsingInput />
      </Flex>
    </Page>
  );
};
