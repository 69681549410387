import { ResponseGetAuthUser } from "@CommonApi/auth/user";
import { useEffect } from "react";
import { useUser } from "src/atoms/user";
import { clearLocalStorage, pages } from "src/utils";

export const PageLogout = () => {
  const { setUser } = useUser();

  useEffect(() => {
    setUser(null as unknown as ResponseGetAuthUser);
    clearLocalStorage();
    window.location.href = pages.login;
  }, []);

  return null;
};
