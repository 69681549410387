import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { basePlanogramReviewAtom } from "./atoms";
import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";

export interface OpenBasePlanogramReviewModal {
  merchflowId: number;
  basePogId: number;
  basePog: PogNonCircular;
  isFavorite?: boolean;
}

export const useBasePlanogramReviewModal = () => {
  const { openModal } = useModals();
  const setData = useSetAtom(basePlanogramReviewAtom);

  const openBasePlanogramReviewModal = (data: OpenBasePlanogramReviewModal) => {
    setData(data);
    openModal("BasePlanogramReviewModal");
  };

  return { openBasePlanogramReviewModal };
};
