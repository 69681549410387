import { Flex, Icon, Text } from "src/elements";
import { IconType } from "src/elements/Icon";

export interface LegendEntry {
  label: string;
  background?: string;
  labelColor?: string;
  borderColor?: string;
  borderRadius?: string;
  icon?: {
    name: IconType;
    color?: string;
  };
}

const LegendEntryElement = ({
  label,
  labelColor,
  background,
  borderColor,
  borderRadius = "3px",
  icon,
}: LegendEntry) => (
  <Flex gap="5px" align="center">
    <Flex
      minHeight="14px"
      minWidth="14px"
      background={background}
      borderColor={borderColor}
      borderRadius={borderRadius}
      justify="center"
      align="center"
    >
      {icon && <Icon name={icon.name} color={icon.color} size={14} />}
    </Flex>

    <Text variant="small1" color={labelColor}>
      {label}
    </Text>
  </Flex>
);

interface Props {
  legends: LegendEntry[];
}

export const Legend = ({ legends }: Props) => (
  <Flex gap="15px">
    {legends.map((legend, i) => (
      <LegendEntryElement key={i} {...legend} />
    ))}
  </Flex>
);
