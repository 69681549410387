import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { merchflowReviewUpdateAtom } from "./atoms";
import { ResponseGetViolationMerchflow_merchflowId_ } from "@CommonApi/violation/merchflow/_merchflowId_";

export const useMerchflowReviewUpdateModal = () => {
  const { openModal } = useModals();
  const setUpdateReview = useSetAtom(merchflowReviewUpdateAtom);

  const openMerchflowReviewUpdateModal = (payload: {
    merchflowId: number;
    violations?: ResponseGetViolationMerchflow_merchflowId_;
  }) => {
    setUpdateReview(payload);
    openModal("ModalMerchflowReviewUpdate");
  };

  return { openMerchflowReviewUpdateModal };
};
