import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { violationReportModalMerchflowIdAtom } from "./atoms";

export const useViolationsReportModal = () => {
  const { openModal } = useModals();
  const setViolationReportModalMerchflowId = useSetAtom(violationReportModalMerchflowIdAtom);

  const openViolationsReportModal = (merchflowId: number) => {
    setViolationReportModalMerchflowId(merchflowId);
    openModal("ViolationsReportModal");
  };

  return { openViolationsReportModal };
};
