import { useEffect, useState } from "react";
import { Page, PageStatus } from "src/components";
import { useInlineLoaders } from "src/components/InlineLoader";
import { Button, Color, Flex, Pagination, Scroller, SubgridTable, Text } from "src/elements";

import { useInputsApi } from "src/api";
import { useModals } from "src/components/Modals";
import { Product } from "@CommonTypes/owa-db/merchantSchema";
import { RoutePutInputsProducts } from "@CommonApi/inputs/products";
import { getProductsTableColumns } from "./store/columns";
import { useProductsPage } from "./store/hooks";

export const PageProducts = () => {
  const { products, page, setPage, isLoading, loadProducts } = useProductsPage();
  const { putInputsProductsApi } = useInputsApi();
  const { isInlineLoading } = useInlineLoaders();
  const { openConfirmModal } = useModals();

  const [modifiedProducts, setModifiedProducts] = useState<{
    [key: string]: Product;
  }>({});

  const isProductsUpdateLoading = isInlineLoading(RoutePutInputsProducts);

  useEffect(() => {
    loadProducts();
  }, [page]);

  const saveProducts = async () => {
    const response = await putInputsProductsApi({
      products: Object.values(modifiedProducts),
    });

    if (response) {
      openConfirmModal({
        icon: { name: "checkmarkCircle" },
        title: "Products changes have been saved!",
        buttons: [{ label: "Confirm" }],
      });
      clearModifiedProducts();
      loadProducts();
    }
  };

  const clearModifiedProducts = () => {
    setModifiedProducts({});
  };

  return (
    <Page>
      <Flex column minHeight="0" flexGrow={1} gap="10px">
        <Flex padding="10px 0 0 0" justify="between" align="center">
          <Text variant="h3">Products</Text>

          <Button width="120px" iconRight={{ name: "plus" }} isDisabled>
            Add Product
          </Button>
        </Flex>

        <Flex column minHeight="0" flexGrow={1} gap="20px">
          {isLoading && <PageStatus label="Loading Products..." icon="spinner" />}

          {!isLoading && !products && (
            <PageStatus label="Products not found!" icon="alert" color={Color.red} />
          )}

          {!isLoading && products && (
            <>
              <Flex minHeight="0" flexGrow>
                <Scroller>
                  <SubgridTable
                    columns={getProductsTableColumns({
                      modifiedProducts,
                      setModifiedProducts,
                    })}
                    data={products.data}
                  />
                </Scroller>
              </Flex>

              {Object.keys(modifiedProducts).length > 0 && (
                <Flex justify="right">
                  <Button width="120px" isLoading={isProductsUpdateLoading} onClick={saveProducts}>
                    Save Changes
                  </Button>
                </Flex>
              )}
            </>
          )}
        </Flex>

        <Pagination
          page={page}
          setPage={setPage}
          totalPages={products?.totalPages || 1}
          isUsingInput
          isLoading={isLoading}
        />
      </Flex>
    </Page>
  );
};
