import { useAtom } from "jotai";
import { useEffect, useState } from "react";

import { useModals } from "src/components/Modals";
import { Button, Flex, Modal } from "src/elements";
import { pages } from "src/utils";
import { useNavigation } from "src/utils/navigation";
import { useApi } from "src/api";
import { basePlanogramRulesAtom, BasePlanogramRulesProps } from "./store/atoms";
import { adapterPogToPogNonCircular } from "@CommonAdapters/pog";
import { PlanogramOptimizeOn } from "src/components";
import { PlanogramOptimizeOnProps } from "src/components/PlanogramOptimizeOn/PlanogramOptimizeOn";
import { useInlineLoaders } from "src/components/InlineLoader";
import { RoutePostMerchflows_merchflowId_BasePog } from "@CommonApi/merchflows/_merchflowId_/base-pog";

export const ModalBasePlanogramRules = () => {
  const [data, setData] = useAtom(basePlanogramRulesAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  if (!data) return null;

  return <ModalBasePlanogramRulesInner {...data} />;
};

const ModalBasePlanogramRulesInner = ({
  merchflowId,
  s2SolutionId,
  fixturesPog,
}: BasePlanogramRulesProps) => {
  const { postStartMerchflowBasePogApi } = useApi();
  const { closeModal: _closeModal } = useModals();
  const { navigate } = useNavigation();
  const { isInlineLoading } = useInlineLoaders();

  const [optimizeOn, setOptimizeOn] = useState<PlanogramOptimizeOnProps["optimizeOn"]>("SALES");
  const isGenerateBasePlanogramLoading = isInlineLoading(RoutePostMerchflows_merchflowId_BasePog);

  const generateBasePlanogram = async () => {
    const response = await postStartMerchflowBasePogApi(merchflowId, {
      s2_solution_id: s2SolutionId,
      s2_solution: adapterPogToPogNonCircular(fixturesPog),
      optimize_on: optimizeOn,
    });

    if (response?.base_pog_id) {
      navigate(pages.merchflows_merchflowId_BasePog_basePogId_, {
        merchflowId: merchflowId,
        basePogId: response?.base_pog_id,
      });
      closeModal();
    }
  };

  const closeModal = () => {
    _closeModal("ModalBasePlanogramRules");
  };

  return (
    <Modal title="Base Planogram Rules" name="ModalBasePlanogramRules" width="500px">
      <Flex column gap="20px" padding="20px 20px 10px 20px">
        <PlanogramOptimizeOn optimizeOn={optimizeOn} setOptimizeOn={setOptimizeOn} />

        <Flex width="100%" justify="center" gap="20px">
          <Button
            color="red"
            variant="inverted"
            isDisabled={isGenerateBasePlanogramLoading}
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button isLoading={isGenerateBasePlanogramLoading} onClick={generateBasePlanogram}>
            Generate Base Planogram
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
