import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { Flex, Grid, VerticalDivider } from "src/elements";
import { Metric } from "../Metric/Metric";

export const PlanogramMetrics = ({
  pog,
  size,
  isLoading,
}: {
  pog: Pog | null;
  size?: "default" | "small" | "big";
  isLoading?: boolean;
}) => (
  <Flex align="center">
    <Grid
      gap="20px"
      columns="minmax(0, 1fr) auto minmax(0, 1fr) auto minmax(0, 1fr) auto minmax(0, 1fr)"
    >
      <Metric
        title="Return on Space"
        percent={pog?.metrics?.returnOnSpace}
        height={size}
        isLoading={isLoading}
      />

      <VerticalDivider size={2} />

      <Metric
        title="Core Range"
        percent={pog?.metrics?.coreRange}
        height={size}
        isLoading={isLoading}
      />

      <VerticalDivider size={2} />

      <Metric
        title="Shelf Alignment"
        percent={pog?.metrics?.shelfAlignment}
        height={size}
        isLoading={isLoading}
      />

      <VerticalDivider size={2} />

      <Metric
        title="DOS / MOS"
        percent={pog?.metrics?.dosMos}
        height={size}
        isLoading={isLoading}
      />
    </Grid>
  </Flex>
);
