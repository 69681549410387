export const RouteAuthLogin = "/auth/login";

export interface PayloadAuthLogin {
  email: string;
  password: string;
}

export interface ResponseAuthLogin {
  token: string;
  user: User;
  signingTimestamp: string;
  /** expressed in seconds or a string describing a time span [zeit/ms](https://github.com/zeit/ms.js).  Eg: 60, "2 days", "10h", "7d" */
  expiresIn: string;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  userType: "MERCHANT_USER" | "ADMIN_USER";
  merchantId: number;
  createdAt: string;
  merchantName: string;
}
