import { adapterPogNonCircularToPog } from "@CommonAdapters/pog";
import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, atom, useAtom } from "jotai";
import { useMemo } from "react";
import { Planogram } from "src/components";
import { Button, Color, Flex, Skeleton, Text, Icon } from "src/elements";
import { pages, uuid } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { S2SolutionDb } from "@CommonApi/merchflow/_merchflowId_/s2/template/_templateId_";
import { PlanogramMetrics } from "src/components/PlanogramExtensions/PlanogramMetrics";

interface Props {
  merchflowId: number;
  s2Solution?: S2SolutionDb | null;
  isLoading?: boolean;
}

export const OneQuadrantView = ({ merchflowId, s2Solution, isLoading }: Props) => {
  const pogAtom = useMemo(() => {
    return atom(
      s2Solution?.s2_solution_data?.pog
        ? adapterPogNonCircularToPog(s2Solution.s2_solution_data?.pog)
        : null,
    );
  }, [s2Solution]);

  const containersAtom = useMemo(() => {
    return atom(s2Solution?.s2_solution_data?.containers || null);
  }, [s2Solution]);

  const [pog] = useAtom(pogAtom);
  const [containers] = useAtom(containersAtom);

  return (
    <Flex key={uuid()} padding="10px" gap="10px" column>
      <Flex width="100%" flexGrow>
        {isLoading && <Skeleton width="100%" height="100%" />}

        {!isLoading &&
          (s2Solution?.s2_solution_data?.pog === null ||
            s2Solution?.s2_solution_data?.containers === null) && (
            <Flex justify="center" align="center" column gap="10px" width="100%" height="100%">
              <Icon name="alert" color={Color.red} size={32} />

              <Text variant="h4" color={Color.red}>
                Mudmap generation failed
              </Text>
            </Flex>
          )}

        {!isLoading && s2Solution === null && (
          <Flex justify="center" align="center" column gap="10px" width="100%" height="100%">
            <Icon name="alert" color={Color.yellow} size={32} />

            <Text variant="h4" color={Color.yellow}>
              Mudmap not found
            </Text>
          </Flex>
        )}

        {!isLoading && pog && containers && (
          <Planogram
            pogAtom={pogAtom as unknown as PrimitiveAtom<Pog>}
            containersAtom={containersAtom as unknown as PrimitiveAtom<PlanogramContainer[]>}
            isMetricsRecalculated={false}
          />
        )}
      </Flex>

      {(s2Solution !== undefined || isLoading) && (
        <Flex justify="between">
          <PlanogramMetrics pog={pog} size="small" isLoading={isLoading} />

          <Button
            size="small"
            color="greenSmoke"
            isDisabled={
              !s2Solution ||
              isLoading ||
              s2Solution.s2_solution_data?.pog === null ||
              s2Solution.s2_solution_data?.containers === null
            }
            url={
              s2Solution
                ? routeToUrl(pages.merchflows_merchflowId_S2_s2SolutionId_, {
                    merchflowId,
                    s2SolutionId: s2Solution.s2_solution_id,
                  })
                : false
            }
          >
            View
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
