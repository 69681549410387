export const RoutePutMerchflows_MerchflowId_StoresApprovals =
  "/merchflows/:merchflowId/stores/approvals";

export type PayloadPutMerchflows_MerchflowId_StoresApprovals =
  | { approved: Approval[] }
  | { unapproved: Approval[] }
  | { approveAllExclude: Approval[] }
  | { unapproveAllExclude: Approval[] };

interface Approval {
  store_pog_id: number;
}
