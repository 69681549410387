import { useSetAtom } from "jotai";
import React from "react";

import { menuAtom } from "./atoms";
import { OpenMenuAction } from "./types";

export const useMenu = () => {
  const setMenu = useSetAtom(menuAtom);

  const openMenu = ({
    event,
    menu,
    isRelativeToElement = false,
  }: {
    event: React.MouseEvent<HTMLElement>;
    menu: OpenMenuAction;
    isRelativeToElement?: boolean;
  }) => {
    event.preventDefault();
    event.stopPropagation();
    setMenu({ ...menu, isRelativeToElement, event });
  };

  return { openMenu };
};
