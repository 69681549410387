import React from "react";

import {
  DragBounds,
  DragModifications,
  ResizeSide,
  Scaleable,
  Snappable,
} from "../../../store/types";
import { ResizeHandle } from "./ResizeHandle";

interface Props extends DragBounds, Scaleable {
  refDraggable: React.RefObject<HTMLDivElement>;
  bounds: DragBounds;
  snappables: Snappable[];
  onModify: (modifications: DragModifications) => void;
}

const resizeSides: ResizeSide[] = [
  "top",
  "right",
  "bottom",
  "left",
  "top-right",
  "bottom-right",
  "bottom-left",
  "top-left",
];

export const ResizeHandles = (props: Props) => (
  <React.Fragment>
    {resizeSides.map((side) => (
      <ResizeHandle key={side} {...props} side={side} />
    ))}
  </React.Fragment>
);
