import { Flex, ProgressBar, Text, Color, Skeleton } from "src/elements";
import { getProgressColor } from "src/elements/ProgressBar";

import { textVariants } from "src/elements/Text/store/variants";

interface Props {
  title?: string;
  percent?: number | null;
  height?: "default" | "small" | "big" | string;
  isInline?: boolean;
  isLoading?: boolean;
}

export const Metric = ({ title, percent, height, isInline, isLoading }: Props) => {
  const isValid = percent !== undefined;
  const color = getProgressColor(percent);
  const size =
    (height === "small" && "6px") ||
    (height === "default" && "12px") ||
    (height === "big" && "18px") ||
    height;

  return (
    <Flex width="100%" direction="column" gap={(height === "small" && "1px") || "5px"}>
      <ProgressBar
        percent={percent}
        color={color}
        height={size}
        isLoading={isLoading}
        isInline={isInline}
      />

      {title && (
        <>
          <Text
            variant={(height === "small" && "caption2") || "h6"}
            whiteSpace="nowrap"
            textTransform="uppercase"
            color={color}
          >
            {title}:
          </Text>

          {isLoading && (
            <Skeleton
              width="70px"
              height={textVariants[(height === "small" && "caption2") || "h4"].lineHeight}
            />
          )}

          {!isLoading && (
            <Text
              variant={(height === "small" && "caption2") || "h4"}
              whiteSpace="nowrap"
              textTransform="uppercase"
              color={isValid ? Color.textMain : Color.textDisabled}
              isLoading={isLoading}
            >
              {isValid && percent ? `${percent.toFixed(2)}%` : "-"}
            </Text>
          )}
        </>
      )}
    </Flex>
  );
};
