/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import isBoolean from "lodash/isBoolean";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";

export const toNonCircularObject = (value: any) => {
  if (value && typeof value === "object") {
    return JSON.parse(stringifyCircular(value));
  }
  return value;
};
export const formatUnknown = (
  details?: unknown, // undefined | null | number | string | boolean | object
) => {
  if (
    details === undefined ||
    details === null ||
    isNumber(details) ||
    isString(details) ||
    isBoolean(details)
  ) {
    return details;
  }

  // @ts-ignore it cannot be undefined, null, number, string, boolean - so, it's object
  if ("stack" in details) {
    return {
      ...toNonCircularObject(details),
      stack: toNonCircularObject(details.stack),
    }; // we have to call stack explicitly so that it's generated
  }

  const asString = String(details);

  // '[object Object]' is not needed
  if (asString === "[object Object]") {
    return toNonCircularObject(details);
  }

  return { ...toNonCircularObject(details), asString };
};

/** Skip all circular references */
export const stringifyCircular = (obj: unknown) => {
  let cache: unknown[] = [];
  const str = JSON.stringify(obj, (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference is found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    // eslint-disable-next-line consistent-return
    return value;
  });
  cache = []; // reset the cache
  return str;
};
