import { PoggerResponse } from "@CommonTypes/algo/pogger";
import { PoggerizeResponse } from "@CommonTypes/algo/poggerize";
import { ReviewStatus } from "@CommonTypes/merchflow/ReviewStatus";
import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";
import { BasePog } from "@CommonTypes/omnihq-db/merchantSchema";

export const enum MerchFlowCoreRange {
  ALL = "all",
  NONE = "none",
  PARTIAL = "partial",
  CSV = "csv",
}

export const enum MerchFlowFlowHierarchy {
  CORE_RANGE = "Core Range",
  DOS = "DOS",
  MOS = "MOS",
}

type Category = string;
type SubCategory = string;

export interface ResponseMerchFlowLoadCategories {
  [category: Category]: SubCategory[];
}

export interface ResponseMerchFlowDetails {
  id: number;
  name: string;
  retailer_category_code: Category;
  category_code: SubCategory;
  notes: string;
  created_by: number;
  created_at: string;
  updated_at: string;
  config: {
    option: MerchFlowCoreRange;
  };
  core_range_upload_id: number | null;
  latest_filter_config: ResponseMerchFlowApplyFilters[];
  go_live_date: string;
  deactivation_date: string | null;
  status: ReviewStatus;
  is_violated: boolean;
  need_update: boolean;
  latest_base_pog_id: number | null;
}

export interface PayloadMerchFlowLoadFilters {
  retailer_category_code: Category;
  category_code: SubCategory;
}

export interface ResponseMerchFlowLoadFilters {
  baySizes: number[];
  states: string[];
  fixtures: {
    section_height: number[];
    section_width: number[];
  };
  clusters: string[];
  stores: {
    bay_size: number;
    state: string;
    section_height: number;
    section_width: number;
    cluster: string;
    retailer_category_code: Category;
    category_code: SubCategory;
    store: string;
    template_id: string;
  }[];
}

export interface PayloadMerchFlowApplyFilters {
  flowId: number;
  filter_config: ResponseMerchFlowLoadFilters;
}

export interface ResponseMerchFlowApplyFilters {
  id: number;
  filter_config: {
    states: string[];
    stores: {
      state: string;
      store: string;
      cluster: string;
      bay_size: number;
      ffUploadId: number;
      pmUploadId: number;
      posUploadId: number;
      template_id: string;
      category_code: string;
      section_width: number;
      section_height: number;
      retailer_category_code: string;
    }[];
    baySizes: number[];
    clusters: string[];
    fixtures: {
      section_width: number[];
      section_height: number[];
    };
    merge_rules?: {
      rule_id: number;
      merged_variants: string[];
    }[];
  };
  created_at: string;
  review_id: number;
  base_pm_path: string | null;
  base_ff_path: string | null;
}

export interface PayloadMerchFlowLoadVariants {
  filterConfigId: number;
}

export type ResponseMerchFlowLoadVariants = string[];

export interface PayloadMerchFlowApplyMergeRules {
  merge_rules: { rule_id: number; merged_variants: string[] }[];
  hierarchy: {
    [MerchFlowFlowHierarchy.CORE_RANGE]: number;
    [MerchFlowFlowHierarchy.DOS]: number;
    [MerchFlowFlowHierarchy.MOS]: number;
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  cdt: {};
}

export type ResponseMerchFlowApplyMergeRules = string;

export interface MerchFlowExportPSA {
  category_code: string;
  store_code: string;
  template_id: string;
  filter_config_id: number;
  pog_level?: "BASE_POG" | "STORE_POG";
}

export type TemplateWithBasePog = {
  template_id: string;
  pog_data: PogNonCircular | null;
};

export type AllBasePogResponse = Omit<
  BasePog,
  "poggerize_response_path" | "pogger_response_path" | "pog_data"
> & {
  poggerize_response_path: PoggerizeResponse;
  pogger_response_path: { data: PoggerResponse };
  pog_data: PogNonCircular;
};
