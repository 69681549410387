import { Color, Flex, Text } from "src/elements";
import styled from "styled-components";

const FormulaInserterElement = styled(Flex)<{ isTextCentered?: boolean }>`
  align-items: center;
  justify-content: ${({ isTextCentered }) => isTextCentered && "center"};
  width: 100%;
  padding: 5px;
  gap: 5px;
  cursor: pointer;
  border-radius: 3px;
  user-select: none;

  &:hover {
    background-color: ${Color.greenSmoke};
    color: ${Color.primaryHover};
  }

  &:hover:active {
    background-color: ${Color.greenSmokeActive};
    color: ${Color.primaryActive};
  }
`;

interface Props {
  onClick: () => void;
  isTextCentered?: boolean;
  label: string;
  sublabel?: string;
}

export const FormulaInserter = ({ onClick, isTextCentered, label, sublabel }: Props) => (
  <Flex onClick={onClick} gap="5px">
    <FormulaInserterElement isTextCentered={isTextCentered}>
      <Text color={Color.textSecondary}>{label}</Text>

      {sublabel && (
        <Text variant="caption1" color={Color.spaceGray}>
          {sublabel}
        </Text>
      )}
    </FormulaInserterElement>
  </Flex>
);
