import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";
import { S2Solution } from "@CommonTypes/merchflow/pog/solution";
import { Pagination } from "@CommonTypes/backend/pagination";

export const RouteGetMerchflows_MerchflowId_Stores_StoreCode_ =
  "/merchflows/:merchflowId/stores/:storeCode";

export type ResponseGetMerchflows_MerchflowId_Stores_StoreCode_ = Pagination<StoreVersionsView>;

export interface StoreVersionsView {
  s2Solution?: S2Solution;
  basePog?: {
    basePogId: number;
    basePogData: PogNonCircular;
  };
  storePog?: {
    storePogId: number;
    storePogData: PogNonCircular;
    isApproved: boolean;
  };
  filterConfigId?: number;
  templateId?: string;
  project_id?: number | null;
}
