import { useAtom } from "jotai";
import { useInputsApi } from "src/api";
import { useInlineLoaders } from "src/components/InlineLoader";
import { usePaginationPage } from "src/elements/Pagination";
import { productsPageAtom } from "./atoms";
import { RouteGetInputsProductsAll } from "@CommonApi/inputs/products/all";

export const useProductsPage = () => {
  const { getInputsProductsAllApi } = useInputsApi();
  const { isInlineLoading } = useInlineLoaders();

  const [page, setPage] = usePaginationPage();
  const [products, setProducts] = useAtom(productsPageAtom);

  const isLoading =
    products === undefined || (products === null && isInlineLoading(RouteGetInputsProductsAll));

  const loadProducts = async () => {
    setProducts(await getInputsProductsAllApi({ page, size: 100 }));
  };

  return { products, isLoading, page, setPage, loadProducts };
};
