import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export const initializeScene = (container: HTMLDivElement) => {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    75,
    container.clientWidth / container.clientHeight,
    1,
    5000,
  );
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(container.clientWidth, container.clientHeight);
  if (container.children.length > 0) {
    container.replaceChild(renderer.domElement, container.children[0]);
  } else {
    container.appendChild(renderer.domElement);
  }
  const controls = new OrbitControls(camera, renderer.domElement);

  camera.position.set(0, 500, 900);

  return { scene, camera, renderer, controls };
};

export const setupEventListeners = (
  container: HTMLDivElement,
  scene: THREE.Scene,
  refIsMouseDown: React.MutableRefObject<boolean>,
  refMousePosition: React.MutableRefObject<{ x: number; y: number }>,
) => {
  const onMouseDown = (event: MouseEvent) => {
    refIsMouseDown.current = true;
    refMousePosition.current.x = event.clientX;
    refMousePosition.current.y = event.clientY;
  };

  const onMouseUp = () => {
    refIsMouseDown.current = false;
  };

  const onMouseMove = (event: MouseEvent) => {
    if (!refIsMouseDown.current) return;
    const deltaMove = {
      x: event.clientX - refMousePosition.current.x,
      y: event.clientY - refMousePosition.current.y,
    };
    scene.rotation.y += deltaMove.x * 0.01;
    scene.rotation.x += deltaMove.y * 0.01;
    refMousePosition.current.x = event.clientX;
    refMousePosition.current.y = event.clientY;
  };

  container.addEventListener("mousedown", onMouseDown);
  container.addEventListener("mouseup", onMouseUp);
  container.addEventListener("mousemove", onMouseMove);

  return {
    cleanupEventListeners: () => {
      container.removeEventListener("mousedown", onMouseDown);
      container.removeEventListener("mouseup", onMouseUp);
      container.removeEventListener("mousemove", onMouseMove);
    },
  };
};

export const animate = (
  renderer: THREE.WebGLRenderer,
  scene: THREE.Scene,
  camera: THREE.PerspectiveCamera,
  controls: OrbitControls,
) => {
  const animateFrame = () => {
    requestAnimationFrame(animateFrame);
    controls.update();
    renderer.render(scene, camera);
  };
  animateFrame();
};

export const getPositionRelativeToMesh = ({
  mesh,
  width,
  height,
  depth,
  x,
  y,
  z,
}: {
  mesh: THREE.Mesh;
  width: number;
  height: number;
  depth: number;
  x: number;
  y: number;
  z: number;
}): THREE.Vector3 => {
  let parentGeometry: THREE.Box3;

  const geometry = mesh.geometry;

  if (geometry instanceof THREE.BufferGeometry) {
    const positionAttribute = geometry.attributes.position;
    const positions: THREE.Vector3[] = [];
    for (let i = 0; i < positionAttribute.count; i++) {
      const vertex = new THREE.Vector3();
      vertex.fromBufferAttribute(positionAttribute, i);
      positions.push(vertex);
    }
    parentGeometry = new THREE.Box3().setFromPoints(positions);
    // @ts-ignore
  } else if (geometry instanceof THREE.Geometry) {
    parentGeometry = new THREE.Box3().setFromObject(mesh);
  } else {
    throw new Error("Unsupported geometry type");
  }

  const parentWidth = parentGeometry.max.x - parentGeometry.min.x;
  const parentHeight = parentGeometry.max.y - parentGeometry.min.y;
  const parentDepth = parentGeometry.max.z - parentGeometry.min.z;

  return new THREE.Vector3(
    x - parentWidth / 2 + width / 2,
    y - parentHeight / 2 + height / 2,
    z - parentDepth / 2 + depth / 2,
  );
};
