import { PlanogramShelf } from "../../types/merchflow/pog/shelf";
import {
  getPlanogramItemHeight,
  getPlanogramItemHorizontalFacings,
  getPlanogramItemWidth,
} from "./item";

export const getShelfYBottom = (shelf: PlanogramShelf) =>
  shelf.bay.notchOffset +
  (shelf.notchNo - 1) * shelf.bay.notchSpacing +
  shelf.offset -
  shelf.thickness;

export const getShelfYTop = (shelf: PlanogramShelf) => {
  const index = shelf.bay.shelves.findIndex((s) => s.uniqueId === shelf.uniqueId);
  const topShelf: PlanogramShelf | undefined = shelf.bay.shelves[index + 1];

  if (topShelf) {
    return getShelfYBottom(topShelf);
  }

  return shelf.bay.yTop;
};

export const getShelfXLeft = (shelf: PlanogramShelf) => shelf.bay.xLeft;

export const getShelfXRight = (shelf: PlanogramShelf) => shelf.bay.xLeft + shelf.width;

export const getShelfHeight = (shelf: PlanogramShelf) => {
  // Hangcell fixture height is from bottom to bottom shelf.
  if (shelf.type === "HANGCELL") {
    const index = shelf.bay.shelves.findIndex((s) => s.uniqueId === shelf.uniqueId);

    const bottomShelf: PlanogramShelf | undefined = shelf.bay.shelves[index - 1];

    return bottomShelf
      ? getShelfYBottom(shelf) -
          getShelfYBottom(bottomShelf) -
          bottomShelf.items.reduce(
            (previousMaxItemHeight, item) =>
              Math.max(previousMaxItemHeight, getPlanogramItemHeight(item)),
            0,
          )
      : getShelfYBottom(shelf) - shelf.bay.yBottom;
  }
  // From bottom to top.
  return getShelfYTop(shelf) - getShelfYBottom(shelf);
};

export const getShelfWidth = (shelf: PlanogramShelf) =>
  getShelfXRight(shelf) - getShelfXLeft(shelf);

export const getShelfCoordinates = (shelf: PlanogramShelf) => ({
  xLeft: getShelfXLeft(shelf),
  xRight: getShelfXRight(shelf),
  yBottom: getShelfYBottom(shelf),
  yTop: getShelfYTop(shelf),
});

/**
 * @param shelf - Reference shelf that is used to find all the shelves merged to this shelf.
 * @returns Array of shelves that are all merged, where the first shelf in the array is the most left shelf.
 */
export const getMergedShelves = (shelf: PlanogramShelf) => {
  const mergedShelves: PlanogramShelf[] = [];

  // Loop to most left merged shelf.
  let mostLeftMergedShelf = shelf;
  while (mostLeftMergedShelf.mergedLeft) {
    mostLeftMergedShelf = mostLeftMergedShelf.mergedLeft;
  }

  // Loop to most right merged shelf.
  let shelfToGet: PlanogramShelf | null = mostLeftMergedShelf;
  while (shelfToGet !== null) {
    mergedShelves.push(shelfToGet);
    shelfToGet = shelfToGet.mergedRight;
  }

  return mergedShelves;
};

/**
 * Helper function to calculate wether shelf is overflowing.
 * @param shelf - Reference shelf that is used to find all merged shelves to calculate overflowing status.
 * @returns true if shelf utilization is more than 100%.
 */
export const isShelfOverflowing = (shelf: PlanogramShelf) =>
  getShelfUtilizationPercentage(shelf) > 100;

export const getMergedShelfUnusedWidth = (shelf: PlanogramShelf) => {
  const mergedShelves = getMergedShelves(shelf);

  // Calculate total width of the items on merged shelf.
  // Calculate total width of the merged shelf.
  let itemsTotalWidth = 0;
  let shelvesTotalWidth = 0;

  for (const mergedShelf of mergedShelves) {
    shelvesTotalWidth += getShelfWidth(mergedShelf);

    for (const item of mergedShelf.items) {
      itemsTotalWidth += getPlanogramItemWidth(item) * getPlanogramItemHorizontalFacings(item);
    }
  }

  return shelvesTotalWidth - itemsTotalWidth;
};

/**
 * To get shelf utilization in percentage. Merged shelves are considered as one.
 * @param shelf - Shelf that is used to find all corresponding merged shelves.
 * @returns Shelf utilization in percentage.
 */
export const getShelfUtilizationPercentage = (shelf: PlanogramShelf) => {
  const mergedShelves = getMergedShelves(shelf);

  // Calculate total width of the items on merged shelf.
  // Calculate total width of the merged shelf.
  let itemsTotalWidth = 0;
  let shelvesTotalWidth = 0;

  for (const mergedShelf of mergedShelves) {
    shelvesTotalWidth += getShelfWidth(mergedShelf);

    for (const item of mergedShelf.items) {
      itemsTotalWidth += getPlanogramItemWidth(item) * getPlanogramItemHorizontalFacings(item);
    }
  }

  return (itemsTotalWidth * 100) / shelvesTotalWidth;
};

export const getMergedShelfWidth = (shelf: PlanogramShelf) => {
  const mergedShelves: PlanogramShelf[] = getMergedShelves(shelf);

  return mergedShelves.reduce((totalWidth, shelf) => totalWidth + getShelfWidth(shelf), 0);
};
