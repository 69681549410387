import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { omniAtom } from "src/utils/atoms";

export interface BasePlanogramRulesProps {
  merchflowId: number;
  s2SolutionId: number;
  containers: PlanogramContainer[];
  fixturesPog: Pog;
}

export const basePlanogramRulesAtom = omniAtom({
  key: "modals/basePlanogramRules",
  value: null as BasePlanogramRulesProps | null,
});
