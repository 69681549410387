import { omniAtom } from "src/utils/atoms";

export const merchflowNotesModalAtom = omniAtom({
  key: "modals/merchflowNotes",
  value: null as null | {
    merchflowId: number;
    notes: string;
    onSave?: (notes: string) => void;
  },
});
