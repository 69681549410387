import { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Flex, Grid, VerticalDivider } from "src/elements";
import { StoresSelectorStore } from "./store/types";

interface Props<T> {
  availableStores: T[];
  selectedStores: T[];
  setSelectedStores: (stores: T[]) => void;
}

export const StoresSelector = <T extends StoresSelectorStore>({
  availableStores,
  setSelectedStores,
}: Props<T>) => {
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: (string | number)[];
  }>({});

  const availableFilters = useMemo(() => {
    const filters: { [key: string]: (string | number)[] } = {};

    for (const store of availableStores) {
      for (const key of Object.keys(store).filter((filter) => filter !== "store")) {
        if (filters[key] === undefined) {
          filters[key] = [store[key]];
        } else if (!filters[key].includes(store[key])) {
          filters[key].push(store[key]);
        }
      }
    }

    return filters;
  }, [availableStores]);

  // Effect to save selected stores based on selected filters.
  useEffect(() => {
    setSelectedStores(
      availableStores.filter((store) => {
        for (const filterKey of Object.keys(selectedFilters)) {
          if (
            selectedFilters[filterKey].length > 0 &&
            !selectedFilters[filterKey].includes(store[filterKey])
          ) {
            return false;
          }
        }

        return true;
      }),
    );
  }, [selectedFilters]);

  const selectFilter = <T extends string | number>(
    filterKey: string,
    filterValue: T,
    isChecked: boolean,
  ) => {
    setSelectedFilters({
      ...selectedFilters,
      [filterKey]: isChecked
        ? [...(selectedFilters[filterKey] || []), filterValue]
        : selectedFilters[filterKey].filter((_filterValue) => _filterValue !== filterValue),
    });
  };

  const selectFilterAll = (filterKey: string, isChecked: boolean) => {
    setSelectedFilters({
      ...selectedFilters,
      [filterKey]: isChecked ? availableFilters[filterKey] : [],
    });
  };

  const isFilterAllSelected = (filterKey: string) =>
    selectedFilters[filterKey] &&
    availableFilters[filterKey] &&
    selectedFilters[filterKey].length === availableFilters[filterKey].length;

  return (
    <Flex width="100%" gap="20px" justify="center">
      {Object.keys(availableFilters).map((filterKey, index) => (
        <Fragment key={index}>
          {index !== 0 && <VerticalDivider />}

          <Flex column gap="10px">
            {filterKey === "bay_size" && (
              <>
                <Checkbox
                  label="BAY SIZES"
                  isHeader
                  isCheckboxLeft
                  isChecked={isFilterAllSelected("bay_size")}
                  setIsChecked={(isChecked) => selectFilterAll("bay_size", isChecked)}
                />

                <Flex>
                  <Grid columns="repeat(4, minmax(45px, 1fr))" gap="10px">
                    {Array.from({
                      length: Math.max(...(availableFilters.bay_size as number[]), 32),
                    }).map((_, _baySize) => {
                      const baySize = _baySize + 1;
                      const isDisabled =
                        !availableFilters.bay_size || !availableFilters.bay_size.includes(baySize);
                      const isChecked =
                        selectedFilters.bay_size && selectedFilters.bay_size.includes(baySize);

                      return (
                        <Button
                          key={baySize}
                          isDisabled={isDisabled}
                          variant={isDisabled || isChecked ? "default" : "inverted"}
                          onClick={() => {
                            selectFilter("bay_size", baySize, !isChecked);
                          }}
                        >
                          {baySize}
                        </Button>
                      );
                    })}
                  </Grid>
                </Flex>
              </>
            )}

            {filterKey !== "bay_size" && (
              <Flex minWidth="100px" column gap="10px">
                <Checkbox
                  label={filterKey.replaceAll("_", " ").toUpperCase()}
                  isHeader
                  isCheckboxLeft
                  isChecked={isFilterAllSelected(filterKey)}
                  setIsChecked={(isChecked) => selectFilterAll(filterKey, isChecked)}
                />

                <Grid columns="1fr 1fr" gap="10px">
                  {availableFilters[filterKey].sort().map((filter, i) => (
                    <Checkbox
                      key={i}
                      label={String(filter)}
                      isCheckboxLeft
                      isChecked={
                        selectedFilters[filterKey] && selectedFilters[filterKey].includes(filter)
                      }
                      setIsChecked={(isChecked) => selectFilter(filterKey, filter, isChecked)}
                    />
                  ))}
                </Grid>
              </Flex>
            )}
          </Flex>
        </Fragment>
      ))}
    </Flex>
  );
};
