import { Select } from "src/elements";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { useNavigation } from "src/utils/navigation";

const options = [
  { label: "Store", value: "STORE" },
  { label: "Template", value: "TEMPLATE" },
];
export const ViewAsStoreTemplate = ({
  merchflowId,
  isTemplate = false,
}: {
  merchflowId: number | string;
  isTemplate?: boolean;
}) => {
  const { navigate } = useNavigation();

  const onSelect = (mode: string) => {
    if (isTemplate && mode === "STORE") {
      navigate(
        routeToUrl(pages.merchflows_merchflowId_StoresAll, {
          merchflowId,
        }),
      );
    } else if (!isTemplate && mode === "TEMPLATE") {
      navigate(
        routeToUrl(pages.merchflowTemplates, {
          merchflowId,
        }),
      );
    }
  };

  return (
    <Select
      width="110px"
      options={options}
      placeholder="View as"
      title="View as"
      value={!isTemplate ? "STORE" : "TEMPLATE"}
      setValue={onSelect}
    />
  );
};
