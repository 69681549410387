import { Category, ResponseGetCategoriesList } from "@CommonApi/categories/list";
import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { addCategoryModalAtom } from "./atoms";

export const useAddCategoryModal = () => {
  const { openModal } = useModals();
  const setData = useSetAtom(addCategoryModalAtom);

  const openAddCategoryModal = ({
    categories,
    category,
  }: {
    categories: ResponseGetCategoriesList;
    category?: Category;
  }) => {
    setData({
      categories,
      category: category,
    });
    openModal("AddCategoryModal");
  };

  return { openAddCategoryModal };
};
