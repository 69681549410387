import { PspRules } from "@CommonTypes/backend/PspRules";

export const defaultPspRules: PspRules = {
  fixture: {
    can_adjust_shelf_notches: true,
    can_add_or_delete_shelves: false,
    shelves_same_height_as_base_pog: true,
    vertical_overlap: false,
  },
  range: {
    can_add_non_core_range_products: true,
  },
  merchandising: {
    can_switch_between_horizontal_and_vertical_blocking: false,
    can_break_cases_trays_if_core_range_not_fit: true,
    can_fill_extra_space_next_to_cases_trays_with_loose_products: false,
    how_should_loose_products_be_placed_with_respect_to_their_cases_trays: "behind",
    move_products_between_shelves: false,
    leverage_alternative_orientations: true,
  },
  high_density_mode: {
    enabled: false,
    additional_bay_products_ranging: "TOP",
  },
  optimize_on: "SALES",
};
