import { omniAtom } from "src/utils/atoms";

const prefix = "modals/storeLevelPogLog";

export const dynamicLogAtom = omniAtom({
  prefix,
  key: "dynamicLog",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
  value: {} as any,
});
