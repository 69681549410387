import { ResponseGetMerchflow_merchflowId_FlowUpdateChangeSummary } from "@CommonApi/merchflow/_merchflowId_/flow-update/change-summary";
import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { changeSummaryDataAtom } from "./atoms";

export const useMerchflowFlowUpdateChangeSummaryModal = () => {
  const { openModal } = useModals();

  const setChangeSummary = useSetAtom(changeSummaryDataAtom);

  const openMerchflowFlowUpdateChangeSummaryModal = (
    changeSummary: ResponseGetMerchflow_merchflowId_FlowUpdateChangeSummary,
  ) => {
    setChangeSummary(changeSummary);
    openModal("ModalChangeSummary");
  };

  return { openMerchflowFlowUpdateChangeSummaryModal };
};
