import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { Planogram } from "@CommonTypes/merchflow/pog/pog";
import { getShelfXLeft, getShelfXRight, getShelfYBottom } from "@CommonUtils/pog/shelf";

export const calculateContainerCurrentWidth = (
  container: PlanogramContainer,
  planogram: Planogram,
) => {
  if (container.algoTargetWidth === undefined) {
    return undefined;
  }

  let currentTargetWidth = 0;

  for (const bay of planogram.bays) {
    for (const shelf of bay.shelves) {
      if (container.yBottom <= getShelfYBottom(shelf) && container.yTop > getShelfYBottom(shelf)) {
        const xLeft = Math.max(getShelfXLeft(shelf), container.xLeft);
        const xRight = Math.min(getShelfXRight(shelf), container.xRight);

        if (
          xLeft >= getShelfXLeft(shelf) &&
          xLeft <= getShelfXRight(shelf) &&
          xRight <= getShelfXRight(shelf) &&
          xRight >= getShelfXLeft(shelf)
        ) {
          currentTargetWidth += xRight - xLeft;
        }
      }
    }
  }

  return currentTargetWidth;
};
