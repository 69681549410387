import { Flex } from "src/elements";
import { StepsNavigatorStep } from "./store/types";
import { Step } from "./components/Step";

interface Props {
  steps: StepsNavigatorStep[];
}

export const StepsNavigator = ({ steps }: Props) => {
  const activeStepIndex = steps.findIndex((step) => step.isActive);

  return (
    <Flex width="100%">
      {steps.map((step, i) => (
        <Step
          key={i}
          {...step}
          isFirst={i === 0}
          isLast={i === steps.length - 1}
          status={
            (step.isDisabled && "DISABLED") ||
            (i < activeStepIndex && "COMPLETE") ||
            (i === activeStepIndex && "ACTIVE") ||
            "INCOMPLETE"
          }
        />
      ))}
    </Flex>
  );
};
