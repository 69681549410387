import { Radios } from "src/elements";

export interface PlanogramOptimizeOnProps {
  optimizeOn: "SALES" | "PROFIT" | "DISPERSION";
  setOptimizeOn: (optimizeOn: PlanogramOptimizeOnProps["optimizeOn"]) => void;
}

export const PlanogramOptimizeOn = ({ optimizeOn, setOptimizeOn }: PlanogramOptimizeOnProps) => (
  <Radios
    label="Optimize On:"
    options={[
      {
        label: "Sales",
        value: "SALES",
      },
      {
        label: "Profit",
        value: "PROFIT",
      },
      {
        label: "Dispersion",
        value: "DISPERSION",
      },
    ]}
    value={optimizeOn}
    setValue={setOptimizeOn}
  />
);
