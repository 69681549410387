import { omniAtom } from "src/utils/atoms";

const prefix = "components/filtersSidebar";

export const storesFilters = [
  {
    id: "returnOnSpace",
    title: "Return on Space",
    options: ["Greater than 90%", "Between 75% to 90%", "Lower than 75%"],
    isExpanded: false,
    value: null as string | null,
  },

  {
    id: "coreRange",
    title: "Core Range",
    options: ["Equal to 100%", "Between 90% to 99%", "Lower than 90%"],
    isExpanded: false,
    value: null as string | null,
  },

  {
    id: "shelfAlignment",
    title: "Shelf Alignment",
    options: ["Greater than 90%", "Between 75% to 90%", "Lower than 75%"],
    isExpanded: false,
    value: null as string | null,
  },

  {
    id: "dosMos",
    title: "DOS / MOS",
    options: ["Greater than 95%", "Between 90% to 95%", "Lower than 90%"],
    isExpanded: false,
    value: null as string | null,
  },

  {
    id: "size",
    title: "Size",
    options: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ],
    isExpanded: false,
    value: [] as string[],
  },

  {
    id: "storePlanograms",
    title: "Store Planograms",
    options: ["Show all", "Show only Approved", "Show only Un-Approved"],
    isExpanded: false,
    value: "Show all" as string | null,
  },
];

export const storesFiltersAtom = omniAtom({
  prefix,
  key: "filters",
  value: storesFilters,
});

export const filtersSidebarIsVisibleAtom = omniAtom({
  prefix,
  key: "isVisible",
  value: false,
});
