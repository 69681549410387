import { useModals } from "src/components/Modals";

export const useMerchflowPSAReviewModal = () => {
  const { openModal } = useModals();

  const openMerchflowPSAReviewModal = () => {
    openModal("ModalMerchflowPSAReview");
  };

  return { openMerchflowPSAReviewModal };
};
