import { Pagination } from "@CommonTypes/backend/pagination";

export const RouteGetMerchflowTemplateAllStores_templateId_ =
  "/merchflow/template/all-stores/:templateId";

export type ResponseGetMerchflowTemplateAllStores_templateId_ = Pagination<TemplateAssignStore>;

export interface TemplateAssignStore {
  template_id: string;
  merchflow_id: number;
  cluster: string;
  store_id: number;
  store_name: string;
  size: number;
  region: string;
  fixture: string;
  comment: string;
  store_pog_id: number;
  approve_status_code: TemplateAssignStoreStatus;
  filter_config_id: number;
}

export enum TemplateAssignStoreStatus {
  NONE = "NONE",
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
}
