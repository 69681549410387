import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { pogDeleteProductDataAtom } from "./atoms";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";

export const usePogDeleteProductModal = () => {
  const { openModal } = useModals();
  const setData = useSetAtom(pogDeleteProductDataAtom);

  const openPogDeleteProductModal = ({
    item,
    availableStores,
  }: {
    item: PlanogramItem;
    availableStores: ResponseMerchFlowApplyFilters["filter_config"];
  }) => {
    setData({ item, availableStores });
    openModal("ModalPogDeleteProduct");
  };

  return { openPogDeleteProductModal };
};
