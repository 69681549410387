export const RouteGetMerchflowsFilters = "/merchflows/filters";

export interface ParamsGetMerchflowsFilters {
  retailer_category_code: string;
  category_code: string;
  base_store_code?: string;
}

export interface ResponseGetMerchflowsFilters {
  baySizes: number[];
  states: string[];
  fixtures: {
    section_height: number[];
    section_width: number[];
  };
  clusters: string[];
  business_type: string[];
  activity_type: string[];
  stores: {
    bay_size: number;
    state: string;
    section_height: number;
    section_width: number;
    cluster: string;
    retailer_category_code: string;
    category_code: string;
    store: string;
    template_id: string;
    business_type: string;
    activity_type: string;
  }[];
}
