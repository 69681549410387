import { Color, Flex, Text } from "src/elements";
import styled from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Item: any = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px 0 0 0;
  flex: 1 1 0px;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -3px;
    display: block;
    width: 6px;
    height: 6px;
    background: ${({ active }) => (active ? Color.primary : Color.spaceGray)};
    border: 2px solid ${({ active }) => (active ? Color.primary : Color.spaceGray)};
    border-radius: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: calc(-45% + 10px);
    right: calc(55% + 10px);
    display: block;
    height: 2px;
    background: ${({ active }) => (active ? Color.primary : Color.spaceGray)};
  }

  &:first-child:before {
    display: none;
  }
`;

interface Props<T> {
  step: T;
  steps: { id: T; title: string; onClick?: () => void }[];
}
export function StepHeader<T>({ steps, step }: Props<T>) {
  return (
    <Flex width="100%" margin="0 0 25px 0">
      {steps.map(({ id, title, onClick }) => {
        const isActive =
          steps.findIndex((s) => s.id === id) <= steps.findIndex((s) => s.id === step);

        return (
          <Item key={`${id}-${title}`} active={isActive} onClick={isActive ? onClick : null}>
            <Text
              variant="body3"
              color={isActive ? Color.textMain : Color.spaceGray}
              textAlign="center"
            >
              {title}
            </Text>
          </Item>
        );
      })}
    </Flex>
  );
}
