import { useAtom } from "jotai";

import { inlineLoadersAtom } from "./atoms";
import { InlineLoader } from "./types";

export const useInlineLoaders = () => {
  const [inlineLoaders, setInlineLoaders] = useAtom(inlineLoadersAtom);

  const addInlineLoader = (loader: InlineLoader) => {
    setInlineLoaders((loaders) => [...loaders, loader]);
  };

  const removeInlineLoader = (loader: InlineLoader) => {
    setInlineLoaders((loaders) => loaders.filter((l) => l !== loader));
  };

  const isInlineLoading = (loader: InlineLoader) => {
    return inlineLoaders.includes(loader);
  };

  return {
    addInlineLoader,
    removeInlineLoader,
    isInlineLoading,
  };
};
