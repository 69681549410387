export const RouteGetMerchflow_merchflowId_FlowUpdateProducts =
  "/merchflow/:merchflowId/flow-update/products";

export type ResponseGetMerchflow_merchflowId_FlowUpdateProducts = {
  addable_products: FlowUpdateProduct[];
  removable_products: FlowUpdateProduct[];
};

export interface FlowUpdateProduct {
  product_code: string;
  name: string;
}
