import { useEffect, useState } from "react";
import * as THREE from "three";
import { getPositionRelativeToMesh } from "../store/utils";
import { Item } from "./Item";
import { FreezerSection } from "@CommonTypes/Freezer/Freezer";

interface Props {
  parentMesh: THREE.Mesh;
  section: FreezerSection;
}

export const Section = ({
  parentMesh,
  section: sectionData,
  section: { width, height, depth, x, y, z },
}: Props) => {
  const [section, setSection] = useState<THREE.Mesh | null>(null);

  useEffect(() => {
    const geometry = new THREE.BoxGeometry(width, height, depth);
    const material = new THREE.MeshBasicMaterial({
      color: "orange",
      wireframe: true,
    });

    const section = new THREE.Mesh(geometry, material);
    setSection(section);

    const position = getPositionRelativeToMesh({
      mesh: parentMesh,
      width,
      height,
      depth,
      x,
      y,
      z,
    });

    section.position.set(position.x, position.y, position.z);
    parentMesh.add(section);

    return () => {
      parentMesh.remove(section);
    };
  }, []);

  return (
    <>
      {section &&
        sectionData.items.map((item, index) => (
          <Item key={index} parentMesh={section} item={item} />
        ))}
    </>
  );
};
