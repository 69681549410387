import { useEffect, useRef, useState } from "react";
import { Flex } from "src/elements";
import { initializeScene, setupEventListeners, animate } from "./store/utils";
import { Base } from "./components/Base";
import { FreezerMesh } from "./components/FreezerMesh";
import * as freezerData from "./dummy.json";
import * as THREE from "three";
import { Item } from "./components/Item";
import { Section } from "./components/Section";

export const Freezer = () => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refIsMouseDown = useRef(false);
  const refMousePosition = useRef({ x: 0, y: 0 });

  const [scene, setScene] = useState<THREE.Scene | null>(null);
  const [freezer, setFreezer] = useState<THREE.Mesh | null>(null);

  useEffect(() => {
    if (!refContainer.current) return;

    const { scene, camera, renderer, controls } = initializeScene(refContainer.current);
    setScene(scene);

    const { cleanupEventListeners } = setupEventListeners(
      refContainer.current,
      scene,
      refIsMouseDown,
      refMousePosition,
    );

    animate(renderer, scene, camera, controls);

    return () => {
      cleanupEventListeners();
    };
  }, []);

  return (
    <Flex flexGrow ref={refContainer}>
      {scene && (
        <>
          <FreezerMesh
            scene={scene}
            setFreezer={setFreezer}
            width={freezerData.width}
            height={freezerData.height}
            depth={freezerData.depth}
            wallThickness={freezerData.wallThickness}
          />

          {freezer && (
            <>
              <Base
                scene={scene}
                parentMesh={freezer}
                width={freezerData.width + 2 * freezerData.wallThickness}
                height={freezerData.baseHeight}
                depth={freezerData.depth + 2 * freezerData.wallThickness}
                wallThickness={freezerData.wallThickness}
              />

              {freezerData.items.map((item, index) => (
                <Item key={index} parentMesh={freezer} item={item} />
              ))}

              {freezerData.baskets.map((basket, index) => (
                <Section key={index} section={basket} parentMesh={freezer} />
              ))}
            </>
          )}
        </>
      )}
    </Flex>
  );
};
