import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { omniAtom } from "src/utils/atoms";

export const pagesS0PogAtom = omniAtom({
  key: "pages/s0/pog",
  value: null as Pog | null,
});

export const pagesS0ContainersAtom = omniAtom({
  key: "pages/s0/containers",
  value: null as PlanogramContainer[] | null,
});
