import { ResponseGetViolationMerchflow_merchflowId_ } from "@CommonApi/violation/merchflow/_merchflowId_";
import { omniAtom } from "src/utils/atoms";

export const merchflowReviewUpdateAtom = omniAtom({
  value: null as null | {
    merchflowId: number;
    violations?: ResponseGetViolationMerchflow_merchflowId_;
  },
  key: "modals/merchflowReviewUpdate",
});
