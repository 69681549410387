import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { PlanogramItem, Pog } from "@CommonTypes/merchflow/pog/pog";
import { omniAtom } from "src/utils/atoms";

const prefix = "modals/pogNewProduct";

export const newProductModalDataAtom = omniAtom({
  prefix,
  key: "data",
  value: null as {
    currentStoreCode: string;
    planogramItem: PlanogramItem;
    pog: Pog;
    availableStores: ResponseMerchFlowApplyFilters["filter_config"];
  } | null,
});
