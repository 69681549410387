import { omniAtom } from "src/utils/atoms";
import { FeatureFlag, Setting } from "./types";

export const featureFlagsAtom = omniAtom({
  key: "featureFlags",
  value: null as null | Record<FeatureFlag, boolean>,
});

export const settingsAtom = omniAtom({
  key: "settings",
  value: null as null | Record<Setting, string>,
});
