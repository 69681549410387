import { User } from "@CommonApi/auth/login";
import { useAtom } from "jotai";
import { omniAtom } from "src/utils/atoms";

export const userAtom = omniAtom({
  key: "user",
  value: null as unknown as User, // Authorized pages all have user available.
});

export const useUser = () => {
  const [user, setUser] = useAtom(userAtom);
  return { user, setUser };
};
