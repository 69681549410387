import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import { useAtom } from "jotai";
import { useMemo, useRef, useState } from "react";
import { highlightsModeAtom } from "src/components/Planogram/store/atoms";
import { PogAtom } from "src/components/Planogram/store/types";
import { isPlanogramItemMatching } from "src/components/Planogram/store/utils";
import { Button, Color, Flex, Scroller, Search, Text } from "src/elements";

import styled from "styled-components";

import { Dropper } from "../../../Item/Dropper";
import { onDropOverToUnrangedItems } from "../../../Item/store/utils";
import { UnrangedItem } from "./components/UnrangedItem";
import { usePogNewProductModal } from "src/modals/PogNewProduct/store/hooks";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { useInlineLoaders } from "src/components/InlineLoader";
import { RouteGetMerchflowsReviewFlows_merchflowId_ } from "@CommonApi/merchflows/review/flows/_merchflowId_";
import { usePOG } from "src/components/Planogram/store/actions";
import { Filterable } from "src/components/Filterable/Filterable";

type ViewFilter = "ALL" | "NEW" | "CORE_RANGE";

type SortFilter = "minOnShelf" | "quantity" | "salesAmount";

const sortByOptions: { label: string; value: SortFilter }[] = [
  { label: "MOS", value: "minOnShelf" },
  { label: "Movement", value: "quantity" },
  { label: "Sales", value: "salesAmount" },
];

const viewByOptions: { label: string; value: ViewFilter }[] = [
  { label: "All", value: "ALL" },
  { label: "New", value: "NEW" },
  { label: "Core Range", value: "CORE_RANGE" },
];

const UnrangedItemsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;
  position: relative;

  // Render draggable item placeholder as unranged item placeholder.
  [data-placeholder] {
    height: 52px;
    max-height: 52px;
    width: 100%;

    .item-dropper-container {
      display: none;
    }

    .planogram-item {
      height: 100%;
      width: 100%;
      box-shadow: none;

      > * {
        display: none;
      }

      ::after {
        display: flex;
      }
    }
  }
`;

interface Props {
  merchflowId?: number;
  currentStoreCode?: string;
  scaleX: number;
  scaleY: number;
  pogAtom: PogAtom;
  availableStores: ResponseMerchFlowApplyFilters["filter_config"] | null;
}

export const UnrangedItems = ({
  merchflowId,
  currentStoreCode,
  scaleX,
  scaleY,
  pogAtom,
  availableStores,
}: Props) => {
  const { openPogNewProductModal } = usePogNewProductModal();
  const { isInlineLoading } = useInlineLoaders();
  const { moveProductToUnrangedItems } = usePOG(pogAtom);

  const [pog] = useAtom(pogAtom);
  const [highlightsMode] = useAtom(highlightsModeAtom);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<ViewFilter>("ALL");
  const [sortBy, setSortBy] = useState<SortFilter>("quantity");
  const [sortByDirection, setSortByDirection] = useState<"ASCENDING" | "DESCENDING">("DESCENDING");

  const refUnrangedItems = useRef<HTMLDivElement>(null);
  const isAvailableStoresLoading = isInlineLoading(RouteGetMerchflowsReviewFlows_merchflowId_);

  const filteredAndSortedUnrangedItems = useMemo(() => {
    return (
      pog.unrangedItems
        // Search input filter.
        .filter((unrangedItem) => isPlanogramItemMatching(unrangedItem, search))

        // New items filter.
        .filter((item) => {
          switch (filter) {
            case "NEW":
              return item.newItemFlag === true;
            case "CORE_RANGE":
              return item.inCoreRange === true;
            case "ALL":
              return true;
          }
        })

        // Sort by mos or quantity.
        .sort((item1, item2) =>
          item1[sortBy] < item2[sortBy]
            ? sortByDirection === "DESCENDING"
              ? 1
              : -1
            : sortByDirection === "DESCENDING"
              ? -1
              : 1,
        )
    );
  }, [pog, search, filter, sortBy, sortByDirection]);

  const minimumRecommendedQuantities = useMemo(() => {
    const recommendedQuantities: { [key: string]: number } = {};

    for (const bay of pog.planogram.bays) {
      for (const shelf of bay.shelves) {
        for (const item of shelf.items) {
          if (item.variant === "") continue;

          if (
            recommendedQuantities[item.variant] === undefined ||
            item.quantity < recommendedQuantities[item.variant]
          ) {
            recommendedQuantities[item.variant] = item.quantity;
          }
        }
      }
    }

    return recommendedQuantities;
  }, [pog]);

  const onDropOver = () => {
    onDropOverToUnrangedItems({
      refUnrangedItems,
      onDrop,
    });
  };

  const onDrop = (item: PlanogramItem) => {
    moveProductToUnrangedItems(item);
  };

  const openNewProductModal = () => {
    if (availableStores && currentStoreCode) {
      openPogNewProductModal({ currentStoreCode, pog, availableStores });
    }
  };

  return (
    <>
      <Flex width="100%" column gap="10px" flexGrow={1} minHeight="0">
        <Flex padding="10px 10px 0 10px">
          <Search search={search} setSearch={setSearch} />
        </Flex>

        <Flex padding="0 10px" gap="5px 10px" flexWrap="wrap">
          <Filterable
            label="Items"
            options={viewByOptions}
            value={filter}
            setValue={setFilter}
            clearable="ALL"
          />

          <Filterable
            label="Sort by"
            direction={sortByDirection}
            setDirection={setSortByDirection}
            options={sortByOptions}
            value={sortBy}
            setValue={setSortBy}
          />
        </Flex>

        <Scroller gutter="stable both-edges">
          <UnrangedItemsContent ref={refUnrangedItems} className="unranged-items">
            <Dropper
              side="left"
              width="100%"
              height="100%"
              onDropOver={onDropOver}
              onDrop={onDrop}
            />

            {filteredAndSortedUnrangedItems.map((unrangedItem, i) => {
              const isRecommended =
                minimumRecommendedQuantities[unrangedItem.variant] !== undefined &&
                unrangedItem.quantity > minimumRecommendedQuantities[unrangedItem.variant];

              return (
                <UnrangedItem
                  key={i}
                  item={unrangedItem}
                  scaleX={scaleX}
                  scaleY={scaleY}
                  isRecommended={isRecommended}
                  extraInformation={
                    (sortBy === "minOnShelf" && "mos") ||
                    (sortBy === "salesAmount" && "salesAmount") ||
                    "movement"
                  }
                  pogAtom={pogAtom}
                  availableStores={availableStores}
                />
              );
            })}

            {filteredAndSortedUnrangedItems.length === 0 && (
              <Flex width="100%" flexGrow={1} justify="center" align="center">
                <Text variant="h4" color={Color.spaceGray}>
                  No Unranged Items
                </Text>
              </Flex>
            )}
          </UnrangedItemsContent>
        </Scroller>

        <Flex className="add-new-product" width="100%" padding="0 10px 10px 10px">
          <Button
            width="100%"
            color="greenSmoke"
            onClick={openNewProductModal}
            isDisabled={highlightsMode || !merchflowId || !currentStoreCode}
            isLoading={isAvailableStoresLoading}
          >
            Add New Product
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
