import styled from "styled-components";

export const InnerWrapper = styled.div<{ isTranslucent?: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ isTranslucent }) => isTranslucent && "0.2"};
  pointer-events: none;
`;
