import React from "react";
import { Flex } from "../Box/Box";
import { Color } from "../Color/Color";
import { VerticalDivider } from "../Dividers";
import { Tag } from "./Tag";

interface Props {
  tags: { label: string; value: string | number }[];
}

export const Tags = ({ tags }: Props) => (
  <Flex gap="5px" align="center">
    {tags.map((tag, index) => (
      <React.Fragment key={index}>
        {index > 0 && <VerticalDivider color={Color.lightGrayHover} />}

        <Tag label={tag.label} value={tag.value} />
      </React.Fragment>
    ))}
  </Flex>
);
