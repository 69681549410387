import { ResponseGetUsersList } from "@CommonApi/users/list";
import { useAtom } from "jotai";
import { useApi } from "src/api";
import { omniAtom } from "src/utils/atoms";

const usersPageAtom = omniAtom({
  key: "pages/users",
  value: undefined as undefined | null | ResponseGetUsersList,
});

export const useUsersPage = () => {
  const { getUsersListApi } = useApi();

  const [users, setUsers] = useAtom(usersPageAtom);

  const loadUsers = async () => {
    setUsers(await getUsersListApi());
  };

  return { users, loadUsers };
};
