import { IconType } from "src/elements/Icon";
import { pages } from "src/utils";

export const sidebarMenu: {
  title: string;
  path: string;
  icon: IconType;
}[] = [
  {
    title: "Categories",
    path: pages.categories,
    icon: "categories",
  },
  {
    title: "Stores",
    path: pages.stores,
    icon: "stores",
  },
  {
    title: "Products",
    path: pages.products,
    icon: "basket",
  },
  {
    title: "Fixtures",
    path: pages.fixtures,
    icon: "fixtures",
  },
  {
    title: "Merchflows",
    path: pages.merchflows,
    icon: "merchflows",
  },
  {
    title: "StoreSummary",
    path: pages.storeSummary,
    icon: "stores",
  },
  {
    title: "Users",
    path: pages.users,
    icon: "users",
  },
];
