export const RoutePostMerchflows_MerchflowId_ExportsBatch =
  "/merchflows/:merchflowId/exports/batch";

export type StorePogExportType = "psa" | "pdf" | "csv";

export type PayloadPostMerchflows_MerchflowId_ExportsBatch = {
  type: StorePogExportType;
} & (
  | {
      // To export selected store pogs
      store_pogs_ids: number[];
      exportAllStorePogExclude?: never;
      approved_store_pogs?: never;
      base_pog_ids?: never;
    }
  | {
      // To export all store pogs
      store_pogs_ids?: never;
      exportAllStorePogExclude: number[];
      approved_store_pogs?: never;
      base_pog_ids?: never;
    }
  | {
      // To export all approved store pogs.
      store_pogs_ids?: never;
      exportAllStorePogExclude?: never;
      approved_store_pogs: boolean;
      base_pog_ids?: never;
    }
  | {
      // To export selected base pogs.
      store_pogs_ids?: never;
      exportAllStorePogExclude?: never;
      approved_store_pogs?: never;
      base_pog_ids: number[];
    }
);

export interface ResponsePostMerchflows_MerchflowId_ExportsBatch {
  batch_id: number;
  type: StorePogExportType;
  status: "IN_PROGRESS" | "CREATED" | "ERROR";

  url?: string;
}

export const RouteGetMerchflows_MerchflowId_ExportsBatch =
  "/merchflows/:merchflowId/exports/batch/:batchId";

export interface ResponseGetMerchflows_MerchflowId_ExportsBatch {
  batch_id: number;
  status: "CREATED" | "IN_PROGRESS" | "DONE";
  type: StorePogExportType;

  // Download URL is provided once the batch exporting is done.
  url?: string;
}
