import { FlowFilterConfig } from "@CommonTypes/owa-db/merchantSchema";

export const RoutePutMerchflows_merchflowId_Filters = "/merchflows/:merchflowId/filters";

export interface PayloadPutMerchflows_merchflowId_Filters {
  flowId: number;
  filter_config: FilterConfigPayloadType;
}

export type FilterConfigPayloadType = Omit<FilterConfigType, "stores"> & {
  stores: FilterConfigPayloadStore[];
};

export type FilterConfigPayloadStore = Omit<
  FilterConfigStore,
  "ffUploadId" | "pmUploadId" | "posUploadId"
>;

export type ResponsePutMerchflows_merchflowId_Filters = Omit<FlowFilterConfig, "filter_config"> & {
  filter_config: FilterConfigType;
};
export interface FilterConfigType {
  baySizes: number[];
  clusters: string[];
  fixtures: {
    section_width: number[];
    section_height: number[];
  };
  states: string[];
  stores: FilterConfigStore[];
  cdt?: unknown;
  hierachy?: unknown;
}
export interface FilterConfigStore {
  bay_size: number;
  state: string;
  section_height: number;
  section_width: number;
  cluster: string;
  retailer_category_code: string;
  category_code: string;
  store: string;
  template_id: string;
  ffUploadId: number;
  pmUploadId: number;
  posUploadId: number;
}
