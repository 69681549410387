import { useSetAtom } from "jotai";
import { useEffect, useRef } from "react";
import { Button, Color, Flex, Icon, Text } from "src/elements";
import { Transition } from "src/utils";
import styled from "styled-components";

import { toastsAtom } from "../store/atoms";
import { useToasts } from "../store/hooks";
import { Toast as ToastType } from "../store/types";
import { TimerBar } from "./TimerBar";

const ToastElementContainer = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  transition: height ${Transition.medium};

  &[data-state="visible"] {
    animation: toast-show ${Transition.slow} forwards;
  }

  &[data-state="close"] {
    height: 0px !important;
  }

  &[data-state="delete"] {
    display: none;
  }

  @keyframes toast-show {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

const ToastElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  background: ${Color.white};
  box-shadow: rgba(22, 22, 22, 0.15) 0px 10px 25px;
  border-radius: 3px;
  overflow: hidden;

  &[data-state="close"] {
    animation: toast-hide ${Transition.medium} forwards;
  }

  @keyframes toast-hide {
    from {
      transform: translateY(0%);
      opacity: 1;
    }
    to {
      transform: translateY(50%);
      opacity: 0;
    }
  }
`;

interface Props {
  toast: ToastType;
}

export const Toast = ({
  toast,
  toast: { id, title, message, type, state, actions, duration, height },
}: Props) => {
  const { closeToast } = useToasts();

  const setToasts = useSetAtom(toastsAtom);

  const refToastContainer = useRef<HTMLDivElement>(null);
  const refToast = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Change current toast state from open to visible.
    if (toast.state === "open") {
      showToast();
    }

    // Toast was closed, initiate toast deletion.
    if (toast.state === "close") {
      deleteToast();
    }
  }, [toast]);

  const showToast = () => {
    setToasts((toasts) =>
      toasts.map((toast) => {
        if (toast.id === id && toast.state === "open") {
          return {
            ...toast,
            state: "visible",
            height: (refToast.current?.getBoundingClientRect().height || 90) + 10,
          };
        }

        return toast;
      }),
    );

    // Timeout to close toast automatically.
    if (duration !== "infinite") {
      setTimeout(() => {
        onClose();
      }, duration);
    }
  };

  const deleteToast = () => {
    setTimeout(() => {
      setToasts((toasts) =>
        toasts.map((toast) => {
          if (toast.id === id && toast.state === "close") {
            return {
              ...toast,
              state: "delete",
            };
          }

          return toast;
        }),
      );
    }, 1000);
  };

  const onClose = () => {
    closeToast(id);
  };

  return (
    <ToastElementContainer ref={refToastContainer} data-state={state} height={height}>
      <ToastElement ref={refToast} data-state={state}>
        <Flex flexGrow={1} padding="7px" align="start" gap="8px">
          <Flex>
            <Icon
              name={
                (type === "success" && "toastSuccess") ||
                (type === "warning" && "toastWarning") ||
                (type === "error" && "toastError")
              }
              color={
                (type === "success" && Color.success) ||
                (type === "warning" && Color.yellow) ||
                (type === "error" && Color.red)
              }
            />
          </Flex>

          <Flex flexGrow={1} minWidth="0">
            <Flex direction="column" gap="5px" flexGrow={1}>
              <Text variant="body1" color={Color.textSecondary}>
                {title}
              </Text>

              {message.length > 0 && message !== "{}" && (
                <Flex>
                  <Text
                    variant="caption1"
                    color={Color.textSecondary}
                    wordBreak="break-all"
                    lineClamp={6}
                  >
                    {message}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>

          <Flex minWidth="14px" minHeight="14px" justify="center" align="center" onClick={onClose}>
            <Icon name="close" size={10} color={Color.spaceGray} />
          </Flex>
        </Flex>

        {actions.length > 0 && (
          <Flex padding="7px" justify="right" gap="6px">
            {actions.map((action, i) => (
              <Button
                key={i}
                color={
                  (type === "success" && "success") ||
                  (type === "warning" && "yellow") ||
                  (type === "error" && "red")
                }
                size="small"
                onClick={action.onClick}
              >
                {action.label}
              </Button>
            ))}
          </Flex>
        )}

        <TimerBar type={type} duration={duration} />
      </ToastElement>
    </ToastElementContainer>
  );
};
