/* eslint-disable no-extra-label */
import {
  ResponseGetMerchflowsFilters,
  RouteGetMerchflowsFilters,
} from "@CommonApi/merchflows/filters";
import { useInlineLoaders } from "src/components/InlineLoader";
import {
  Button,
  Checkbox,
  Color,
  Flex,
  Grid,
  HorizontalDivider,
  Scroller,
  Spinner,
  Text,
  VerticalDivider,
} from "src/elements";
import { MerchflowReviewSelectedFilters, MerchflowReviewStep } from "../store/types";
import { numberSorterReversed } from "src/utils/array";
import { useMemo } from "react";
import { useMerchflowsApi } from "src/api/merchflows";
import {
  ResponsePutMerchflows_merchflowId_Filters,
  RoutePutMerchflows_merchflowId_Filters,
} from "@CommonApi/merchflows/_reviewId_/filters";
import { ResponseGetMerchflowsVariants } from "@CommonApi/merchflows/variants";

interface Props {
  merchflowId: number;
  availableFilters: ResponseGetMerchflowsFilters | null;
  setStep: (step: MerchflowReviewStep) => void;
  selectedFilters: MerchflowReviewSelectedFilters;
  setSelectedFilters: (filters: MerchflowReviewSelectedFilters) => void;
  appliedFilters: ResponsePutMerchflows_merchflowId_Filters | null;
  setAppliedFilters: (appliedFilters: ResponsePutMerchflows_merchflowId_Filters | null) => void;
  setAvailableVariants: (variants: ResponseGetMerchflowsVariants | null) => void;
}

export const Step2Filters = ({
  merchflowId,
  availableFilters,
  setStep,
  selectedFilters,
  setSelectedFilters,
  appliedFilters,
  setAppliedFilters,
  setAvailableVariants,
}: Props) => {
  const { putMerchflows_merchflowId_FiltersApi, getMerchflowsVariantsApi } = useMerchflowsApi();
  const { isInlineLoading } = useInlineLoaders();

  const isFiltersLoading = isInlineLoading(RouteGetMerchflowsFilters);
  const isApplyFiltersLoading = isInlineLoading(RoutePutMerchflows_merchflowId_Filters);

  const filteredStores = useMemo(() => {
    const stores: ResponseGetMerchflowsFilters["stores"] = [];

    if (availableFilters) {
      availableStoresLooper: for (const availableStore of availableFilters.stores) {
        if (
          selectedFilters.baySizes.length > 0 &&
          !selectedFilters.baySizes.includes(availableStore.bay_size)
        ) {
          continue availableStoresLooper;
        }

        if (
          selectedFilters.clusters.length > 0 &&
          !selectedFilters.clusters.includes(availableStore.cluster)
        ) {
          continue availableStoresLooper;
        }

        if (
          selectedFilters.fixtureHeights.length > 0 &&
          !selectedFilters.fixtureHeights.includes(availableStore.section_height)
        ) {
          continue availableStoresLooper;
        }

        if (
          selectedFilters.fixtureWidths.length > 0 &&
          !selectedFilters.fixtureWidths.includes(availableStore.section_width)
        ) {
          continue availableStoresLooper;
        }

        if (
          selectedFilters.states.length > 0 &&
          !selectedFilters.states.includes(availableStore.state)
        ) {
          continue availableStoresLooper;
        }

        stores.push(availableStore);
      }
    }

    return stores;
  }, [availableFilters, selectedFilters]);

  function utilAreAllSelected<T>(selectedFilters: T[], availableFilters: T[]) {
    return selectedFilters.length === availableFilters.length;
  }

  function utilSelectFilter<T>(filterKey: string, filterValue: T, isChecked: boolean) {
    setSelectedFilters({
      ...selectedFilters,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      [filterKey]: isChecked
        ? //@ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          [...selectedFilters[filterKey], filterValue]
        : //@ts-ignore
          selectedFilters[filterKey].filter((_filterValue: T) => _filterValue !== filterValue),
    });
  }

  function utilSelectAllFilters<T>(filterKey: string, filters: T[], isChecked: boolean) {
    setSelectedFilters({
      ...selectedFilters,
      [filterKey]: isChecked ? filters : [],
    });
  }

  const previousStep = () => {
    setStep("STEP_1_DETAILS");
  };

  const applyFilters = async () => {
    const response = await putMerchflows_merchflowId_FiltersApi({
      flowId: merchflowId,
      filter_config: {
        baySizes: selectedFilters.baySizes,
        clusters: selectedFilters.clusters,
        fixtures: {
          section_height: selectedFilters.fixtureHeights,
          section_width: selectedFilters.fixtureWidths,
        },
        states: selectedFilters.states,
        stores: filteredStores,
      },
    });

    if (response) {
      setAppliedFilters(response);
      nextStep();
      setAvailableVariants(
        await getMerchflowsVariantsApi({
          filterConfigId: response.id,
        }),
      );
    }
  };

  const nextStep = () => {
    setStep("STEP_3_MERGE_RULES");
  };

  return (
    <Flex width="100%">
      {isFiltersLoading && <Spinner size="big" label="Stores are loading..." isCentered />}

      {!isFiltersLoading && availableFilters && (
        <Flex width="100%" column gap="20px" justify="center" flexGrow={1}>
          <Scroller>
            <Flex gap="20px">
              <Flex column gap="10px">
                <Checkbox
                  label="BAY SIZES"
                  isHeader
                  isCheckboxLeft
                  isChecked={utilAreAllSelected(
                    selectedFilters.baySizes,
                    availableFilters.baySizes,
                  )}
                  setIsChecked={(isChecked) =>
                    utilSelectAllFilters("baySizes", availableFilters.baySizes, isChecked)
                  }
                />

                <Flex>
                  <Grid columns="repeat(4, minmax(45px, 1fr))" gap="10px">
                    {Array.from({
                      length: Math.max(...availableFilters.baySizes, 32),
                    }).map((_, _baySize) => {
                      const baySize = _baySize + 1;
                      const isDisabled = !availableFilters.baySizes.includes(baySize);
                      const isSelected = selectedFilters.baySizes.includes(baySize);

                      return (
                        <Button
                          key={baySize}
                          isDisabled={isDisabled}
                          variant={isDisabled || isSelected ? "default" : "inverted"}
                          onClick={() => utilSelectFilter("baySizes", baySize, !isSelected)}
                        >
                          <>{baySize}</>
                        </Button>
                      );
                    })}
                  </Grid>
                </Flex>
              </Flex>

              <VerticalDivider />

              <Flex column gap="10px">
                <Checkbox
                  label="STATES"
                  isHeader
                  isCheckboxLeft
                  isChecked={utilAreAllSelected(selectedFilters.states, availableFilters.states)}
                  setIsChecked={(isChecked) =>
                    utilSelectAllFilters("states", availableFilters.states, isChecked)
                  }
                />

                <Grid columns="1fr 1fr" gap="10px">
                  {availableFilters.states.sort().map((state, i) => (
                    <Checkbox
                      key={i}
                      label={state}
                      isCheckboxLeft
                      isChecked={selectedFilters.states.includes(state)}
                      setIsChecked={(isChecked) => utilSelectFilter("states", state, isChecked)}
                    />
                  ))}
                </Grid>
              </Flex>

              <VerticalDivider />

              <Flex column gap="10px">
                <Checkbox
                  label="FIXTURE HEIGHTS"
                  isHeader
                  isCheckboxLeft
                  isChecked={utilAreAllSelected(
                    selectedFilters.fixtureHeights,
                    availableFilters.fixtures.section_height,
                  )}
                  setIsChecked={(isChecked) =>
                    utilSelectAllFilters(
                      "fixtureHeights",
                      availableFilters.fixtures.section_height,
                      isChecked,
                    )
                  }
                />

                <Grid columns="1fr 1fr" gap="10px">
                  {availableFilters.fixtures.section_height
                    .sort(numberSorterReversed)
                    .map((fixture, i) => (
                      <Checkbox
                        key={i}
                        label={`section_height_${fixture}`}
                        isCheckboxLeft
                        isChecked={selectedFilters.fixtureHeights.includes(fixture)}
                        setIsChecked={(isChecked) =>
                          utilSelectFilter("fixtureHeights", fixture, isChecked)
                        }
                      />
                    ))}
                </Grid>

                <HorizontalDivider />

                <Checkbox
                  label="FIXTURE WIDTHS"
                  isHeader
                  isCheckboxLeft
                  isChecked={utilAreAllSelected(
                    selectedFilters.fixtureWidths,
                    availableFilters.fixtures.section_width,
                  )}
                  setIsChecked={(isChecked) =>
                    utilSelectAllFilters(
                      "fixtureWidths",
                      availableFilters.fixtures.section_width,
                      isChecked,
                    )
                  }
                />

                <Grid columns="1fr 1fr" gap="10px">
                  {availableFilters.fixtures.section_width
                    .sort(numberSorterReversed)
                    .map((fixture, i) => (
                      <Checkbox
                        key={i}
                        label={`section_width_${fixture}`}
                        isCheckboxLeft
                        isChecked={selectedFilters.fixtureWidths.includes(fixture)}
                        setIsChecked={(isChecked) =>
                          utilSelectFilter("fixtureWidths", fixture, isChecked)
                        }
                      />
                    ))}
                </Grid>
              </Flex>

              <VerticalDivider />

              <Flex column gap="10px">
                <Checkbox
                  label="CLUSTERS"
                  isHeader
                  isChecked={utilAreAllSelected(
                    selectedFilters.clusters,
                    availableFilters.clusters,
                  )}
                  isCheckboxLeft
                  setIsChecked={(isChecked) =>
                    utilSelectAllFilters("clusters", availableFilters.clusters, isChecked)
                  }
                />

                <Grid columns="1fr" gap="10px">
                  {availableFilters.clusters.sort().map((cluster, i) => (
                    <Checkbox
                      key={i}
                      label={cluster}
                      isCheckboxLeft
                      isChecked={selectedFilters.clusters.includes(cluster)}
                      setIsChecked={(isChecked) => utilSelectFilter("clusters", cluster, isChecked)}
                    />
                  ))}
                </Grid>
              </Flex>
            </Flex>
          </Scroller>

          <Flex flexGrow={1} />

          <Flex width="100%" gap="10px" justify="between" align="center">
            <Text variant="small1" color={Color.primary}>
              Our algorithm will automatically reduce the selections to the most common aggregates.
              Additional filtering is optional.
            </Text>

            <Flex gap="10px" justify="right">
              <Button variant="inverted" onClick={previousStep}>
                Back
              </Button>

              {appliedFilters && <Button onClick={nextStep}>Next</Button>}

              {!appliedFilters && (
                <Button
                  isLoading={isApplyFiltersLoading}
                  isDisabled={filteredStores.length === 0}
                  onClick={applyFilters}
                >
                  Next ({filteredStores.length}
                  {filteredStores.length === 1 ? " store" : " stores"})
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
