import React from "react";

interface Props {
  times?: number;
  children?: React.ReactNode;
}

export const Repeater = ({ times = 1, children }: Props) => (
  <React.Fragment>
    {Array.from({ length: times }, (_, i) => (
      <React.Fragment key={i}>{children}</React.Fragment>
    ))}
  </React.Fragment>
);
