import { Color } from "src/elements";
import { lightenDarkenColor } from "src/utils";

export const themes: {
  [theme: string]: {
    default: string;
    borderColor: string;
    hover: string;
    active: string;
    disabled: string;
    text: string;
    textHover: string;
    textActive: string;
    textDisabled: string;
    textInverted?: string;
  };
} = {
  primary: {
    default: Color.primary,
    borderColor: Color.primary,
    text: Color.white,
    textHover: Color.white,
    hover: Color.primaryHover,
    active: Color.primaryActive,
    textActive: Color.white,
    disabled: Color.primaryDisabled,
    textDisabled: Color.white,
  },

  greenSmoke: {
    default: Color.greenSmoke,
    borderColor: Color.greenSmoke,
    hover: Color.greenSmokeHover,
    active: Color.greenSmokeActive,
    disabled: Color.greenSmokeDisabled,

    text: Color.primary,
    textHover: Color.primaryHover,
    textActive: Color.primaryActive,
    textDisabled: Color.primaryDisabled,
    textInverted: Color.primary,
  },

  yellow: {
    default: Color.yellow,
    borderColor: Color.yellow,
    text: Color.white,
    textHover: Color.white,
    hover: Color.yellowHover,
    active: Color.yellowActive,
    textActive: Color.white,
    disabled: Color.yellowDisabled,
    textDisabled: Color.white,
  },

  red: {
    default: Color.red,
    borderColor: Color.red,
    text: Color.white,
    textHover: Color.white,
    hover: Color.redHover,
    active: Color.redActive,
    textActive: Color.white,
    disabled: Color.redDisabled,
    textDisabled: Color.white,
  },

  success: {
    default: Color.success,
    borderColor: Color.success,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    hover: lightenDarkenColor(Color.success, -10),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    active: lightenDarkenColor(Color.success, -20),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    disabled: lightenDarkenColor(Color.success, 60),

    text: Color.white,
    textHover: Color.white,
    textActive: Color.white,
    textDisabled: Color.white,
  },
};
