import hexToRgba from "hex-to-rgba";
import { Color, Icon, Text } from "src/elements";
import styled from "styled-components";

const OverflowingContainer = styled.div<Props>`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: ${({ shelfThickness, scaleY }) => `calc(${shelfThickness}px * ${scaleY})`};
  left: 0;
  width: 100%;
  height: ${({ shelfHeight, shelfThickness, scaleY }) =>
    `calc(${shelfHeight - shelfThickness}px * ${scaleY})`};
  border: 1px dashed ${Color.red};
  border-radius: 3px;
  background-color: ${hexToRgba(Color.red, 0.15)};
  z-index: 1;
`;

const OverflowingLabel = styled.div`
  margin: -1px;
  display: flex;
  align-items: center;
  width: min-content;
  padding: 5px;
  gap: 5px;
  background-color: ${Color.red};
  border-radius: 3px;
`;

interface Props {
  shelfHeight: number;
  shelfThickness: number;
  scaleY: number;
}

export const Overflowing = ({ shelfHeight, shelfThickness, scaleY }: Props) => (
  <OverflowingContainer shelfHeight={shelfHeight} shelfThickness={shelfThickness} scaleY={scaleY}>
    <OverflowingLabel>
      <Icon name="alert" color={Color.white} size={14} />

      <Text variant="small3" color={Color.white}>
        Overflowing
      </Text>
    </OverflowingLabel>
  </OverflowingContainer>
);
