import React from "react";
import { Color, Text } from "src/elements";
import styled from "styled-components";
import { textVariants } from "../Text/store/variants";

const TextareaContainer = styled.div<{ height?: string }>`
  position: relative;
  width: 100%;
  height: ${({ height }) => height};
`;

const TextareaTitle = styled.div`
  position: absolute;
  top: -11px;
  left: 8px;
  background-color: ${Color.white};
  padding: 0 3px;
`;

const TextareaElement = styled.textarea<{ height?: string }>`
  width: 100%;
  height: ${({ height }) => height};
  border: 1px solid ${Color.primary};
  border-radius: 4px;
  padding: 10px;
  font-size: ${textVariants.body3.fontSize};
  line-height: ${textVariants.body3.lineHeight};
  font-weight: ${textVariants.body3.fontWeight};
  resize: none;
  outline: none;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${Color.white};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Color.primary};
    border-radius: 10px;
  }
`;

interface Props {
  title: string;
  value: string;
  setValue: (value: string) => void;
  height?: string;
}

export const Textarea: React.FC<Props> = ({ title, value, height, setValue }) => (
  <TextareaContainer height={height}>
    <TextareaTitle>
      <Text variant="small1" color={Color.primary}>
        {title}
      </Text>
    </TextareaTitle>

    <TextareaElement
      height={height}
      value={value}
      onChange={(value) => {
        setValue(value.currentTarget.value);
      }}
    />
  </TextareaContainer>
);
