import { useModals } from "src/components/Modals";
import { Button, Color, Flex, Icon, Modal, Text } from "src/elements";

import { ConfirmModalProps } from "./store/types";
import { useAtom } from "jotai";
import { confirmModalDataAtom } from "./store/atoms";
import { useEffect } from "react";

export const ModalConfirm = () => {
  const [data, setData] = useAtom(confirmModalDataAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  if (!data) {
    return null;
  }

  return <ModalConfirmInner {...data} />;
};

const ModalConfirmInner = ({ title, description, icon, buttons }: ConfirmModalProps) => {
  const { closeModal: _closeModal } = useModals();

  const closeModal = () => {
    _closeModal("ModalConfirm");
  };

  return (
    <Modal name="ModalConfirm" width="400px" isConfirmModal>
      <Flex column padding="20px 10px 10px 10px" gap="30px" justify="center">
        <Flex column gap="10px" justify="center">
          {icon && <Icon name={icon.name} color={icon.color} size={40} />}

          <Text variant="h5" textAlign="center">
            {title}
          </Text>

          {description && typeof description === "string" && (
            <Text textAlign="center" variant="small2" color={Color.textSecondary}>
              {description}
            </Text>
          )}

          {description && typeof description === "object" && description}
        </Flex>

        <Flex width="100%" justify="space-between" gap="10px">
          {buttons.map((button, i) => (
            <Button
              key={i}
              width="100%"
              color={button.color}
              variant={button.variant}
              onClick={() => {
                if (button.onClick) {
                  button.onClick();
                }
                closeModal();
              }}
            >
              {button.label}
            </Button>
          ))}
        </Flex>
      </Flex>
    </Modal>
  );
};
