import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { createTemplateModalAtom } from "./atom";

export const useCreateTemplateModal = () => {
  const { openModal } = useModals();
  const setData = useSetAtom(createTemplateModalAtom);

  const openCreateTemplateModal = ({
    storeCode,
    storePogId,
  }: {
    storeCode: string;
    storePogId: number;
  }) => {
    setData({ storeCode, storePogId });
    openModal("CreateTemplateModal");
  };

  return {
    openCreateTemplateModal,
  };
};
