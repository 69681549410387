import {
  ResponseGetMerchflow_merchflowId_StorePogTriggerAllFinished,
  RouteGetMerchflow_merchflowId_StorePogTriggerAllFinished,
} from "@CommonApi/merchflow/_merchflowId_/store-pog-trigger/all-finished";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useApi } from "src/api";
import { useInlineLoaders } from "src/components/InlineLoader";
import { Select } from "src/elements";
import { useAsyncState } from "src/hooks/useAsyncState";
import { pages } from "src/utils";
import { routeToUrl } from "src/utils/axios";
import { useNavigation } from "src/utils/navigation";

interface Props {
  merchflowId: number;
}

export const SelectStorePogTrigger = ({ merchflowId }: Props) => {
  const { getMerchflowStorePogTriggerAllFinishedApi } = useApi();
  const { isInlineLoading } = useInlineLoaders();
  const { navigate } = useNavigation();
  const [searchParams] = useSearchParams();

  const [versions, setVersions] =
    useAsyncState<ResponseGetMerchflow_merchflowId_StorePogTriggerAllFinished>(undefined);
  const [storePogTriggerId, setStorePogTriggerIdInner] = useState<number | null>(
    searchParams.get("store_pog_trigger_id")
      ? Number(searchParams.get("store_pog_trigger_id"))
      : null,
  );

  const isLoading =
    isInlineLoading(RouteGetMerchflow_merchflowId_StorePogTriggerAllFinished) ||
    versions === undefined;

  useEffect(() => {
    setVersions(getMerchflowStorePogTriggerAllFinishedApi(merchflowId));
  }, []);

  const setStorePogTriggerId = (storePogTriggerId: number | null) => {
    setStorePogTriggerIdInner(storePogTriggerId);

    if (storePogTriggerId === null) {
      navigate(
        routeToUrl(pages.merchflows_merchflowId_StoresAll, {
          merchflowId,
        }),
      );
    } else {
      navigate(
        routeToUrl(
          pages.merchflows_merchflowId_StoresAll,
          {
            merchflowId,
          },
          { store_pog_trigger_id: storePogTriggerId },
        ),
      );
    }
  };

  return (
    <Select
      width="150px"
      title="Filter store POGs by"
      placeholder="Version"
      options={
        versions
          ? [
              { label: "Latest", value: null },
              ...versions?.map((version) => ({
                label: String(version.store_pog_trigger_id),
                value: version.store_pog_trigger_id,
              })),
            ]
          : []
      }
      value={storePogTriggerId}
      setValue={setStorePogTriggerId}
      isLoading={isLoading}
    />
  );
};
