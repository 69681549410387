import { lightenDarkenColor } from "src/utils";
import styled from "styled-components";

import { ToastDuration, ToastType } from "../store/types";
import { Color } from "src/elements";

const TimerBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${Color.opacitySkyHover};
  border-top: 1px solid ${lightenDarkenColor(Color.opacitySkyHover, -5)};
  box-shadow: inset 0 0 2px ${lightenDarkenColor(Color.opacitySkyHover, -10)};
`;

const TimerBarElement = styled.div<{
  type: ToastType;
  duration: ToastDuration;
}>`
  width: 100%;
  height: 100%;
  background-color: ${({ type }) =>
    (type === "success" && Color.success) ||
    (type === "error" && Color.red) ||
    (type === "warning" && Color.yellow)};
  border-radius: 0 4px 4px 0;

  animation: ${({ duration }) =>
    typeof duration === "number" && `timer ${duration}ms linear forwards`};

  @keyframes timer {
    to {
      transform: translateX(-100%);
    }
  }
`;

interface Props {
  type: ToastType;
  duration: ToastDuration;
}

export const TimerBar = ({ type, duration }: Props) => (
  <TimerBarContainer>
    <TimerBarElement type={type} duration={duration} />
  </TimerBarContainer>
);
