import { DevTools as JotaiDevTools } from "jotai-devtools";
import "jotai-devtools/styles.css";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { env } from "./utils/env";

const app = document.getElementById("root");

if (app) {
  const root = ReactDOM.createRoot(app);

  root.render(
    <>
      <BrowserRouter>
        <App />
      </BrowserRouter>

      {env.VITE_ENV === "local" && <JotaiDevTools />}
    </>,
  );
}
