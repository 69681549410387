import { ReactNode, useState } from "react";
import { Button, Flex, Text } from "src/elements";
import styled from "styled-components";

const AccordionContainer = styled.div`
  border-radius: 5px;
  box-shadow: 0 10px 25px rgba(36, 36, 36, 0.2);
`;

interface Props {
  readonly header: string;
  readonly children: ReactNode;
}

const Accordion = ({ header, children }: Props) => {
  const [isActive, setIsActive] = useState(true);

  return (
    <AccordionContainer data-name={`accordion-${header}`}>
      <Flex justify="between" padding="12px 10px 10px 10px">
        <Text variant="body1">{header}</Text>

        <Button
          variant="borderless"
          iconRight={{ name: isActive ? "triangleUp" : "triangleDown" }}
          onClick={() => setIsActive(!isActive)}
        />
      </Flex>

      {isActive && (
        <Flex column gap="10px">
          {children}
        </Flex>
      )}
    </AccordionContainer>
  );
};

export default Accordion;
