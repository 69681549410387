import { Filter } from "src/pages/merchandise/store/types";
import { omniAtom } from "src/utils/atoms";

const prefix = "modals/PowerBiReport";

export const reportFiltersAtom = omniAtom({
  prefix,
  key: "reportFilters",
  value: [] as Filter[],
});

export const reportIdAtom = omniAtom({
  prefix,
  key: "reportId",
  value: "",
});
