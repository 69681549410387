export const RouteGetUsersList = "/users/list";

export type ResponseGetUsersList = User[];

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  userType: "MERCHANT_USER";
  merchantId: number;
};
