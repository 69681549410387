import { PlanogramShelf } from "@CommonTypes/merchflow/pog/shelf";
import { useAtom } from "jotai";
import { getShelfXLeft, getShelfXRight, getShelfYBottom } from "src/omni-common/utils/pog/shelf";
import { uuid } from "src/utils";

import { PogAtom } from "../store/types";

export const usePlanogramShelfActions = (pogAtom: PogAtom) => {
  const [pog, setPog] = useAtom(pogAtom);

  const moveShelf = (shelf: PlanogramShelf, notchNo: number) => {
    setPog((pog) => {
      let shelfToLeft: PlanogramShelf | null = shelf;
      while (shelfToLeft) {
        shelfToLeft.notchNo = Math.round(notchNo);
        shelfToLeft = shelfToLeft.mergedLeft;
      }

      let shelfToRight: PlanogramShelf | null = shelf.mergedRight;
      while (shelfToRight) {
        shelfToRight.notchNo = Math.round(notchNo);
        shelfToRight = shelfToRight.mergedRight;
      }

      return { ...pog };
    });
  };

  const deleteShelf = (shelf: PlanogramShelf) => {
    setPog((pog) => {
      // Move shelf items to unranged.
      for (const item of shelf.items) {
        item.shelf = null;
        pog.unrangedItems.unshift(item);
      }

      // Remove shelf from planogram.
      shelf.bay.shelves = shelf.bay.shelves.filter((s) => s.uniqueId !== shelf.uniqueId);

      // Fix merged shelves connections
      if (shelf.mergedLeft) {
        shelf.mergedLeft.mergedRight = shelf.mergedRight;
      }

      if (shelf.mergedRight) {
        shelf.mergedRight.mergedLeft = shelf.mergedLeft;
      }

      return { ...pog };
    });
  };

  const duplicateShelf = (shelf: PlanogramShelf) => {
    setPog((pog) => {
      let shelfToDuplicate: PlanogramShelf | null = getFirstShelf(shelf);
      const duplicatedShelves: PlanogramShelf[] = [];

      // Build new shelf objects.
      while (shelfToDuplicate !== null) {
        const duplicatedShelf: PlanogramShelf = {
          ...shelfToDuplicate,
          uniqueId: getNewOwaShelfId(),
          notchNo: shelfToDuplicate.notchNo + 1,
          items: [],
        };

        // Add duplicated shelf after the used shelf.
        shelfToDuplicate.bay.shelves.push(duplicatedShelf);

        // Add new duplicated shelf into list of shelves to correctly merge them later.
        duplicatedShelves.push(duplicatedShelf);

        // Update reference.
        shelfToDuplicate = shelfToDuplicate.mergedRight;
      }

      // Merge all new duplicated shelves.
      for (let i = 0; i < duplicatedShelves.length; i++) {
        const shelf = duplicatedShelves[i];
        const rightShelf = duplicatedShelves[i + 1];

        if (rightShelf) {
          shelf.mergedRight = rightShelf;
          rightShelf.mergedLeft = shelf;
        }

        // Fix shelves order.
        shelf.bay.shelves = shelf.bay.shelves.sort((shelf1, shelf2) =>
          shelf1.notchNo < shelf2.notchNo ? -1 : 1,
        );
      }

      return { ...pog };
    });
  };

  const mergeLeft = (shelf: PlanogramShelf) => {
    const leftShelf = canBeMergedLeft(shelf);
    if (!leftShelf) return;

    setPog((pog) => {
      shelf.mergedLeft = leftShelf;
      leftShelf.mergedRight = shelf;

      return { ...pog };
    });
  };

  const unmergeLeft = (shelf: PlanogramShelf) => {
    setPog((pog) => {
      if (shelf.mergedLeft) shelf.mergedLeft.mergedRight = null;
      shelf.mergedLeft = null;

      return { ...pog };
    });
  };

  const mergeRight = (shelf: PlanogramShelf) => {
    const rightShelf = canBeMergedRight(shelf);
    if (!rightShelf) return;

    setPog((pog) => {
      shelf.mergedRight = rightShelf;
      rightShelf.mergedLeft = shelf;

      return { ...pog };
    });
  };

  const unmergeRight = (shelf: PlanogramShelf) => {
    setPog((pog) => {
      if (shelf.mergedRight) shelf.mergedRight.mergedLeft = null;
      shelf.mergedRight = null;

      return { ...pog };
    });
  };

  const canBeMergedLeft = (shelf: PlanogramShelf) => {
    if (shelf.mergedLeft) return null;

    for (const _bay of pog.planogram.bays) {
      for (const _shelf of _bay.shelves) {
        if (
          getShelfXRight(_shelf) === getShelfXLeft(shelf) &&
          getShelfYBottom(_shelf) === getShelfYBottom(shelf) &&
          _shelf.thickness === shelf.thickness
        ) {
          return _shelf;
        }
      }
    }

    return null;
  };

  const canBeMergedRight = (shelf: PlanogramShelf) => {
    if (shelf.mergedRight) return null;

    for (const _bay of pog.planogram.bays) {
      for (const _shelf of _bay.shelves) {
        if (
          getShelfXLeft(_shelf) === getShelfXRight(shelf) &&
          getShelfYBottom(_shelf) === getShelfYBottom(shelf) &&
          _shelf.thickness === shelf.thickness
        ) {
          return _shelf;
        }
      }
    }

    return null;
  };

  const canBeMovedDown = (shelf: PlanogramShelf) => {
    const index = shelf.bay.shelves.findIndex((s) => s.uniqueId === shelf.uniqueId);
    const bottomShelf: PlanogramShelf | undefined = shelf.bay.shelves[index - 1];

    if (bottomShelf) {
      return shelf.notchNo > bottomShelf.notchNo + 1;
    }

    return shelf.notchNo > 1;
  };

  const canBeMovedUp = (shelf: PlanogramShelf) => {
    const index = shelf.bay.shelves.findIndex((s) => s.uniqueId === shelf.uniqueId);
    const topShelf: PlanogramShelf | undefined = shelf.bay.shelves[index + 1];

    if (topShelf) {
      return topShelf.notchNo > shelf.notchNo + 1;
    }

    return shelf.notchNo < shelf.bay.maxNotch;
  };

  const getShelf = (shelfId: string) => {
    for (const bay of pog.planogram.bays) {
      for (const shelf of bay.shelves) {
        if (shelf.uniqueId === shelfId) {
          return shelf;
        }
      }
    }

    return null as unknown as PlanogramShelf;
  };

  const setShelfDepth = (shelf: PlanogramShelf, depth: number) => {
    setPog((pog) => {
      shelf.depth = depth;
      return { ...pog };
    });
  };

  const setShelfThickness = (shelf: PlanogramShelf, thickness: number) => {
    setPog((pog) => {
      shelf.thickness = thickness;
      return { ...pog };
    });
  };

  const setShelfNotchNo = (shelf: PlanogramShelf, notchNo: number) => {
    setPog((pog) => {
      shelf.notchNo = notchNo;
      return { ...pog };
    });
  };

  const moveShelfToBayNo = (shelf: PlanogramShelf, bayNo: number) => {
    setPog((pog) => {
      const index = shelf.bay.shelves.findIndex((s) => s.uniqueId === shelf.uniqueId);
      shelf.bay.shelves.splice(index, 1);

      // Add shelf to correct bay.
      const bay = pog.planogram.bays[bayNo - 1];
      bay.shelves.push(shelf);
      bay.shelves = bay.shelves.sort((shelf1, shelf2) =>
        shelf1.notchNo < shelf2.notchNo ? -1 : 1,
      );
      shelf.bay = bay;

      return { ...pog };
    });
  };

  const getShelfBayNo = (shelfToGet: PlanogramShelf) => {
    for (let bayNo = 1; bayNo <= pog.planogram.bays.length; bayNo++) {
      for (const shelf of pog.planogram.bays[bayNo - 1].shelves) {
        if (shelf.uniqueId === shelfToGet.uniqueId) {
          return bayNo;
        }
      }
    }

    return 1;
  };

  const getFirstShelf = (shelf: PlanogramShelf): PlanogramShelf => {
    if (shelf.mergedLeft) {
      return getFirstShelf(shelf.mergedLeft);
    }

    return shelf;
  };

  const getLastShelf = (shelf: PlanogramShelf): PlanogramShelf => {
    if (shelf.mergedRight) {
      return getLastShelf(shelf.mergedRight);
    }

    return shelf;
  };

  return {
    getShelfBayNo,
    getFirstShelf,
    getLastShelf,
    setShelfDepth,
    setShelfThickness,
    setShelfNotchNo,
    moveShelfToBayNo,
    getShelf,
    moveShelf,
    deleteShelf,
    duplicateShelf,
    mergeLeft,
    mergeRight,
    unmergeLeft,
    unmergeRight,
    canBeMergedLeft,
    canBeMergedRight,
    canBeMovedDown,
    canBeMovedUp,
  };
};

export const getNewOwaShelfId = () => `owa-${uuid()}`;
