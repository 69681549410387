import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMerchflowTemplateApi } from "src/api/merchflows/templates";
import { useInlineLoaders } from "src/components/InlineLoader";
import { Color, Flex, Modal, Pagination, Repeater, Skeleton, SubgridTable } from "src/elements";
import { SubgridColumn } from "src/elements/SubgridTable/store/types";
import styled from "styled-components";
import { assignedStoresAtom } from "./store";
import { TemplateAssignStoreStatus } from "@CommonApi/merchflow/template/all-stores/_templateId_";

const Dot = styled.div<{ color?: Color }>`
  width: 8px;
  height: 8px;
  background: ${({ color }) => color ?? Color.primary};
  border-radius: 50%;
`;

const ApprovedStatus = ({ color }: { color?: Color }) => (
  <Flex justify="center">
    <Dot color={color || Color.primary} />
  </Flex>
);

const STORE_PER_REQUEST = 12;
const MAX_STORE_NAME_LENGTH = 15;

const formatName = (name: string) => {
  if (name && name.length > 15) {
    return `${name.slice(0, MAX_STORE_NAME_LENGTH)}...`;
  }
  return name;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const templateAssignedColumns: SubgridColumn<any>[] = [
  {
    id: "storeName",
    header: "STORE NAME",
    justify: "center",
  },
  {
    id: "storeCode",
    header: "STORE CODE",
    justify: "center",
  },
  {
    id: "template",
    header: "TEMPLATE",
    subColumns: [
      { id: "templateId", header: "ID", justify: "center" },
      {
        id: "templateAssigned",
        header: "ASSIGNED",
        justify: "center",
      },
    ],
  },
  {
    id: "merchflow",
    header: "MERCHFLOW",
    subColumns: [
      {
        id: "filter_config_id",
        header: "ID",
        justify: "center",
      },
      {
        id: "merchflowAssigned",
        header: "ASSIGNED",
        justify: "center",
      },
    ],
  },
  {
    id: "planogram",
    header: "PLANOGRAM",
    subColumns: [
      {
        id: "planogramId",
        header: "ID",
        justify: "center",
      },
      {
        id: "planogramApproved",
        header: "APPROVED",
        justify: "center",
        align: "center",
      },
    ],
  },
  { id: "size", header: "SIZE", justify: "center" },
  { id: "region", header: "REGION", justify: "center" },
  { id: "fixture", header: "FIXTURE", justify: "center" },
  { id: "cluster", header: "CLUSTER", justify: "center" },
  { id: "comment", header: "COMMENTS", justify: "center" },
];

export const AssignedStoreTemplate = () => {
  const [data, setData] = useAtom(assignedStoresAtom);

  const { getStoreAssignedApi } = useMerchflowTemplateApi();
  const { isInlineLoading } = useInlineLoaders();
  const { templateId: _templateId } = useParams<{ templateId: string }>();
  const [page, setPage] = useState(1);
  const isDataLoading = isInlineLoading("merchflow/template/all-stores/:templateId");
  const templateId = _templateId!;

  useEffect(() => {
    getStoreAssignedApi(setData, templateId, {
      page,
      size: STORE_PER_REQUEST,
    });
    return () => {
      setData(null);
    };
  }, [page]);

  return (
    <Modal name="AssignedStoreTemplate" title="Assigned Stores" width="1366px" height="550px">
      <Flex padding="8px" direction="column">
        {!isDataLoading && (
          <SubgridTable
            columns={templateAssignedColumns}
            data={(data?.data || []).map((store) => ({
              ...store,
              storeName: formatName(store.store_name),
              storeCode: store.store_id,
              templateId: store.template_id,
              templateAssigned: <ApprovedStatus />,
              merchflowAssigned: <ApprovedStatus />,
              filter_config_id: store.filter_config_id,
              planogramId: store.store_pog_id,
              planogramApproved:
                store.approve_status_code === TemplateAssignStoreStatus.SINGLE ? (
                  <ApprovedStatus />
                ) : store.approve_status_code === TemplateAssignStoreStatus.MULTIPLE ? (
                  <ApprovedStatus color={Color.red} />
                ) : null,
            }))}
          />
        )}

        {isDataLoading && (
          <Flex direction="column" gap="10px">
            <Repeater times={STORE_PER_REQUEST}>
              <Skeleton height="20px" />
            </Repeater>
          </Flex>
        )}
        <Flex>
          <Pagination
            isUsingInput
            page={page}
            totalPages={data?.totalPages || 1}
            setPage={setPage}
            isLoading={isDataLoading}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};
