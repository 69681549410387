import styled from "styled-components";

import { IconProps } from "../store/types";
import { Color, Icon } from "src/elements";

const IconContainer = styled.div<{
  side: "left" | "right";
  onClick?: () => void;
}>`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 33px;
  user-select: none;
  left: ${({ side }) => side === "left" && "0"};
  right: ${({ side }) => side === "right" && "0"};
  cursor: ${({ onClick }) => onClick && "pointer"};
  pointer-events: ${({ onClick }) => !onClick && "none"};
`;

export const InputIcon = ({
  side,
  icon,
  error,
}: {
  side: "left" | "right";
  icon?: IconProps | false;
  error?: boolean;
}) => {
  if (!icon) return null;

  return (
    <IconContainer side={side} onClick={icon.onClick}>
      <Icon name={icon.name} size={14} color={(error && Color.red) || Color.primary} />
    </IconContainer>
  );
};
