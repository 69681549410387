import { Modal } from "src/elements";

export const StoresModal = () => {
  // const {
  //   modals: {
  //     stores: { allStores, bindedStores, isLoading },
  //   },
  // } = useTypedSelector((state) => state);
  // const [category] = useAtom(categoryAtom);

  // const {
  //   clearStore,
  //   putBindApi,
  //   getStoresApi,
  //   setBindedStores,
  //   toggleBindedStore,
  // } = useActions();

  // useEffect(() => {
  //   if (!category) return;
  //   getStoresApi({ catId: category.id });

  //   return () => {
  //     clearStore();
  //   };
  // }, []);

  // if (!category) return null;

  // const handleSave = () => {
  //   putBindApi({ categoryId: category.id, stores: bindedStores });
  // };

  // const handleSelectAll = () => {
  //   if (bindedStores.length !== allStores.length) {
  //     setBindedStores(allStores.map((item) => item.id));
  //   } else {
  //     setBindedStores([]);
  //   }
  // };

  return (
    <Modal
      name="StoresModal"
      // title={`Stores for category: ${category.code}`}
      width="1100px"
    >
      {/* {
      <Scroller>
        <Flex padding="12px">
          {isLoading && <Spinner isFullscreen />}

          {!isEmpty(allStores) && (
            <Flex flexWrap="wrap" gap="8px">
              {allStores.map((store) => (
                <Flex width="20%" margin="5px 0" key={store.id}>
                  <Checkbox
                    label={store.code}
                    isCheckboxLeft
                    isChecked={Boolean(
                      bindedStores.find(
                        (bindedStore) => bindedStore === store.id,
                      ),
                    )}
                    setIsChecked={() => toggleBindedStore(store.id)}
                  />
                </Flex>
              ))}
            </Flex>
          )}

          <Flex margin="10px 0 0 0" gap="20px">
            <Button variant="inverted" onClick={handleSelectAll} width="100px">
              {bindedStores.length !== allStores.length
                ? "Select All"
                : "Deselect All"}
            </Button>

            <Button onClick={handleSave}>Save</Button>
          </Flex>
        </Flex>
      </Scroller>} */}
    </Modal>
  );
};
