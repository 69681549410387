import { useEffect, useMemo, useState } from "react";
import { Page, PageStatus } from "src/components";
import { Button, Color, Flex, Grid, Search, Text, Toggle } from "src/elements";
import { Category } from "@CommonApi/categories/list";

import { useImportPsaModal } from "src/modals/ImportPsa/store/hooks";
import Accordion from "./components/CategoryGroup";
import { useImportCsvModal } from "src/modals/ImportCsv/store/hooks";
import { useCategoriesPage } from "./store/hooks";
import { useAddCategoryModal } from "src/modals/AddCategory/store/hooks";
import { SubCategory } from "./components/SubCategory";
import { useImportStoreSummaryModal } from "src/modals/ImportStoreSummary/store/hooks";
import { useApi } from "src/api";
import { useInlineLoaders } from "src/components/InlineLoader";
import { RoutePostImportEtlRun } from "@CommonApi/import-etl/run";

export const PageCategories = () => {
  const { categories, isLoading, loadCategories } = useCategoriesPage();
  const { openImportPsaModal } = useImportPsaModal();
  const { openImportCsvModal } = useImportCsvModal();
  const { openImportStoreSummaryModal } = useImportStoreSummaryModal();
  const { openAddCategoryModal } = useAddCategoryModal();
  const { postImportEtlRunApi } = useApi();
  const { isInlineLoading } = useInlineLoaders();

  const [includeArchived, setIncludeArchived] = useState(false);
  const [search, setSearch] = useState("");

  const filteredCategories = useMemo(() => {
    const searchLowercase = search.toLowerCase();

    return search && categories
      ? categories.filter(
          (category) =>
            category.catGroup.toLowerCase().includes(searchLowercase) ||
            category.categories.find((subCategory) =>
              subCategory.code.toLowerCase().includes(searchLowercase),
            ),
        )
      : categories;
  }, [categories, search]);

  useEffect(() => {
    loadCategories();
  }, []);

  const editGuidelines = () => {
    // setCategory(category);
    // toggleModal("GuidelinesModal");
  };

  const bindStores = () => {
    // setCategory(category);
    // openModal("StoresModal");
  };

  const editCategory = (category: Category) => {
    if (categories) {
      openAddCategoryModal({ categories, category });
    }
  };

  const newCategory = () => {
    if (categories) {
      openAddCategoryModal({ categories });
    }
  };

  const handleImportPsa = (category: Category) => {
    openImportPsaModal(category);
  };

  const handleImportCsv = (category: Category) => {
    openImportCsvModal(category);
  };

  const handleImportStoreSummary = (category: Category) => {
    openImportStoreSummaryModal(category);
  };

  return (
    <Page>
      <Flex justify="between" align="end" margin="0 0 10px 0">
        <Flex column gap="10px">
          <Text variant="h3">Categories</Text>

          <Flex align="center" gap="20px">
            <Search
              width="200px"
              search={search}
              setSearch={setSearch}
              placeholder="Search by category"
            />

            <Flex>
              <Toggle
                label="View archived categories"
                isChecked={includeArchived}
                setIsChecked={setIncludeArchived}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex gap="10px">
          <Button
            color="greenSmoke"
            variant="inverted"
            isDisabled={!categories}
            isLoading={isInlineLoading(RoutePostImportEtlRun)}
            onClick={postImportEtlRunApi}
          >
            Run ETL for all categories
          </Button>

          <Button isDisabled={!categories} onClick={newCategory}>
            Add category
          </Button>
        </Flex>
      </Flex>

      {isLoading && <PageStatus icon="spinner" label="Loading Categories..." />}

      {!isLoading && filteredCategories && filteredCategories.length === 0 && (
        <PageStatus icon="info" color={Color.spaceGray} label="Categories not found" />
      )}

      {!isLoading && filteredCategories && filteredCategories.length > 0 && (
        <Grid columns="1fr 1fr" gap="20px">
          <Flex column gap="20px">
            {filteredCategories
              .slice(0, Math.ceil(filteredCategories.length / 2))
              .map((category, index) => (
                <Accordion key={index} header={category.catGroup}>
                  {category.categories
                    .filter(
                      (subCategory) =>
                        subCategory.status === "ACTIVE" ||
                        (subCategory.status === "ARCHIVED" && includeArchived),
                    )
                    .map((subCategory, index) => (
                      <SubCategory
                        key={index}
                        onEdit={editCategory}
                        category={subCategory}
                        onGuidelines={editGuidelines}
                        loading={isLoading}
                        onBindStores={bindStores}
                        onImportPsa={handleImportPsa}
                        onImportCsv={handleImportCsv}
                        onImportStoreSummary={handleImportStoreSummary}
                      />
                    ))}
                </Accordion>
              ))}
          </Flex>

          <Flex column gap="20px">
            {filteredCategories
              .slice(Math.ceil(filteredCategories.length / 2))
              .map((category, index) => (
                <Accordion key={index} header={category.catGroup}>
                  {category.categories
                    .filter(
                      (subCategory) =>
                        subCategory.status === "ACTIVE" ||
                        (subCategory.status === "ARCHIVED" && includeArchived),
                    )
                    .map((subCategory, index) => (
                      <SubCategory
                        key={index}
                        onEdit={editCategory}
                        category={subCategory}
                        onGuidelines={editGuidelines}
                        loading={isLoading}
                        onBindStores={bindStores}
                        onImportPsa={handleImportPsa}
                        onImportCsv={handleImportCsv}
                        onImportStoreSummary={handleImportStoreSummary}
                      />
                    ))}
                </Accordion>
              ))}
          </Flex>
        </Grid>
      )}
    </Page>
  );
};
