import { omniAtom } from "src/utils/atoms";

const prefix = "modals/storePlanogramRules";

export const storePlanogramRulesDataAtom = omniAtom({
  prefix,
  key: "data",
  value: null as {
    merchflowId: number;
    basePogId: number;
    isBasePogFavorited: boolean;
  } | null,
});
