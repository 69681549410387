import { Color, Flex, Icon } from "src/elements";

interface Props {
  isVisible: boolean;
  sortedDirection?: "ascending" | "descending";
}

export const SubgridSorter = ({ isVisible, sortedDirection }: Props) => (
  <Flex minWidth="14px" minHeight="14px" opacity={isVisible ? 1 : 0}>
    <Icon
      name={
        (sortedDirection === "ascending" && "triangleUp") ||
        (sortedDirection === "descending" && "triangleDown") ||
        "triangleSort"
      }
      color={Color.white}
    />
  </Flex>
);
