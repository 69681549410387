import { PlanogramShelf } from "@CommonTypes/merchflow/pog/shelf";
import {
  getMergedShelfUnusedWidth,
  getMergedShelfWidth,
  getShelfXLeft,
  getShelfYBottom,
} from "@CommonUtils/pog/shelf";

import { Tags } from "src/elements";
import { formatDimension } from "src/utils/formatDimension";

interface Props {
  shelf: PlanogramShelf;
}

export const ShelfDetails = ({ shelf }: Props) => {
  const mergedWidth = getMergedShelfWidth(shelf);
  const unusedWidth = getMergedShelfUnusedWidth(shelf);

  return (
    <Tags
      tags={[
        { label: "Shelf ID", value: shelf.uniqueId },
        { label: "X", value: formatDimension(getShelfXLeft(shelf)) },
        { label: "Y", value: formatDimension(getShelfYBottom(shelf)) },
        { label: "Notch No", value: shelf.notchNo },
        { label: "Width", value: formatDimension(mergedWidth) },
        { label: "Height", value: formatDimension(shelf.thickness) },
        { label: "Depth", value: formatDimension(shelf.depth) },
        { label: "Available Space", value: formatDimension(unusedWidth) },
      ]}
    />
  );
};
