import { useModals } from "src/components/Modals";

export const useMerchflowReviewModal = () => {
  const { openModal } = useModals();

  const openMerchflowReviewModal = () => {
    openModal("ModalMerchflowReview");
  };

  return { openMerchflowReviewModal };
};
