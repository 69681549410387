import { Category } from "@CommonApi/categories/list";
import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { importCsvModalDataAtom } from "./atoms";

export const useImportCsvModal = () => {
  const { openModal } = useModals();
  const setImportCsvModalData = useSetAtom(importCsvModalDataAtom);

  const openImportCsvModal = (category: Category) => {
    setImportCsvModalData(category);
    openModal("ModalImportCsv");
  };

  return { openImportCsvModal };
};
