import { Page } from "src/components";
import { Flex, Grid, Text } from "src/elements";
import { Icon, icons } from "src/elements/Icon/Icon";

export const PageIcons = () => {
  return (
    <Page>
      <Grid columns="repeat(6, 1fr)" gap="10px">
        {icons.map((icon, index) => (
          <Flex key={index} gap="10px" align="center">
            <Icon name={icon} />

            <Text>{icon}</Text>
          </Flex>
        ))}
      </Grid>
    </Page>
  );
};
