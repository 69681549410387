import styled from "styled-components";
import { SubgridColumn, SubgridSortedBy } from "../store/types";
import { Color, Flex, Grid, Text } from "src/elements";
import { SubgridCell } from "./SubgridCell";
import { reduceSubgridTableColumnsToBodyColumns } from "../store/utils";
import { lightenDarkenColor } from "src/utils";
import { SubgridSorter } from "./SubgridSorter";
import React, { useRef } from "react";
import { useHoverClickable } from "src/utils/hoverClickable";

const Header = styled(Grid)<{ subheadersCount: number }>`
  grid-template-columns: subgrid;
  grid-column: ${({ subheadersCount }) => subheadersCount > 0 && `span ${subheadersCount}`};
  white-space: nowrap;
`;

const HeaderWrapper = <T,>({
  column,
  refHeader,
  subHeadersCount,
  hasSubHeaders,
  isLeftBorder,
  isTopBorder,
  onClick,
  children,
}: {
  column: SubgridColumn<T>;
  refHeader: React.RefObject<HTMLDivElement>;
  subHeadersCount: number;
  hasSubHeaders: boolean;
  isLeftBorder: boolean;
  isTopBorder: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}) =>
  column.headerWithoutStyle ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <SubgridCell
      ref={refHeader}
      span={subHeadersCount}
      background={Color.primaryActive}
      justify={(hasSubHeaders && "center") || column.justify}
      align="center"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      borderColor={lightenDarkenColor(Color.primaryActive, -15)}
      leftBorder={isLeftBorder}
      topBorder={isTopBorder}
      onClick={onClick}
    >
      {children}
    </SubgridCell>
  );

interface Props<T> {
  column: SubgridColumn<T>;
  isSortable: boolean;
  isLeftBorder: boolean;
  isTopBorder: boolean;
  sortedBy: SubgridSortedBy;
  setSortedBy: (sortedBy: SubgridSortedBy) => void;
}

export const SubgridHeader = <T,>({
  column,
  isSortable,
  isLeftBorder,
  isTopBorder,
  sortedBy,
  setSortedBy,
}: Props<T>) => {
  const refHeader = useRef<HTMLDivElement>(null);
  const { isHovered } = useHoverClickable(refHeader);

  const subHeadersCount = column.subColumns
    ? reduceSubgridTableColumnsToBodyColumns(column.subColumns).length
    : 0;
  const hasSubHeaders = subHeadersCount > 0;

  const sortByColumn = () => {
    // Sort by new column.
    if (!sortedBy || sortedBy.id !== column.id) {
      return setSortedBy({
        id: column.id,
        direction: "descending",
      });
    }

    // Was previously sorted by same column in descending, sort by ascending.
    if (sortedBy.direction === "descending") {
      return setSortedBy({
        id: column.id,
        direction: "ascending",
      });
    }

    // Was previously sorted by same column in ascending, reset the sorting.
    if (sortedBy.direction === "ascending") {
      return setSortedBy(null);
    }
  };

  return (
    <Header subheadersCount={subHeadersCount}>
      <HeaderWrapper
        column={column}
        refHeader={refHeader}
        subHeadersCount={subHeadersCount}
        hasSubHeaders={hasSubHeaders}
        isLeftBorder={isLeftBorder}
        isTopBorder={isTopBorder}
        {...(isSortable && !hasSubHeaders && { onClick: sortByColumn })}
      >
        {column.headerRenderer === undefined && (
          <Flex gap="3px" align="center">
            {isSortable && !hasSubHeaders && column.justify === "center" && (
              <SubgridSorter isVisible={false} />
            )}

            <Text variant="small1" color={Color.white}>
              {column.header}
            </Text>

            {isSortable && !hasSubHeaders && (
              <SubgridSorter
                isVisible={sortedBy?.id === column.id || isHovered}
                sortedDirection={sortedBy?.id === column.id ? sortedBy.direction : undefined}
              />
            )}
          </Flex>
        )}

        {column.headerRenderer !== undefined && column.headerRenderer}
      </HeaderWrapper>

      {column.subColumns?.map((subHeader, i) => (
        <SubgridHeader
          key={i}
          column={subHeader}
          isSortable={isSortable}
          sortedBy={sortedBy}
          setSortedBy={setSortedBy}
          isLeftBorder={false}
          isTopBorder={false}
        />
      ))}
    </Header>
  );
};
