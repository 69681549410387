import { ReviewStatus } from "@CommonTypes/merchflow/ReviewStatus";
import { MerchFlowCoreRange } from "./merchandise";

export const RoutePostMerchflows = "/merchflows";

export interface PayloadPostMerchflows {
  retailer_category_code: string;
  category_code: string;
  name: string;
  notes: string;
  core_range: {
    option: MerchFlowCoreRange;
    core_range_upload_id?: number;
  };
  go_live_date: string;
  deactivation_date: string | null;
  psa_base_pog_file_name?: string;
}

export interface ResponsePostMerchflows {
  id: number;
  name: string;
  category_code: string;
  retailer_category_code: string;
  notes: string;
  created_by: number;
  created_at: string;
  updated_at: string;
  config: {
    option: MerchFlowCoreRange;
    core_range_upload_id?: number;
  };
  core_range_upload_id: null;
  go_live_date: string;
  deactivation_date: string | null;
  status: ReviewStatus;
  filter_config_id?: number;
  base_pog_id?: number;
}
