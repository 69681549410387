import React from "react";
import { Sidebar } from "src/components";
import styled from "styled-components";

import { sidebarPadding, sidebarWidth } from "../Sidebar/Sidebar";
import { Color } from "src/elements";

const PageContainer = styled.div`
  display: flex;
  padding: ${sidebarPadding}px ${sidebarPadding}px ${sidebarPadding}px
    ${sidebarWidth + 2 * sidebarPadding}px;
  background: ${Color.white};
  height: 100vh;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

interface Props {
  children: React.ReactNode;
}

export const Page = ({ children }: Props) => (
  <>
    <PageContainer>
      <Sidebar />

      <Main>{children}</Main>
    </PageContainer>
  </>
);
