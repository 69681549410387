import { useLocation } from "react-router-dom";
import { Color, Flex, Icon, Link, Text } from "src/elements";
import { IconType } from "src/elements/Icon";
import { Transition, pages } from "src/utils";
import styled from "styled-components";
import { sidebarMenu } from "./store/constants";
import { useRef } from "react";
import { useHoverClickable } from "src/utils/hoverClickable";

export const sidebarWidth = 70;
export const sidebarPadding = 10;

const SidebarElement = styled(Flex)`
  position: fixed;
  flex-direction: column;
  top: ${sidebarPadding}px;
  bottom: ${sidebarPadding}px;
  left: ${sidebarPadding}px;
  width: ${sidebarWidth}px;
  border-radius: 3px;
  background-color: ${Color.white};
  box-shadow: 0 6px 25px rgba(223, 223, 239, 0.8);
  gap: 10px;
`;

const Menu = styled(Flex)`
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;

const MenuItemContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 10px 0;
  transition: all ${Transition.fast};
`;

const MenuItem = ({ title, path, icon }: { title?: string; path: string; icon: IconType }) => {
  const { pathname } = useLocation();

  const refMenuItem = useRef(null);
  const { isHovered } = useHoverClickable(refMenuItem);

  const isActive = pathname === path;

  return (
    <Link to={path} width="100%" minWidth="100%">
      <MenuItemContainer
        ref={refMenuItem}
        background={(isHovered && Color.lightGray) || Color.transparent}
      >
        <Icon
          name={icon}
          color={
            (isActive && Color.primary) || (isHovered && Color.spaceGrayHover) || Color.spaceGray
          }
        />

        {title && (
          <Text
            variant="caption1"
            color={isActive ? Color.primary : Color.spaceGray}
            textAlign="center"
          >
            {title}
          </Text>
        )}
      </MenuItemContainer>
    </Link>
  );
};

export const Sidebar = () => (
  <SidebarElement>
    <MenuItem path={pages.home} icon="logo" />

    <Menu>
      {sidebarMenu.map((item, i) => (
        <MenuItem key={i} title={item.title} path={item.path} icon={item.icon} />
      ))}
    </Menu>

    <MenuItem title="Log Out" path={pages.logout} icon="logout" />
  </SidebarElement>
);
