export const RouteGetViolationMerchflow_merchflowId_ = "/violation/merchflow/:merchflowId";

export interface ViolationReport {
  violation_type: string;
  violation_message: string;
  stores_affected: number;
  fix: string[];
}

export interface ResponseGetViolationMerchflow_merchflowId_ {
  data: ViolationReport[];
}
