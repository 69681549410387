import { Pagination } from "@CommonTypes/backend/pagination";
import { StoreSummary } from "@CommonTypes/owa-db/merchantSchema";

export const RouteGetAllStoreSummary = "/store-summary";

export const RoutePostImportStoreSummary = "/store-summary/import";

export interface PayloadPostStoreSummary {
  file: File;
  category_id: number;
}

export type ResponseGetAllStoreSummary = Pagination<StoreSummary>;
