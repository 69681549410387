import { PspRules } from "@CommonTypes/backend/PspRules";
import { Flex, Color, Text, Toggle, HorizontalDivider, Scroller } from "src/elements";
import { Transition } from "src/utils";

interface Props {
  pspRules: PspRules;
  setPspRules: (pspRules: PspRules) => void;
  title: string;
  rulesParent: keyof PspRules;
  area: string;
  sectionRule?: string;
  rules: {
    title: string;
    rule: string;
    isDisabled?: boolean;
    isHidden?: boolean;
    customNo?: { label: string; value: string };
    customYes?: { label: string; value: string };
  }[];
}

export const RulesSection = ({
  title,
  pspRules,
  setPspRules,
  rulesParent,
  area,
  rules,
  sectionRule,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleRule = (rule: string, checked: any) => {
    const newPspRules = { ...pspRules };

    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    newPspRules[rulesParent][rule] = checked;

    setPspRules(newPspRules);
  };

  return (
    <Flex
      width="100%"
      background={Color.lightGray}
      column
      borderRadius="3px"
      overflow="hidden"
      gridArea={area}
    >
      <Flex
        background={Color.lightGrayHover}
        padding="5px 10px"
        justify="center"
        align="center"
        gap="10px"
      >
        <Text variant="body1">{title}</Text>

        {sectionRule !== undefined && (
          <Toggle
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            isChecked={pspRules[rulesParent][sectionRule]}
            setIsChecked={(checked) => {
              toggleRule(sectionRule, checked);
            }}
          />
        )}
      </Flex>

      <HorizontalDivider color={Color.textDisabled} />

      <Scroller>
        <Flex column>
          {rules
            .filter((rule) => !rule.isHidden)
            .map((rule, i) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const isChecked = rule.customYes?.value
                ? //@ts-ignore
                  pspRules[rulesParent][rule.rule] === rule.customYes.value
                : //@ts-ignore
                  pspRules[rulesParent][rule.rule];

              return (
                <>
                  {i > 0 && <HorizontalDivider color={Color.lightGrayHover} />}

                  <Flex key={rule.rule} column padding="10px" gap="10px" justify="center">
                    <Text
                      variant="small1"
                      textAlign="center"
                      color={(rule.isDisabled && Color.textDisabled) || Color.textSecondary}
                    >
                      {rule.title}
                    </Text>

                    <Flex gap="10px">
                      <Flex
                        opacity={isChecked ? 0 : 1}
                        transition={`opacity ${Transition.fast}`}
                        cursor="default"
                      >
                        <Text
                          variant="body2"
                          color={(rule.isDisabled && Color.textDisabled) || Color.textSecondary}
                        >
                          {rule.customNo?.label || "No"}
                        </Text>
                      </Flex>

                      <Toggle
                        isDisabled={rule.isDisabled}
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        isChecked={isChecked}
                        setIsChecked={(checked) => {
                          toggleRule(
                            rule.rule,
                            rule.customYes && rule.customNo
                              ? checked
                                ? rule.customYes.value
                                : rule.customNo.value
                              : checked,
                          );
                        }}
                      />

                      <Flex
                        opacity={isChecked ? 1 : 0}
                        transition={`opacity ${Transition.fast}`}
                        cursor="default"
                      >
                        <Text
                          variant="body2"
                          color={(rule.isDisabled && Color.textDisabled) || Color.textSecondary}
                        >
                          {rule.customYes?.label || "Yes"}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </>
              );
            })}
        </Flex>
      </Scroller>
    </Flex>
  );
};
