import hexToRgba from "hex-to-rgba";
import React, { useEffect, useState } from "react";
import { useUser } from "src/atoms/user";
import { useInlineLoaders } from "src/components/InlineLoader";
import { Button, Flex, Input, Text, Icon, Color } from "src/elements";
import { getLoginToken } from "src/utils";
import styled from "styled-components";
import { removeLoginToken, setLoginToken } from "src/utils/storage";
import { RouteAuthLogin } from "@CommonApi/auth/login";
import { useApi } from "src/api";

const Page = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url(cover.svg);
  background-size: cover;
  background-repeat: no-repeat;
`;

const Wrapper = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  padding: 100px 0;
  text-align: center;
`;

const LoginCard = styled.form`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px 30px 30px 30px;
  background-color: ${Color.white};
  box-shadow: rgba(63, 142, 106, 0.1) 0px 10px 25px;
  border-radius: 4px;
`;

export const PageLogin = () => {
  const { isInlineLoading } = useInlineLoaders();
  const { postLoginApi, getVerifyUserApi } = useApi();
  const { setUser } = useUser();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);

  useEffect(() => {
    checkIfUserCanBeAutomaticallyLoggedIn();
  }, []);

  const checkIfUserCanBeAutomaticallyLoggedIn = async () => {
    if (!getLoginToken()) return;

    const response = await getVerifyUserApi();

    // Invalid login token.
    if (!response) {
      removeLoginToken();
      return;
    }

    // User verified.
    setUser(response);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const startLogin = async (event?: React.FormEvent) => {
    event?.preventDefault();
    const response = await postLoginApi({ email, password });

    if (response) {
      setIsLoginSuccessful(true);

      setTimeout(() => {
        setLoginToken(response.token);
        setUser(response.user);
      }, 1000);
    }
  };

  // Login token found, show empty page to prevent flashing until user is verified and redirected.
  if (getLoginToken()) {
    return null;
  }

  return (
    <Page>
      <Wrapper>
        <Flex justify="center">
          <Icon name="logo" size={200} />
        </Flex>

        <LoginCard onSubmit={startLogin}>
          <Flex direction="column" gap="30px">
            <Input
              title="E-Mail"
              placeholder="example@omnistream.co"
              value={email}
              setValue={setEmail}
              rightIcon={{ name: "email" }}
            />

            <Input
              title="Password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              setValue={setPassword}
              rightIcon={{
                name: showPassword ? "eyeOn" : "eyeOff",
                onClick: togglePassword,
              }}
            />
          </Flex>

          <Button
            type="submit"
            width="100%"
            size="big"
            isLoading={isInlineLoading(RouteAuthLogin)}
            isSuccessful={isLoginSuccessful}
            onClick={startLogin}
          >
            Login
          </Button>
        </LoginCard>
      </Wrapper>

      <Flex direction="column" padding="10px">
        <Text variant="small3" color={hexToRgba(Color.primary, 0.5)}>
          © {new Date().getFullYear()} All Rights Reserved Omnistream.
        </Text>
      </Flex>
    </Page>
  );
};
