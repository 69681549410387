import { useRef } from "react";
import { Color, Text } from "src/elements";
import { Flex } from "src/elements/Box/Box";
import styled from "styled-components";

import { paginationSize } from "../store/constants";
import { useHoverClickable } from "src/utils/hoverClickable";

const PaginationPageElement = styled(Flex)<{ isActive: boolean }>`
  position: relative;
  min-height: ${paginationSize};
  min-width: ${paginationSize};
  padding: 0 3px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  pointer-events: ${({ isActive }) => isActive && "none"};
`;

interface Props {
  totalPages: number;
  page: number;
  isJumper?: boolean;
  isActive: boolean;
  onClick: (page: number) => void;
}

export const PaginationPage = ({ totalPages, page, isJumper, isActive, onClick }: Props) => {
  const refPage = useRef<HTMLDivElement>(null);

  const { isHovered, isActive: isClicked } = useHoverClickable(refPage);

  return (
    <PaginationPageElement
      ref={refPage}
      isActive={isActive}
      background={
        (isActive && Color.primary) ||
        (isClicked && Color.greenSmokeActive) ||
        (isHovered && Color.greenSmoke) ||
        Color.white
      }
      onClick={() => onClick(page)}
    >
      <Flex visibility="hidden">
        <Text>{totalPages}</Text>
      </Flex>

      <Flex position="absolute" width="100%" height="100%" align="center" justify="center">
        <Text color={isActive ? Color.white : Color.textSecondary}>
          {(isJumper && "...") || page}
        </Text>
      </Flex>
    </PaginationPageElement>
  );
};
