import { useApi } from "src/api";
import { useInlineLoaders } from "src/components/InlineLoader";
import { Button, Flex } from "src/elements";
import { ProductChange } from "../store/types";
import { useModals } from "src/components/Modals";
import { useNavigation } from "src/utils/navigation";
import { pages } from "src/utils";
import { useState } from "react";
import { defaultPspRules } from "src/pages/Merchflows_merchflowId_BasePog_basePogId_/store/types";
import { PspRules } from "@CommonTypes/backend/PspRules";
import { RulesSection } from "src/components/PlanogramRules/components/RulesSection";
import { RoutePostMerchflow_merchflowId_FlowUpdateV1 } from "@CommonApi/merchflow/_merchflowId_/flow-update-v1";
import { routeToUrl } from "src/utils/axios";

interface Props {
  merchflowId: number;
  changedProducts: ProductChange[];
}

export const Step4PlanogramRules = ({ merchflowId, changedProducts }: Props) => {
  const { postFlowUpdateV1Api } = useApi();
  const { isInlineLoading } = useInlineLoaders();
  const { closeModal } = useModals();
  const { navigate } = useNavigation();

  const [pspRules, setPspRules] = useState<PspRules>(defaultPspRules);

  const startUpdate = async () => {
    const response = await postFlowUpdateV1Api(merchflowId, {
      products_to_swap: changedProducts
        .filter(
          (productChange) =>
            productChange.productToAdd !== undefined && productChange.productToRemove !== undefined,
        )
        .map((productsSwap) => ({
          product_code_to_add: productsSwap.productToAdd!.product_code,
          product_code_to_remove: productsSwap.productToRemove!.product_code,
        })),
      products_to_insert: changedProducts
        .filter(
          (productChange) =>
            productChange.productToAdd !== undefined &&
            productChange.adjacentPosition !== undefined &&
            productChange.adjacentProduct !== undefined,
        )
        .map((addedProduct) => ({
          product_code: addedProduct.productToAdd!.product_code,
          adjacent_product_code: addedProduct.adjacentProduct!.product_code,
          place_to: addedProduct.adjacentPosition!,
        })),
      product_codes_to_delete: changedProducts
        .filter(
          (productChange) =>
            productChange.productToRemove !== undefined && productChange.productToAdd === undefined,
        )
        .map((removedProduct) => removedProduct.productToRemove!.product_code),
      psp_rules: pspRules,
    });

    if (response) {
      closeModal("ModalMerchflowReviewUpdateV1");
      navigate(
        routeToUrl(
          pages.merchflows_merchflowId_StoresAll,
          {
            merchflowId,
          },
          {
            store_pog_trigger_id: response.store_pog_trigger_id,
          },
        ),
      );
    }
  };

  return (
    <Flex width="100%" column>
      <Flex width="100%" gap="20px" margin="20px 0" flexGrow>
        <RulesSection
          pspRules={pspRules}
          setPspRules={setPspRules}
          title="Merchandising"
          rulesParent="merchandising"
          area="merchandising"
          rules={[
            {
              title: "Can we break trays if a core range product cannot fit otherwise?",
              rule: "can_break_trays_if_core_range_not_fit",
            },
            {
              title: "Can we fill extra space next to cases / trays with loose products?",
              rule: "can_fill_extra_space_next_to_cases_trays_with_loose_products",
            },
            {
              title: "How should loose products be placed with respect to their cases / trays?",
              rule: "how_should_loose_products_be_placed_with_respect_to_their_cases_trays",
              isHidden:
                pspRules.merchandising
                  .can_fill_extra_space_next_to_cases_trays_with_loose_products === false,
              customNo: { label: "Behind", value: "behind" },
              customYes: { label: "In front of", value: "front" },
            },
            {
              title: "Can we leverage alternative orientations to fill empty space on a shelf?",
              rule: "leverage_alternative_orientations",
            },
          ]}
        />
      </Flex>

      <Flex justify="right">
        <Button
          isLoading={isInlineLoading(RoutePostMerchflow_merchflowId_FlowUpdateV1)}
          onClick={startUpdate}
        >
          Start Update
        </Button>
      </Flex>
    </Flex>
  );
};
