import hexToRgba from "hex-to-rgba";
import React, { useRef, useState } from "react";
import { Color, Flex, Icon, Spinner, Text } from "src/elements";
import { useHoverClickable } from "src/utils/hoverClickable";
import styled from "styled-components";

const FileUploaderElement = styled(Flex)`
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-width: 1px;
  border-radius: 4px;
`;

const FileUploaderInput = styled.input<{ isDisabled: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  pointer-events: ${({ isDisabled }) => isDisabled && "none"};
`;

interface Props {
  fileLabel: string;
  fileType: "psa" | "csv";
  isLoading: boolean;
  onChange: (file: File) => void;
  onSuccess?: React.ReactNode;
}

export const FileUploader = ({ fileLabel, fileType, isLoading, onChange, onSuccess }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const refFileInput = useRef<HTMLInputElement>(null);
  const { isHovered } = useHoverClickable(refFileInput);

  const status =
    (onSuccess && "SUCCESS") ||
    (file && file?.name?.toLowerCase()?.endsWith(`.${fileType}`) && "SELECTED") ||
    (file && "ERROR") ||
    "EMPTY";

  const backgroundColor =
    ((status === "SUCCESS" || (status === "SELECTED" && (isDragging || isHovered))) &&
      Color.greenSmoke) ||
    (status === "SELECTED" && Color.greenSmokeDisabled) ||
    (status === "ERROR" && (isDragging || isHovered) && hexToRgba(Color.red, 0.4)) ||
    (status === "ERROR" && hexToRgba(Color.red, 0.3)) ||
    ((isDragging || isHovered) && Color.opacitySkyHover) ||
    Color.opacitySky;

  const borderColor =
    ((status === "SUCCESS" || status === "SELECTED") && Color.greenSmokeHover) ||
    (status === "ERROR" && Color.redDisabled) ||
    Color.opacitySkyHover;

  const iconColor =
    ((status === "SUCCESS" || status === "SELECTED") && Color.primary) ||
    (status === "ERROR" && Color.red) ||
    Color.opacitySkyActive;

  const fileNameColor =
    ((status === "SUCCESS" || status === "SELECTED") && Color.primaryActive) ||
    (status === "ERROR" && Color.redActive) ||
    Color.textSecondary;

  const fileTypeColor =
    (status === "SUCCESS" && Color.primary) ||
    (status === "SELECTED" && Color.primary) ||
    (status === "ERROR" && Color.red) ||
    Color.opacitySkyActive;

  const startDragging = () => setIsDragging(true);

  const stopDragging = () => setIsDragging(false);

  const isCorrectFile = (file: File) => file.name.toLowerCase().endsWith("." + fileType);

  const onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files) {
      return setFile(null);
    }

    setFile(files[0]);

    // Only trigger onChange with correct file.
    if (isCorrectFile(files[0])) {
      onChange(files[0]);
    }
  };

  return (
    <FileUploaderElement
      background={backgroundColor}
      borderColor={borderColor}
      borderStyle={isDragging || isHovered ? "dashed" : "solid"}
    >
      <Icon
        name={(fileType === "psa" && "filePsa") || (fileType === "csv" && "fileCsv") || "empty"}
        color={iconColor}
      />

      <Text variant="small1" color={fileNameColor} textAlign="center">
        {file?.name || `Click or drag your ${fileLabel} file here to upload it`}
      </Text>

      {isLoading && <Spinner size="medium" color={fileTypeColor} />}

      {status === "SUCCESS" && <Icon name="checkmark" size={32} />}

      {!isLoading && (
        <Text variant="small1" color={fileTypeColor} wordBreak="break-word" textAlign="center">
          {onSuccess ||
            (status === "ERROR" && `File needs to be ${fileType.toUpperCase()}`) ||
            `${fileType.toUpperCase()} File`}
        </Text>
      )}

      <FileUploaderInput
        ref={refFileInput}
        type="file"
        onChange={onFileSelected}
        onDragOver={startDragging}
        onDragLeave={stopDragging}
        onDrop={stopDragging}
        title=" "
        accept={`.${fileType}`}
        isDisabled={isLoading || Boolean(onSuccess)}
      />
    </FileUploaderElement>
  );
};
