import { MerchFlowFlowHierarchy } from "@CommonApi/merchandise";

export const RoutePutMerchflows_filterConfigId_MergeRules =
  "/merchflows/:filterConfigId/merge-rules";

export interface PayloadPutMerchflows_filterConfigId_MergeRules {
  merge_rules: { rule_id: number; merged_variants: string[] }[];
  hierarchy: {
    [MerchFlowFlowHierarchy.CORE_RANGE]: number;
    [MerchFlowFlowHierarchy.DOS]: number;
    [MerchFlowFlowHierarchy.MOS]: number;
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  cdt: {};
}

export type ResponsePutMerchflows_filterConfigId_MergeRules = string;
