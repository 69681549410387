import { MerchFlowCoreRange, MerchFlowFlowHierarchy } from "@CommonApi/merchandise";
import { useEffect, useState } from "react";
import { StepsNavigator } from "src/components";
import { Flex, Modal } from "src/elements";
import { uuid } from "src/utils";
import { Step1Details } from "./components/Step1Details";
import { MerchflowReviewSelectedFilters, MerchflowReviewStep } from "./store/types";
import { ResponseGetMerchflowsCategories } from "@CommonApi/merchflows/categories";
import { useMerchflowsApi } from "src/api/merchflows";
import { Step2Filters } from "./components/Step2Filters";
import { ResponseGetMerchflowsFilters } from "@CommonApi/merchflows/filters";
import { ResponsePutMerchflows_merchflowId_Filters } from "@CommonApi/merchflows/_reviewId_/filters";
import { ResponseGetMerchflowsVariants } from "@CommonApi/merchflows/variants";
import { Step3MergeRules } from "./components/Step3MergeRules";
import { PayloadPutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2 } from "@CommonApi/merchflows/_merchflowId_/filter-configs/_filterConfig_id/merge-rules-v2";

export const ModalMerchflowReview = () => {
  const { getMerchflowsCategoriesApi } = useMerchflowsApi();

  const [step, setStep] = useState<MerchflowReviewStep>("STEP_1_DETAILS");

  // DETAILS DATA.
  const [merchflowId, setMerchflowId] = useState<number | null>(null);
  const [name, setName] = useState(uuid());
  const [notes, setNotes] = useState("");
  const [goLiveDate, setGoLiveDate] = useState<Date | null>(new Date());
  const [deactivationDate, setDeactivationDate] = useState<Date | null>(null);
  const [coreRange, setCoreRange] = useState<MerchFlowCoreRange>(MerchFlowCoreRange.PARTIAL);
  const [category, setCategory] = useState<string | null>(null);
  const [subcategory, setSubcategory] = useState<string | null>(null);
  const [availableCategories, setAvailableCategories] =
    useState<ResponseGetMerchflowsCategories | null>(null);

  // FILTERS DATA.
  const [availableFilters, setAvailableFilters] = useState<ResponseGetMerchflowsFilters | null>(
    null,
  );
  const [selectedFilters, setSelecterFilters] = useState<MerchflowReviewSelectedFilters>({
    baySizes: [],
    states: [],
    fixtureHeights: [],
    fixtureWidths: [],
    clusters: [],
  });
  const [appliedFilters, setAppliedFilters] =
    useState<ResponsePutMerchflows_merchflowId_Filters | null>(null);

  // MERGE RULES DATA.
  const [availableVariants, setAvailableVariants] = useState<ResponseGetMerchflowsVariants | null>(
    null,
  );
  const [mergeRules, setMergeRules] = useState<string[][]>([]);
  const [flowHierarchy, setFlowHierarchy] = useState<MerchFlowFlowHierarchy[]>([
    MerchFlowFlowHierarchy.CORE_RANGE,
    MerchFlowFlowHierarchy.DOS,
    MerchFlowFlowHierarchy.MOS,
  ]);
  const [operationMode, setOperationMode] =
    useState<
      PayloadPutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2["operation_mode"]
    >("MODELLED");

  const modalTitle = `New Flow ${category ? `- ${category}` : ""} ${
    subcategory ? ` / ${subcategory}` : ""
  } ${name ? `- ${name}` : ""}`;

  useEffect(() => {
    loadCategories();
  }, []);

  // Generate merge rule from each and every variant once after variants are loaded in.
  useEffect(() => {
    if (availableVariants) {
      setMergeRules(availableVariants.map((variant) => [variant]));
    }
  }, [availableVariants]);

  const loadCategories = async () => {
    setAvailableCategories(await getMerchflowsCategoriesApi());
  };

  return (
    <Modal name="ModalMerchflowReview" width="1200px" title={modalTitle}>
      <Flex minHeight="70vh" padding="20px" column gap="40px">
        <StepsNavigator
          steps={[
            {
              title: "Flow Details",
              isActive: step === "STEP_1_DETAILS",
              onClick: () => setStep("STEP_1_DETAILS"),
            },
            {
              title: "Stores Selection",
              isActive: step === "STEP_2_FILTERS",
              onClick: () => setStep("STEP_2_FILTERS"),
            },
            {
              title: "Merge Rules",
              isActive: step === "STEP_3_MERGE_RULES",
              onClick: () => setStep("STEP_3_MERGE_RULES"),
            },
          ]}
        />

        <Flex flexGrow>
          {step === "STEP_1_DETAILS" && (
            <Step1Details
              setStep={setStep}
              merchflowId={merchflowId}
              setMerchflowId={setMerchflowId}
              name={name}
              setName={setName}
              notes={notes}
              setNotes={setNotes}
              goLiveDate={goLiveDate}
              setGoLiveDate={setGoLiveDate}
              deactivationDate={deactivationDate}
              setDeactivationDate={setDeactivationDate}
              coreRange={coreRange}
              setCoreRange={setCoreRange}
              category={category}
              setCategory={setCategory}
              subcategory={subcategory}
              setSubcategory={setSubcategory}
              availableCategories={availableCategories}
              setAvailableFilters={setAvailableFilters}
            />
          )}

          {step === "STEP_2_FILTERS" && merchflowId && (
            <Step2Filters
              merchflowId={merchflowId}
              availableFilters={availableFilters}
              setStep={setStep}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelecterFilters}
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
              setAvailableVariants={setAvailableVariants}
            />
          )}

          {step === "STEP_3_MERGE_RULES" && appliedFilters && (
            <Step3MergeRules
              merchflowId={appliedFilters.review_id}
              filterConfigId={appliedFilters.id}
              setStep={setStep}
              availableVariants={availableVariants}
              mergeRules={mergeRules}
              setMergeRules={setMergeRules}
              flowHierarchy={flowHierarchy}
              setFlowHierarchy={setFlowHierarchy}
              operationMode={operationMode}
              setOperationMode={setOperationMode}
            />
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
