import { Flex } from "src/elements/Box/Box";
import { Color } from "src/elements/Color/Color";
import { Text } from "src/elements/Text/Text";

interface Props {
  error?: false | string;
}

export const InputError = ({ error }: Props) => {
  if (!error) return null;

  return (
    <Flex padding="0 3px">
      <Text variant="small1" color={Color.red}>
        {error}
      </Text>
    </Flex>
  );
};
