import { Color, Flex, Icon, Link, Text } from "src/elements";
import { StepsNavigatorStep } from "../store/types";
import styled from "styled-components";
import hexToRgba from "hex-to-rgba";
import { useRef } from "react";
import { useHoverClickable } from "src/utils/hoverClickable";

type Status = "COMPLETE" | "ACTIVE" | "INCOMPLETE" | "DISABLED";

const completedColor = hexToRgba(Color.primary, 0.7);

const StepElement = styled(Flex)`
  position: relative;
  justify-content: center;
  gap: 3px;
  flex-direction: column;
  flex-grow: 1;
`;

const StepLine = styled(Flex)<{
  side: "left" | "right";
  status: Status;
  isFirst?: boolean;
  isLast?: boolean;
}>`
  height: 2px;
  background-color: ${({ side, status, isFirst, isLast }) =>
    ((isFirst || isLast) && Color.transparent) ||
    (side === "left" && status === "ACTIVE" && Color.greenSmokeActive) ||
    (status === "COMPLETE" && Color.greenSmokeActive) ||
    Color.spaceGray};
  flex-grow: 1;
`;

const StepBubble = styled(Flex)`
  width: 14px;
  height: 14px;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
  border-radius: 50%;
`;

interface Props extends StepsNavigatorStep {
  isFirst: boolean;
  isLast: boolean;
  status: Status;
}

export const Step = ({ title, url, isFirst, isLast, status, onClick }: Props) => {
  const ref = useRef(null);
  const { isHovered, isActive } = useHoverClickable(ref);

  const backgroundColor =
    (status === "DISABLED" && Color.textDisabled) ||
    (status === "ACTIVE" && Color.primary) ||
    (status === "COMPLETE" && isActive && Color.greenSmokeActive) ||
    (status === "COMPLETE" && isHovered && Color.greenSmokeHover) ||
    (status === "COMPLETE" && Color.greenSmoke) ||
    Color.lightGrayHover;

  const borderColor =
    (status === "DISABLED" && Color.lightGrayHover) ||
    (status === "ACTIVE" && Color.primary) ||
    (status === "COMPLETE" && Color.greenSmokeHover) ||
    Color.spaceGray;

  const iconColor =
    (isActive && Color.primaryActive) || (isHovered && Color.primaryHover) || Color.primary;

  const textColor =
    (status === "DISABLED" && Color.textDisabled) ||
    (status === "ACTIVE" && Color.primary) ||
    (status === "COMPLETE" && isActive && Color.primaryHover) ||
    (status === "COMPLETE" && isHovered && Color.primary) ||
    (status === "COMPLETE" && completedColor) ||
    Color.textSecondary;

  return (
    <StepElement ref={ref} onClick={onClick}>
      <Flex align="center" width="100%">
        <StepLine side="left" isFirst={isFirst} status={status} />

        <StepBubble background={backgroundColor} borderColor={borderColor}>
          {status === "COMPLETE" && <Icon name="checkmark" size={10} color={iconColor} />}
        </StepBubble>

        <StepLine side="right" isLast={isLast} status={status} />
      </Flex>

      <Text variant="small1" textAlign="center" color={textColor}>
        {title}
      </Text>

      {typeof url === "string" && <Link to={url} isAbsolute />}
    </StepElement>
  );
};
