import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useUser } from "src/atoms/user";
import { useNavigation } from "src/utils/navigation";
import { getAndUnsetRedirectUrl } from "src/utils/storage";

export const IsUnauthorized = () => {
  const { user } = useUser();
  const { navigate } = useNavigation();

  // Soon as user logs in, redirect.
  useEffect(() => {
    if (user) {
      navigate(getAndUnsetRedirectUrl());
    }
  }, [user]);

  return user ? null : <Outlet />;
};
