export const RoutePutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2 =
  "/merchflows/:merchflowId/filter-configs/:filterConfigId/merge-rules-v2";

export interface PayloadPutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2 {
  merge_rules: { variants: string[] }[];
  allocation_priority: "DOS_MOS" | "MOS_DOS";
  operation_mode: "MODELLED" | "HEURISTIC";
  frogger_version?: string;
}

export interface ResponsePutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2 {
  s0_user_trigger_id: number;
}
