import { useState } from "react";
import styled from "styled-components";

import { Button, Color } from "src/elements";
import { useStoreCategoriesModal } from "src/modals/StoreCategories/store/hooks";
import { ResponseGetStoresList } from "@CommonApi/stores/list";
import { useStoresPage } from "../store/hooks";

const Item = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 8px 14px;
  margin-bottom: 8px;
  box-shadow: 0 6px 25px rgba(233, 233, 233, 0.8);
  border-radius: 4px;
  background-color: ${Color.white};
`;

const Title = styled.div`
  margin-bottom: 15px;
  color: ${Color.spaceGray};
  font-size: 12px;
`;

const Value = styled.div`
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: ${Color.textMain};
`;

const Id = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: ${Color.primary};
  border: 1px solid ${Color.primary};
  border-radius: 50%;
`;

const Col1 = styled.div`
  display: flex;
  flex-direction: column;
`;

const Col2 = styled.div`
  display: flex;
  flex-direction: column;
`;

const Col3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;
  gap: 20px;
`;

interface Props {
  store: ResponseGetStoresList["data"][0];
}

export const StoreRow = ({ store }: Props) => {
  const { openStoreCategoriesModal } = useStoreCategoriesModal();
  const { updateStoreStatus: _updateStoreStatus } = useStoresPage();

  const isActive = store.status === "ACTIVE";
  const [isLoading, setIsLoading] = useState(false);

  const updateStoreStatus = async (status: ResponseGetStoresList["data"][0]["status"]) => {
    setIsLoading(true);
    await _updateStoreStatus(store.id, status);
    setIsLoading(false);
  };

  const activateStore = () => updateStoreStatus("ACTIVE");

  const archiveStore = () => updateStoreStatus("ARCHIVED");

  return (
    <Item>
      <Col1>
        <Title>ID</Title>

        <Id>{store.id}</Id>
      </Col1>

      <Col2>
        <Title>Store Code</Title>
        <Value>{store.code}</Value>
      </Col2>

      <Col3>
        {isActive ? (
          <Button variant="inverted" color="red" isLoading={isLoading} onClick={archiveStore}>
            Archive
          </Button>
        ) : (
          <Button variant="inverted" isLoading={isLoading} onClick={activateStore}>
            Restore
          </Button>
        )}

        <Button color="greenSmoke" onClick={() => openStoreCategoriesModal(store)}>
          Categories
        </Button>
      </Col3>
    </Item>
  );
};
