import styled from "styled-components";
import { Color } from "../Color/Color";

export const HorizontalDivider = styled.div<{ color?: string; size?: number }>`
  height: ${({ size = 1 }) => `${size}px`};
  width: 100%;
  background-color: ${({ color = Color.textDisabled }) => color};
  border-radius: ${({ size = 1 }) => `${size}px`};
`;

export const VerticalDivider = styled.div<{ color?: string; size?: number }>`
  width: ${({ size = 1 }) => `${size}px`};
  height: 100%;
  min-height: 1rem;
  background-color: ${({ color = Color.textDisabled }) => color};
  border-radius: ${({ size = 1 }) => `${size}px`};
`;
