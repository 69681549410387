import { useAtomValue } from "jotai";

import { loadersAtom } from "./store/atoms";
import { Spinner } from "src/elements";

export const Loaders = () => {
  const loaders = useAtomValue(loadersAtom);

  if (loaders.length > 0) {
    return <Spinner isFullscreen />;
  }

  return null;
};
