import { Fixture } from "@CommonTypes/backend/fixture";
import { ProductMaster } from "@CommonTypes/backend/productMaster";
import { Pos } from "@CommonTypes/owa-db/merchantSchema";

export const RoutePostMerchflowsMergeRulesInput = "/merchflows/merge-rules-input";

export interface PayloadPostMerchflowsMergeRulesInput {
  ffUploadIds: number[];
  pmUploadIds: number[];
  posUploadIds: number[];
}

export interface ResponsePostMerchflowsMergeRulesInput {
  ff: Fixture[];
  pm: ProductMaster[];
  pos: Pos[];
}
