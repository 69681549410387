import { omniAtom } from "src/utils/atoms";

const prefix = "modals/newMergeRule";

export const newMergeRuleDataAtom = omniAtom({
  prefix,
  key: "data",
  value: null as {
    availableVariants: string[];
    mergeRules: string[][];
  } | null,
});

export const newMergeRuleToAddAtom = omniAtom({
  prefix,
  key: "toAdd",
  value: null as string[] | null,
});
