import { Pog, PlanogramBay } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom } from "jotai";

import { InnerWrapper } from "../../common/InnerWrapper";
import { Scaleable } from "../../store/types";
import { Bay } from "./Bay";
import { Pegboards } from "../Pegboards/Pegboards";

interface Props extends Scaleable {
  bays: PlanogramBay[];
  isEditable: boolean;
  pogAtom: PrimitiveAtom<Pog>;
}

export const Bays = ({ bays, scaleX, scaleY, isEditable, pogAtom }: Props) => (
  <InnerWrapper className="bays">
    {bays.map((bay, bayNo) => (
      <Bay
        key={bayNo}
        bay={bay}
        scaleX={scaleX}
        scaleY={scaleY}
        isEditable={isEditable}
        pogAtom={pogAtom}
      >
        <Pegboards
          pegboards={bay.pegboards || []}
          width={bay.xRight - bay.xLeft}
          scaleX={scaleX}
          scaleY={scaleY}
        />
      </Bay>
    ))}
  </InnerWrapper>
);
