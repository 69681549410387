import { MerchFlowFlowHierarchy } from "@CommonApi/merchandise";
import {
  ResponseGetMerchflowsVariants,
  RouteGetMerchflowsVariants,
} from "@CommonApi/merchflows/variants";
import React, { useEffect, useMemo } from "react";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useModals } from "src/components/Modals";
import {
  Button,
  Color,
  Flex,
  Grid,
  Icon,
  InlineMessage,
  Radios,
  Scroller,
  Spinner,
  SubgridTable,
  Text,
} from "src/elements";
import { SubgridCell } from "src/elements/SubgridTable/components/SubgridCell";
import { MerchflowReviewStep } from "../store/types";
import { RoutePutMerchflows_filterConfigId_MergeRules } from "@CommonApi/merchflows/_filterConfigId_/merge-rules";
import { RoutePostMerchflowsMergeRulesInput } from "@CommonApi/merchflows/merge-rules-input";
import { useAtom } from "jotai";
import { useNewMergeRuleModal } from "src/modals/NewMergeRule/store/hooks";
import { newMergeRuleToAddAtom } from "src/modals/NewMergeRule/store/atoms";
import { useIsFeatureFlagEnabled } from "src/components/FeatureFlagProvider/store/utils";
import { useApi } from "src/api";
import { useNavigation } from "src/utils/navigation";
import { pages } from "src/utils";
import {
  PayloadPutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2,
  RoutePutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2,
} from "@CommonApi/merchflows/_merchflowId_/filter-configs/_filterConfig_id/merge-rules-v2";

const MergeRuleRow = ({
  index,
  variants,
  setMergeRules,
}: {
  index: number;
  variants: string[];
  setMergeRules: React.Dispatch<React.SetStateAction<string[][]>>;
}) => {
  const deleteMergeRule = () => {
    setMergeRules((mergeRules) => {
      return [...mergeRules.slice(0, index), ...mergeRules.slice(index + 1)];
    });
  };

  return (
    <Grid backgroundHovered={Color.lightGray}>
      <SubgridCell isWithoutBorder>{String(index + 1).padStart(3, "0")}</SubgridCell>

      <SubgridCell isWithoutBorder>{variants.join(", ")}</SubgridCell>

      <SubgridCell isWithoutBorder justify="right">
        <Flex onClick={deleteMergeRule}>
          <Icon name="delete" size={14} color={Color.red} />
        </Flex>
      </SubgridCell>
    </Grid>
  );
};

const HeaderPriority = () => (
  <Flex
    background={Color.lightGray}
    column
    gap="8px"
    padding="8px"
    borderBottom={`1px solid ${Color.lightGrayHover}`}
  >
    <Text variant="small1" color={Color.yellow}>
      Merge Rules
    </Text>

    <Text variant="small1" color={Color.primaryActive}>
      Priority
    </Text>
  </Flex>
);

const HeaderVariants = () => (
  <Flex
    background={Color.lightGray}
    column
    gap="8px"
    padding="8px"
    borderBottom={`1px solid ${Color.lightGrayHover}`}
  >
    <Text variant="small1" color={Color.textSecondary}>
      Please accept, create or delete your Merge Rules before proceeding.
    </Text>

    <Text variant="small1" color={Color.primaryActive}>
      Merged Variants
    </Text>
  </Flex>
);

const HeaderActions = ({
  availableVariants,
  mergeRules,
  setMergeRules,
}: {
  availableVariants: string[];
  mergeRules: string[][];
  setMergeRules: (mergeRules: string[][]) => void;
}) => {
  const { openConfirmModal } = useModals();
  const { openNewMergeRuleModal } = useNewMergeRuleModal();

  const deleteAllMergeRules = () => setMergeRules([]);

  const openDeleteAllMergeRulesConfirmModal = () => {
    openConfirmModal({
      title: "Delete All Merge Rules?",
      description: "All merge rules will be permanently deleted!",
      buttons: [
        {
          label: "Cancel",
          variant: "inverted",
          color: "primary",
        },
        {
          label: "Delete All Merge Rules",
          variant: "default",
          color: "red",
          onClick: deleteAllMergeRules,
        },
      ],
    });
  };

  return (
    <Flex
      background={Color.lightGray}
      align="bottom"
      gap="8px"
      padding="8px"
      borderBottom={`1px solid ${Color.lightGrayHover}`}
    >
      <Button
        isDisabled={mergeRules.length === 0}
        size="small"
        variant="inverted"
        color="red"
        onClick={openDeleteAllMergeRulesConfirmModal}
      >
        Delete All Merge Rules
      </Button>

      <Button
        size="small"
        color="greenSmoke"
        onClick={() =>
          openNewMergeRuleModal({
            availableVariants,
            mergeRules,
          })
        }
      >
        New Merge Rule
      </Button>
    </Flex>
  );
};

interface Props {
  merchflowId: number;
  filterConfigId: number;
  setStep: (step: MerchflowReviewStep) => void;
  availableVariants: ResponseGetMerchflowsVariants | null;
  mergeRules: string[][];
  setMergeRules: React.Dispatch<React.SetStateAction<string[][]>>;
  flowHierarchy: MerchFlowFlowHierarchy[];
  setFlowHierarchy: (flowHierarchy: MerchFlowFlowHierarchy[]) => void;
  operationMode: PayloadPutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2["operation_mode"];
  setOperationMode: (operationMode: Props["operationMode"]) => void;
}

export const Step3MergeRules = ({
  merchflowId,
  filterConfigId,
  setStep,
  availableVariants,
  mergeRules,
  setMergeRules,
  flowHierarchy,
  setFlowHierarchy,
  operationMode,
  setOperationMode,
}: Props) => {
  const { putMerchflows_merchflowId_MergeRulesApiV2 } = useApi();
  const { isInlineLoading } = useInlineLoaders();
  const { closeModal: _closeModal } = useModals();
  const { navigate } = useNavigation();
  const featureFlagS0Refactor = useIsFeatureFlagEnabled("s0Refactor");

  const [newMergeRuleToAdd, setNewMergeRuleToAdd] = useAtom(newMergeRuleToAddAtom);

  const isVariantsLoading = isInlineLoading(RouteGetMerchflowsVariants);
  const isMergeRulesApplying =
    isInlineLoading(RoutePutMerchflows_filterConfigId_MergeRules) ||
    (!featureFlagS0Refactor && isInlineLoading(RoutePostMerchflowsMergeRulesInput));

  // Effect to use newly added merge rule from separate modal.
  useEffect(() => {
    if (newMergeRuleToAdd) {
      setMergeRules([...mergeRules, newMergeRuleToAdd]);
      setNewMergeRuleToAdd(null);
    }
  }, [newMergeRuleToAdd]);

  const closeModal = () => {
    _closeModal("ModalMerchflowReview");
  };

  const isSomeVariantUnused = useMemo(() => {
    if (availableVariants) {
      variantLooper: for (const variant of availableVariants) {
        for (const mergeRule of mergeRules) {
          if (mergeRule.includes(variant)) {
            continue variantLooper;
          }
        }

        return true;
      }
    }
    return false;
  }, [availableVariants, mergeRules]);

  const previousStep = () => {
    setStep("STEP_2_FILTERS");
  };

  const swapHierarchy = () => {
    setFlowHierarchy(
      flowHierarchy[1] === MerchFlowFlowHierarchy.DOS
        ? [
            MerchFlowFlowHierarchy.CORE_RANGE,
            MerchFlowFlowHierarchy.MOS,
            MerchFlowFlowHierarchy.DOS,
          ]
        : [
            MerchFlowFlowHierarchy.CORE_RANGE,
            MerchFlowFlowHierarchy.DOS,
            MerchFlowFlowHierarchy.MOS,
          ],
    );
  };

  const applyMergeRules = async () => {
    const response = await putMerchflows_merchflowId_MergeRulesApiV2(merchflowId, filterConfigId, {
      merge_rules: mergeRules.map((mergeRule) => ({
        variants: mergeRule,
      })),
      allocation_priority: flowHierarchy[1] === MerchFlowFlowHierarchy.DOS ? "DOS_MOS" : "MOS_DOS",
      operation_mode: "MODELLED",
    });

    if (response) {
      navigate(pages.merchflows_merchflowId_S0_s0UserTriggerId_, {
        merchflowId,
        s0UserTriggerId: response.s0_user_trigger_id,
      });
      closeModal();
    }
  };

  return (
    <Flex flexGrow={1} minHeight="0">
      {isVariantsLoading && <Spinner size="big" label="Merge Rules are loading..." isCentered />}

      {!isVariantsLoading && availableVariants && (
        <Flex width="100%" column gap="10px" flexGrow={1} minHeight="0">
          <Flex
            width="100%"
            borderColor={Color.lightGrayHover}
            borderRadius="3px"
            flexGrow={1}
            minHeight="0"
          >
            <Scroller gutter="auto" maxHeight="35vh">
              <SubgridTable
                columns={[
                  {
                    id: "index",
                    header: "Priority",
                    headerWithoutStyle: true,
                    headerRenderer: <HeaderPriority />,
                  },
                  {
                    id: "variants",
                    header: "Variants",
                    headerWithoutStyle: true,
                    headerRenderer: <HeaderVariants />,
                    width: "1fr",
                  },
                  {
                    id: "actions",
                    header: "Actions",
                    headerWithoutStyle: true,
                    headerRenderer: (
                      <HeaderActions
                        availableVariants={availableVariants}
                        mergeRules={mergeRules}
                        setMergeRules={setMergeRules}
                      />
                    ),
                    justify: "right",
                  },
                ]}
                data={mergeRules.map((mergeRule, i) => ({
                  index: i,
                  variants: mergeRule,
                  setMergeRules,
                }))}
                rowRenderer={MergeRuleRow}
              />
            </Scroller>
          </Flex>

          <Flex width="100%" gap="10px">
            <Flex
              padding="10px"
              borderColor={Color.lightGrayHover}
              borderRadius="3px"
              gap="20px"
              flexGrow={3}
            >
              <Flex column height="100%" justify="center">
                <Text variant="small1" color={Color.yellow}>
                  Flow Hierarchy
                </Text>

                <Flex flexGrow={1} align="center">
                  <Icon name="categories" size={32} color={Color.yellow} />
                </Flex>
              </Flex>

              <Flex column gap="5px">
                <Text variant="small1">Please select your Flow Hierarchy before proceeding.</Text>

                {flowHierarchy.map((entry, i) => (
                  <Flex key={i} align="center" gap="10px">
                    <Text variant="small2">Priority {i + 1}</Text>

                    <Flex background={Color.lightGray} borderRadius="3px" padding="3px 5px">
                      <Text variant="small2">{entry}</Text>
                    </Flex>
                  </Flex>
                ))}
              </Flex>

              <Flex align="bottom" margin="0 0 0 -220px">
                <Button
                  width="40px"
                  height="47px"
                  variant="inverted"
                  color="greenSmoke"
                  iconRight={{ name: "merchRulesSwapDosMos", size: 18 }}
                  onClick={swapHierarchy}
                />
              </Flex>
            </Flex>

            <Flex
              padding="10px"
              borderColor={Color.lightGrayHover}
              borderRadius="3px"
              gap="20px"
              flexGrow
            >
              <Flex column height="100%" justify="center">
                <Text variant="small1" color={Color.yellow}>
                  Algorithm
                </Text>

                <Flex flexGrow={1} align="center">
                  <Icon name="planogram" size={32} color={Color.yellow} />
                </Flex>
              </Flex>

              <Flex column gap="5px">
                <Text variant="small1">Please select your preferred algorithm.</Text>

                <Radios
                  options={[
                    {
                      label: "Modelled",
                      value: "MODELLED",
                    },
                    { label: "Heuristic", value: "HEURISTIC" },
                  ]}
                  value={operationMode}
                  setValue={setOperationMode}
                />
              </Flex>
            </Flex>
          </Flex>

          <Flex justify="between" align="center" gap="10px">
            <Flex>
              {isSomeVariantUnused && (
                <InlineMessage status="error">
                  There are variants unassigned - please create a new merge rule to ensure all
                  variants are assigned before proceeding.
                </InlineMessage>
              )}
            </Flex>

            <Flex gap="10px">
              <Button variant="inverted" onClick={previousStep}>
                Back
              </Button>

              <Button
                isDisabled={isSomeVariantUnused || isInlineLoading("merchFlow/loadMergeRules")}
                isLoading={
                  isMergeRulesApplying ||
                  isInlineLoading("merchFlow/loadMergeRules") ||
                  isInlineLoading(
                    RoutePutMerchflows_merchflowId_FilterConfigs_filterConfigId_MergeRulesV2,
                  )
                }
                onClick={applyMergeRules}
              >
                Apply Merge Rules
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
