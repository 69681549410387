import { FlowUpdateProduct } from "@CommonApi/merchflow/_merchflowId_/flow-update/products";

export const productToReadableFormat = (product: FlowUpdateProduct) =>
  `${product.product_code} - ${product.name}`;

export const filterProducts = (products: FlowUpdateProduct[], search: string) => {
  return products
    .filter((product) =>
      search.length > 0
        ? product.product_code.toLowerCase().includes(search.toLowerCase()) ||
          product.name.toLowerCase().includes(search.toLowerCase())
        : true,
    )
    .sort((product1, product2) => (product1.product_code < product2.product_code ? -1 : 1));
};
