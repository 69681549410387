import { PspRules } from "@CommonTypes/backend/PspRules";

export const RoutePostMerchflowApplyToBases = "/merchflow/apply-to-bases";

export interface PayloadPostMerchflowApplyToBases {
  base_pog_id: number;
  psp_rules: PspRules;
}

export interface ResponsePostMerchflowApplyToBases {
  store_pog_trigger_id: number;
}
