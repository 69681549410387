export const RouteGetCategoriesList = "/categories/list";

export interface ParamsGetCategoriesList {
  includeArchived?: boolean;
}

export type ResponseGetCategoriesList = {
  catGroup: string;
  categories: Category[];
}[];

export interface Category {
  id: number;
  code: string;
  status: "ACTIVE" | "ARCHIVED";
  created_by: number;
  created_at: string;
  updated_at: string;
  category_group: string;
  isBound: boolean;
}
