import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtom } from "jotai";

import { Panel } from "../../../Panel/Panel";
import { NewShelf } from "./panels/NewShelf/NewShelf";
import { ShelvesDetails } from "./panels/ShelvesDetails/ShelvesDetails";
import {
  planogramSelectedShelfAtom,
  planogramSidepanelTabAtom,
} from "src/components/PlanogramExtensions/store/atoms";
import { PlanogramSidepanelTab } from "src/components/PlanogramExtensions/store/types";

interface Props {
  pogAtom: PrimitiveAtom<Pog>;
}

export const FixturesPanel = ({ pogAtom }: Props) => {
  const [sidepanelTab, setSidepanelTab] = useAtom(planogramSidepanelTabAtom);
  const [selectedShelf] = useAtom(planogramSelectedShelfAtom);

  return (
    <Panel
      tabs={
        !selectedShelf || selectedShelf.isNew
          ? [PlanogramSidepanelTab.FIXTURES_DETAILS, PlanogramSidepanelTab.ADD_FIXTURE]
          : [PlanogramSidepanelTab.FIXTURES_DETAILS, PlanogramSidepanelTab.EDIT_FIXTURE]
      }
      tab={sidepanelTab}
      setTab={setSidepanelTab}
      width="320px"
    >
      {sidepanelTab === PlanogramSidepanelTab.FIXTURES_DETAILS && (
        <ShelvesDetails pogAtom={pogAtom} />
      )}

      {(sidepanelTab === PlanogramSidepanelTab.ADD_FIXTURE ||
        sidepanelTab === PlanogramSidepanelTab.EDIT_FIXTURE) && <NewShelf pogAtom={pogAtom} />}
    </Panel>
  );
};
