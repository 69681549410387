import { DragBounds, ResizeSide, Scaleable, Snappable } from "../../../store/types";
import { moveBoundsChecker, resizeBoundsChecker } from "./boundsChecker";
import { resizeSizeChecker } from "./sizeChecker";
import { moveSnappablesChecker, resizeSnappablesChecker } from "./snappableChecker";

interface MoveProps extends DragBounds, Scaleable {
  bounds: DragBounds;
  snappables: Snappable[];
}

interface ResizeProps extends MoveProps {
  side: ResizeSide;
}

export const moveCoordinatesChecker = ({
  xLeft,
  xRight,
  yBottom,
  yTop,
  bounds,
  snappables,
  scaleX,
  scaleY,
}: MoveProps) => {
  const {
    xLeft: xLeftNew,
    xRight: xRightNew,
    yBottom: yBottomNew,
    yTop: yTopNew,
  } = moveSnappablesChecker({
    xLeft,
    xRight,
    yBottom,
    yTop,
    snappables,
    scaleX,
    scaleY,
  });

  return moveBoundsChecker({
    xLeft: xLeftNew,
    xRight: xRightNew,
    yBottom: yBottomNew,
    yTop: yTopNew,
    bounds,
  });
};

export const resizeCoordinatesChecker = ({
  xLeft,
  xRight,
  yBottom,
  yTop,
  side,
  bounds,
  snappables,
  scaleX,
  scaleY,
}: ResizeProps) => {
  const {
    xLeft: xLeftSnappable,
    xRight: xRightSnappable,
    yBottom: yBottomSnappable,
    yTop: yTopSnappable,
  } = resizeSnappablesChecker({
    xLeft,
    xRight,
    yBottom,
    yTop,
    snappables,
    scaleX,
    scaleY,
  });

  const {
    xLeft: xLeftSize,
    xRight: xRightSize,
    yBottom: yBottomSize,
    yTop: yTopSize,
  } = resizeSizeChecker({
    xLeft: xLeftSnappable,
    xRight: xRightSnappable,
    yBottom: yBottomSnappable,
    yTop: yTopSnappable,
    side,
  });

  const {
    xLeft: xLeftBounds,
    xRight: xRightBounds,
    yBottom: yBottomBounds,
    yTop: yTopBounds,
  } = resizeBoundsChecker({
    xLeft: xLeftSize,
    xRight: xRightSize,
    yBottom: yBottomSize,
    yTop: yTopSize,
    bounds,
  });

  return {
    xLeft: xLeftBounds,
    xRight: xRightBounds,
    yBottom: yBottomBounds,
    yTop: yTopBounds,
  };
};
