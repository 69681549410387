import { Flex } from "src/elements";
import styled from "styled-components";

const GrillContainer = styled(Flex)<{
  grillHeight: number;
  scaleY: number;
}>`
  width: 100%;
  height: ${({ grillHeight, scaleY }) => `calc(${grillHeight}px * ${scaleY})`};
  position: absolute;
  top: ${({ grillHeight, scaleY }) => `calc(${grillHeight}px * -${scaleY})`};
  left: 0;
  z-index: 1;
  pointer-events: none;
`;

const GrillElement = styled(Flex)`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url('data:image/svg+xml,
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="100%">
      <rect x="0" y="0" width="1" height="100%" fill="001a13"/>
      <rect x="0" y="0" width="8" height="1" fill="001a13"/>
    </svg>
  ');
`;

interface Props {
  height: number;
  scaleY: number;
}

export const Grill = ({ height, scaleY }: Props) => {
  if (height === 0) return null;

  return (
    <GrillContainer grillHeight={height} scaleY={scaleY}>
      <GrillElement />
    </GrillContainer>
  );
};
