import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { getContainerLabelDetails } from "@CommonUtils/pog/container";
import { Tag, Text } from "src/elements";

interface Props {
  container: PlanogramContainer;
}

export const ContainerDetails = ({ container }: Props) => {
  const { cdt0, cdt1, cdt2 } = getContainerLabelDetails(container);

  return (
    <>
      {!cdt0 && !cdt1 && cdt2 ? (
        <Text whiteSpace="nowrap" textOverflow="ellipsis">
          {container.label.replaceAll("(", "").replaceAll("'", "").replaceAll(")", "")}
        </Text>
      ) : null}

      {cdt0 && <Tag label="CDT0" value={cdt0} />}

      {cdt1 && <Tag label="CDT1" value={cdt1} />}

      {cdt2 && <Tag label="CDT2" value={cdt2} />}
    </>
  );
};
