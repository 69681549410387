import { ResponseGetMerchflows_MerchflowId_Stores_StoreCode_ } from "@CommonApi/merchflows/_merchflowId_/stores/_storeCode_";
import { omniAtom } from "src/utils/atoms";

const prefix = "merchflows/:merchflowId/stores/:storeCode";

export const merchflows_MerchflowId_Stores_StoreCode_Atom = omniAtom({
  prefix,
  key: "data",
  value: null as null | ResponseGetMerchflows_MerchflowId_Stores_StoreCode_,
});

export const Stores_StoreCode_storePogIndexAtom = omniAtom({
  prefix,
  key: "storePogIndex",
  value: 0,
});
