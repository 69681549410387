import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";

export const RoutePostMerchflows_merchflowId_S1 = "/merchflows/:merchflowId/s1";

export interface PayloadPostMercflows_merchflowId_S1 {
  s0_user_trigger_id: number;
  s0_solution_id: number;
  s0_solution: PogNonCircular;
  frogger_version?: string;
}

export interface ResponsePostMercflows_merchflowId_S1 {
  s1_user_trigger_id: number;
}
