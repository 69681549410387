import React from "react";
import { Radio } from "./components/Radio";

import { Text, Color, Flex } from "src/elements";

interface Props<T> {
  options: { label: string; value: T; isDisabled?: boolean }[];
  value: T;
  setValue: (value: T) => void;
  label?: string;
  width?: string;
  direction?: "column" | "row";
  size?: "default" | "small";
  isUnselectable?: boolean;
  variant?: "body2" | "small2";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Radios = <T extends any>({
  options,
  value,
  setValue,
  label,
  width = "100%",
  direction = "row",
  size = "default",
  isUnselectable = false,
  variant,
}: Props<T>) => (
  <Flex width={width} justify="between" align="center" gap="10px" direction={direction}>
    {label && (
      <Text
        variant={size === "small" ? "caption1" : "body1"}
        color={size === "small" ? Color.primaryActive : Color.textMain}
      >
        {label}
      </Text>
    )}

    <Flex gap="5px 10px" direction={direction}>
      {options.map((option, i) => (
        <Radio
          key={i}
          label={option.label}
          value={option.value}
          setIsChecked={setValue}
          size={size}
          variant={variant}
          isChecked={option.value === value}
          isDisabled={option.isDisabled ?? false}
          isUnselectable={isUnselectable}
        />
      ))}
    </Flex>
  </Flex>
);
