import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import { PlanogramShelf } from "@CommonTypes/merchflow/pog/shelf";
import { omniAtom } from "src/utils/atoms";
import { PlanogramSidepanelTab } from "./types";

const prefix = "planogram/extensions";

export const planogramFixturesModeAtom = omniAtom({
  prefix,
  key: "fixturesMode",
  value: false,
});

export const planogramImagesModeAtom = omniAtom({
  prefix,
  key: "imagesMode",
  value: true,
});

export const planogramHoveredItemAtom = omniAtom({
  prefix,
  key: "hovered/product",
  value: null as PlanogramItem | null,
});

export const planogramHoveredContainerAtom = omniAtom({
  prefix,
  key: "hovered/container",
  value: null as PlanogramContainer | null,
});

export const planogramHoveredShelfAtom = omniAtom({
  prefix,
  key: "hovered/shelf",
  value: null as PlanogramShelf | null,
});

export const planogramItemSearcherAtom = omniAtom({
  prefix,
  key: "itemSearcher",
  value: "",
});

export const planogramSelectedShelfAtom = omniAtom({
  prefix,
  key: "selectedShelf",
  value: null as (PlanogramShelf & { isNew?: boolean }) | null,
});

export const planogramSelectedItemAtom = omniAtom({
  prefix,
  key: "selectedItem",
  value: null as PlanogramItem | null,
});

export const planogramSidepanelTabAtom = omniAtom({
  prefix,
  key: "sidepanel/tab",
  value: PlanogramSidepanelTab.HIGHLIGHTS,
});
