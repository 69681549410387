import React, { useRef } from "react";
import { MouseButton } from "src/types/mouseButtons";
import styled from "styled-components";

import {
  DragBounds,
  DragModifications,
  ResizeSide,
  Scaleable,
  Snappable,
} from "../../../store/types";
import { resizeCoordinatesChecker } from "../utils/coordinatesChecker";

const ResizeHandleElement = styled.div<{ side: ResizeSide }>`
  width: ${({ side }) => (side === "top" || side === "bottom" ? "100%" : "10px")};
  height: ${({ side }) => (side === "left" || side === "right" ? "100%" : "10px")};
  position: absolute;
  top: ${({ side }) =>
    (side === "top" ||
      side === "left" ||
      side === "right" ||
      side === "top-left" ||
      side === "top-right") &&
    "-1px"};
  left: ${({ side }) =>
    (side === "left" ||
      side === "top" ||
      side === "bottom" ||
      side === "top-left" ||
      side === "bottom-left") &&
    "-1px"};
  right: ${({ side }) =>
    (side === "right" || side === "top-right" || side === "bottom-right") && "-1px"};
  bottom: ${({ side }) =>
    (side === "bottom" || side === "bottom-left" || side === "bottom-right") && "-1px"};
  pointer-events: all !important;
  cursor: ${({ side }) =>
    ((side === "top" || side === "bottom") && "n-resize") ||
    ((side === "left" || side === "right") && "e-resize") ||
    ((side === "top-left" || side === "bottom-right") && "nw-resize") ||
    ((side === "top-right" || side === "bottom-left") && "ne-resize")};
`;

interface Props extends DragBounds, Scaleable {
  side: ResizeSide;
  refDraggable: React.RefObject<HTMLDivElement>;
  bounds: DragBounds;
  snappables: Snappable[];
  onModify: (modifications: DragModifications) => void;
}

export const ResizeHandle = ({
  xLeft,
  xRight,
  yBottom,
  yTop,
  scaleX,
  scaleY,
  side,
  refDraggable,
  bounds,
  snappables,
  onModify,
}: Props) => {
  const refStartX = useRef(0);
  const refStartY = useRef(0);

  const refXLeft = useRef(xLeft);
  const refXRight = useRef(xRight);
  const refYTop = useRef(yTop);
  const refYBottom = useRef(yBottom);

  const onResizeStart = (event: React.MouseEvent<HTMLDivElement>) => {
    // Resizing only with left mouse button.
    if (event.button !== MouseButton.PRIMARY) return;

    if (!refDraggable.current) return;

    // Stop propagation to prevent drag handler from firing.
    event.stopPropagation();

    // Save original cursor coordinates to calculate differences on drag.
    refStartX.current = event.screenX;
    refStartY.current = event.screenY;

    // GPU go brrrr!
    refDraggable.current.setAttribute("data-resizing", "");

    // Events for dragging.
    document.body.setAttribute(
      "data-resizing",
      ((side === "top" || side === "bottom") && "vertical") ||
        ((side === "left" || side === "right") && "horizontal") ||
        ((side === "top-left" || side === "bottom-right") && "top-left") ||
        ((side === "top-right" || side === "bottom-left") && "top-right") ||
        "",
    );
    document.addEventListener("pointermove", onResize);
    document.addEventListener("pointerup", onResizeStop, { once: true });
  };

  const onResizeStop = () => {
    // Lets end GPU misery for now.
    refDraggable.current?.removeAttribute("data-resizing");

    // Clear resize events.
    document.body.removeAttribute("data-resizing");
    document.removeEventListener("pointermove", onResize);

    // Remove temporary transformation.
    refDraggable.current?.removeAttribute("style");

    onModify({
      xLeft: refXLeft.current,
      xRight: refXRight.current,
      yBottom: refYBottom.current,
      yTop: refYTop.current,
    });
  };

  const onResize = (event: MouseEvent) => {
    // Raw drag difference on screen.
    const dragDifferenceYRaw = refStartY.current - event.screenY;
    const dragDifferenceXRaw = event.screenX - refStartX.current;

    // Find difference based on how much can be dragged in minimum.
    const dragDifferenceYScaled = Math.round(Math.round(dragDifferenceYRaw / scaleY));
    const dragDifferenceXScaled = Math.round(Math.round(dragDifferenceXRaw / scaleX));

    let xLeftResized = xLeft;
    let xRightResized = xRight;
    let yBottomResized = yBottom;
    let yTopResized = yTop;

    // Resize to left.
    if (side === "left" || side === "top-left" || side === "bottom-left") {
      xLeftResized = xLeft + dragDifferenceXScaled;
    }

    // Resize to right.
    if (side === "right" || side === "top-right" || side === "bottom-right") {
      xRightResized = xRight + dragDifferenceXScaled;
    }

    // Resize to top.
    if (side === "top" || side === "top-left" || side === "top-right") {
      yTopResized = yTop + dragDifferenceYScaled;
    }

    // Resize to bottom.
    if (side === "bottom" || side === "bottom-left" || side === "bottom-right") {
      yBottomResized = yBottom + dragDifferenceYScaled;
    }

    const {
      xLeft: xLeftNew,
      xRight: xRightNew,
      yBottom: yBottomNew,
      yTop: yTopNew,
    } = resizeCoordinatesChecker({
      xLeft: xLeftResized,
      xRight: xRightResized,
      yBottom: yBottomResized,
      yTop: yTopResized,
      side,
      bounds,
      snappables,
      scaleX,
      scaleY,
    });

    // Save new coordinates to update on drag stop.
    refXLeft.current = xLeftNew;
    refXRight.current = xRightNew;
    refYBottom.current = yBottomNew;
    refYTop.current = yTopNew;

    refDraggable.current?.setAttribute(
      "style",
      `
      --x: ${refXLeft.current * scaleX}px;
      --y: ${refYBottom.current * scaleY}px;
      --width: ${(refXRight.current - refXLeft.current) * scaleX}px;
      --height: ${(refYTop.current - refYBottom.current) * scaleY}px;
      `,
    );
  };

  return <ResizeHandleElement side={side} onPointerDown={onResizeStart} />;
};
