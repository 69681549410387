import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";

export const RouteGetMerchflows_merchflowId_S0_s0UserTriggerId_ =
  "/merchflows/:merchflowId/s0/:s0UserTriggerId";

export type ResponseGetMerchflows_merchflowId_S0_s0UserTriggerId_ = ({
  s0_solution_id: number;
} & (
  | { status: "NOT_STARTED" | "IN_PROGRESS" | "ERROR" }
  | {
      status: "DONE";
      solution: PogNonCircular;
    }
))[];
