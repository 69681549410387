import * as THREE from "three";
import { useEffect } from "react";
import { CSG } from "three-csg-ts";

interface Props {
  setFreezer: (freezer: THREE.Mesh) => void;
  scene: THREE.Scene;
  width: number;
  height: number;
  depth: number;
  wallThickness: number;
}

export const FreezerMesh = ({ setFreezer, scene, width, height, depth, wallThickness }: Props) => {
  const wallMaterial = new THREE.MeshBasicMaterial({ color: 0xcccccc });

  useEffect(() => {
    const freezer = new THREE.Group();

    const innerGeometry = new THREE.BoxGeometry(width, height, depth);
    const innerMaterial = new THREE.MeshBasicMaterial({
      color: 0x00ff00,
      wireframe: true,
    });
    const innerMesh = new THREE.Mesh(innerGeometry, innerMaterial);
    setFreezer(innerMesh);
    freezer.add(innerMesh);

    const frontWall = createWallWithWindow("FRONT");
    frontWall.position.set(0, 0, -depth / 2 - wallThickness / 2);
    freezer.add(frontWall);

    const backWall = createWallWithWindow("FRONT");
    backWall.position.set(0, 0, depth / 2 + wallThickness / 2);
    freezer.add(backWall);

    const leftWall = createWallWithWindow("SIDE");
    leftWall.position.set(-width / 2 - wallThickness / 2, 0, 0);
    freezer.add(leftWall);

    const rightWall = createWallWithWindow("SIDE");
    rightWall.position.set(width / 2 + wallThickness / 2, 0, 0);
    freezer.add(rightWall);

    scene.add(freezer);

    return () => {
      scene.remove(freezer);
    };
  }, []);

  const createWallWithWindow = (orientation: "FRONT" | "SIDE") => {
    const isFront = orientation === "FRONT";

    const wallWidth = isFront ? width + 2 * wallThickness : wallThickness;
    const wallHeight = height;
    const wallDepth = isFront ? wallThickness : depth;
    const wallGeometry = new THREE.BoxGeometry(wallWidth, wallHeight, wallDepth);
    const wallMesh = new THREE.Mesh(wallGeometry, wallMaterial);

    const windowWidth = isFront ? width : wallThickness;
    const windowHeight = height - 2 * wallThickness;
    const windowDepth = isFront ? wallThickness : depth - 2 * wallThickness;
    const windowGeometry = new THREE.BoxGeometry(windowWidth, windowHeight, windowDepth);
    const windowMesh = new THREE.Mesh(windowGeometry);

    const wallWithWindowMesh = CSG.subtract(wallMesh, windowMesh);

    return wallWithWindowMesh;
  };

  return null;
};
