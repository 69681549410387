export const RoutePostCategories = "/categories";

export const RoutePutCategories = RoutePostCategories;

export interface PayloadPostCategories {
  categoryGroup: string;
  code: string;
}

export interface PayloadPutCategories {
  catId: number;
  categoryGroup: string;
  code: string;
}

export interface ResponsePostCategories {
  id: number;
  code: string;
  status: "ACTIVE" | "ARCHIVED";
  created_by: number;
  created_at: string;
  updated_at: string;
  category_group: string;
}

export type ResponsePutCategories = ResponsePostCategories;
