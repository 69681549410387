import React, { useRef } from "react";

import { InnerInput } from "./components/InnerInput";
import { InputContainer } from "./components/InputContainer";
import { InputError } from "./components/InputError";
import { InputIcon } from "./components/InputIcon";
import { InputOuterContainer } from "./components/InputOuterContainer";
import { InputTitle } from "./components/InputTitle";
import { CommonProps } from "./store/types";
import styled from "styled-components";
import { useHoverClickable } from "src/utils/hoverClickable";

const DatePicker = styled("input")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    color: transparent;
    background: transparent;
    opacity: 0;
    cursor: pointer;
  }
`;

interface Props extends Omit<CommonProps, "leftIcon"> {
  value: Date | null;
  setValue: (value: Date | null) => void;
  min?: Date;
  max?: Date;
}

export const DateInput = ({
  value,
  setValue,
  title,
  isRequired,
  isDisabled,
  isBorderless,
  isFocusedAutomatically,
  error: _error,
  placeholder,
  width = "100%",
  min,
  max,
}: Props) => {
  const refInput = useRef<HTMLInputElement>(null);
  const { isHovered, isFocused } = useHoverClickable(refInput);

  const error = _error ?? (isRequired && value === null && `${title} needs to be set`);

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target.valueAsDate;
    setValue(date);
  };

  const getInputDate = (date?: Date) => {
    if (!date) return undefined;

    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  };

  return (
    <InputOuterContainer width={width}>
      <InputContainer
        width={width}
        isHovered={isHovered}
        isFocused={isFocused}
        isDisabled={isDisabled}
        isBorderless={isBorderless}
      >
        <InputTitle
          isActive={isFocused}
          isRequired={isRequired}
          isDisabled={isDisabled}
          error={error}
          label={title}
        />

        <InputIcon
          side="left"
          icon={{
            name: "date",
          }}
          error={Boolean(error)}
        />

        <DatePicker
          type="date"
          onChange={onDateChange}
          min={getInputDate(min)}
          max={getInputDate(max)}
          ref={refInput}
        />

        <InnerInput
          type="text"
          value={value?.toLocaleDateString() || ""}
          setValue={() => {}}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isFocusedAutomatically={isFocusedAutomatically}
          paddingLeft
        />
      </InputContainer>

      <InputError error={error} />
    </InputOuterContainer>
  );
};
