import styled from "styled-components";
import { Flex } from "../Box/Box";
import { Color } from "../Color/Color";
import { Text } from "../Text/Text";

const TagElement = styled(Flex)`
  padding: 3px 5px;
  background-color: ${Color.lightGray};
  border: 1px solid ${Color.lightGrayHover};
  border-radius: 10px;
  gap: 5px;
  align-items: center;
`;

interface Props {
  label: string;
  value: string | number;
}

export const Tag = ({ label, value }: Props) => (
  <TagElement>
    <Text variant="caption3" bold color={Color.textSecondary}>
      {label.replaceAll(":", "")}:
    </Text>

    <Text variant="small1" color={Color.textSecondary}>
      {value}
    </Text>
  </TagElement>
);
