import { SubgridBodyColumn, SubgridColumn } from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractSubgridTableBodyColumn = <T>(column: SubgridColumn<T>): SubgridBodyColumn<any>[] => {
  let bodyColumns: SubgridBodyColumn<T>[] = [];

  if (!column.subColumns) {
    bodyColumns = [column];
  }

  if (column.subColumns) {
    bodyColumns = column.subColumns.reduce((previousBodyColumns, subHeader) => {
      return [...previousBodyColumns, ...extractSubgridTableBodyColumn(subHeader)];
    }, [] as SubgridBodyColumn<T>[]);
  }

  return bodyColumns;
};

export const reduceSubgridTableColumnsToBodyColumns = <T>(
  columns: SubgridColumn<T>[],
): SubgridBodyColumn<T>[] => {
  return columns.reduce((previousBodyColumns, column) => {
    return [...previousBodyColumns, ...extractSubgridTableBodyColumn(column)];
  }, [] as SubgridBodyColumn<T>[]);
};
