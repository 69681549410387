import { OptionalPaginationQueryParams, Pagination } from "@CommonTypes/backend/pagination";
import { OneStoreView } from "@CommonTypes/merchflow/OneStoreView";

export const RouteGetMerchflows_merchflowId_StoresAll = "/merchflows/:merchflowId/stores/all";

export type ParamsGetMerchflows_merchflowId_StoresAll = OptionalPaginationQueryParams & {
  store_pog_trigger_id?: number;
};

export type ResponseGetMerchflows_merchflowId_StoresAll = Pagination<OneStoreView> & {
  project_id: number | null;
};
