import { useSetAtom } from "jotai";
import { useModals } from "src/components/Modals";
import { importStoreSummaryModalDataAtom } from "./atoms";
import { Category } from "@CommonApi/categories/list";

export const useImportStoreSummaryModal = () => {
  const { openModal } = useModals();
  const setImportStoreSummaryData = useSetAtom(importStoreSummaryModalDataAtom);

  const openImportStoreSummaryModal = (category: Category) => {
    setImportStoreSummaryData(category);
    openModal("ModalImportStoreSummary");
  };

  return { openImportStoreSummaryModal };
};
