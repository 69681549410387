import { useEffect, useRef } from "react";
import { useApi } from "src/api";
import { getLoginToken, setLoginToken } from "src/utils";

export const TokenRenewer = () => {
  const { getRenewTokenApi } = useApi();
  const refTokenRenewer = useRef<null | NodeJS.Timer>(null);

  useEffect(() => {
    if (!refTokenRenewer.current) {
      renewToken();

      refTokenRenewer.current = setInterval(
        () => {
          renewToken();
        },
        30 * 60 * 1000,
      );
    }
  }, []);

  const renewToken = async () => {
    if (!getLoginToken()) return;

    const response = await getRenewTokenApi();

    if (response) {
      setLoginToken(response.token);
    }
  };

  return null;
};
