import { PrimitiveAtom, useAtom } from "jotai";
import { useRef } from "react";

import { InnerInput } from "./components/InnerInput";
import { InputContainer } from "./components/InputContainer";
import { InputError } from "./components/InputError";
import { InputIcon } from "./components/InputIcon";
import { InputOuterContainer } from "./components/InputOuterContainer";
import { InputTitle } from "./components/InputTitle";
import { NumberArrows } from "./components/NumberArrows";
import { CommonProps } from "./store/types";
import { useHoverClickable } from "src/utils/hoverClickable";

interface Props extends CommonProps {
  value: number;
  setValue: (value: number) => void;
  min?: number;
  max?: number;
}

interface StateProps extends CommonProps {
  state: PrimitiveAtom<number>;
}

export const NumberInput = ({
  value,
  setValue,
  title,
  isRequired = false,
  isDisabled = false,
  isBorderless = false,
  isFocusedAutomatically = false,
  error = false,
  placeholder,
  width = "100%",
  min,
  max,
  leftIcon,
  refInput: refInputForwarded,
}: Props) => {
  const refInput = useRef<HTMLInputElement>(null);
  const { isHovered, isFocused } = useHoverClickable(refInput);

  return (
    <InputOuterContainer width={width}>
      <InputContainer
        width={width}
        isHovered={isHovered}
        isFocused={isFocused}
        isDisabled={isDisabled}
        isBorderless={isBorderless}
      >
        <InputTitle
          isActive={isFocused}
          isRequired={isRequired}
          isDisabled={isDisabled}
          error={error}
          label={title}
        />

        <InputIcon side="left" icon={leftIcon} />

        <InnerInput
          refInput={refInputForwarded || refInput}
          type="number"
          value={value}
          setValue={setValue}
          placeholder={placeholder}
          min={min}
          max={max}
          isDisabled={isDisabled}
          isFocusedAutomatically={isFocusedAutomatically}
          paddingLeft={Boolean(leftIcon)}
          paddingRight
        />

        <NumberArrows
          value={value}
          setValue={setValue}
          isDisabled={isDisabled}
          min={min}
          max={max}
        />
      </InputContainer>

      <InputError error={error} />
    </InputOuterContainer>
  );
};

export const StateNumberInput = ({
  state,
  title,
  placeholder,
  width,
  isRequired,
  isDisabled,
  leftIcon,
}: StateProps) => {
  const [value, setValue] = useAtom(state);

  return (
    <NumberInput
      title={title}
      placeholder={placeholder}
      width={width}
      isRequired={isRequired}
      isDisabled={isDisabled}
      leftIcon={leftIcon}
      value={value}
      setValue={setValue}
    />
  );
};
