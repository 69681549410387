import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import { PlanogramShelf } from "@CommonTypes/merchflow/pog/shelf";
import { Flex } from "src/elements";
import styled from "styled-components";

const DropperElement = styled(Flex)<{
  width: string;
  height: string;
  bottom: string;
  side: DropperSide;
  shelfType: PlanogramShelf["type"];
}>`
  flex-grow: ${({ side }) => side === "static" && "1"};
  position: ${({ side }) => side !== "static" && "absolute"};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  left: ${({ side }) => side !== "right" && "0"};
  right: ${({ side }) => side === "right" && "0"};
  bottom: ${({ shelfType, bottom }) => shelfType !== "HANGCELL" && bottom};
  top: ${({ shelfType, bottom }) => shelfType === "HANGCELL" && bottom};
  pointer-events: none;
`;

type DropperSide = "left" | "right" | "static";

interface Props {
  side: DropperSide;
  width: string;
  height: string;
  bottom?: string;
  shelfType?: PlanogramShelf["type"];
  onDropOver: () => void;
  onDrop: (item: PlanogramItem) => void;
}

export const Dropper = ({
  side,
  width,
  height,
  bottom = "0",
  shelfType = "REGULAR",
  onDropOver,
}: Props) => {
  return (
    <DropperElement
      className="item-dropper"
      side={side}
      width={width}
      height={height}
      bottom={bottom}
      shelfType={shelfType}
      onPointerEnter={onDropOver}
    />
  );
};
