import { Flex, Radios } from "src/elements";
import styled from "styled-components";
import { SectionHeader } from "./SectionHeader";
import { FiltersSidebarFilters } from "../store/types";

const SectionElement = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;

export interface FilterWithRadios {
  id: string;
  title: string;
  options: string[];
  isExpanded: boolean;
  value: string | null;
}

interface Props<T> {
  id: string;
  title: string;
  filters: FiltersSidebarFilters<T>;
  setFilters: (filters: FiltersSidebarFilters<T>) => void;
}

export const RadiosSection = <T extends object>({ id, title, filters, setFilters }: Props<T>) => {
  const filtersSection = filters.find((filter) => filter.id === id)!;

  const setIsExpanded = (isExpanded: boolean) => {
    setFilters(
      filters.map((filterSection) =>
        filterSection.id === id ? { ...filterSection, isExpanded } : filterSection,
      ),
    );
  };

  const selectOption = (value: string) => {
    setFilters(
      filters.map((section) =>
        section.style === "buttons" ? section : section.id === id ? { ...section, value } : section,
      ),
    );
  };

  return (
    <SectionElement>
      <SectionHeader
        title={title}
        isExpanded={filtersSection.isExpanded || false}
        setIsExpanded={setIsExpanded}
      />

      {filtersSection.isExpanded && (
        <Flex column padding="0 5px">
          <Radios
            options={filtersSection.filters}
            value={filtersSection.value}
            setValue={(value: string) => {
              selectOption(value);
            }}
            direction="column"
            isUnselectable
          />
        </Flex>
      )}
    </SectionElement>
  );
};
