import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Button, Color, Flex, Scroller, Search, SubgridTable, Text } from "src/elements";
import styled from "styled-components";
import { MerchflowReviewUpdateStep, ProductChange } from "../store/types";
import { useInlineLoaders } from "src/components/InlineLoader";
import { PageStatus } from "src/components";
import { Radio } from "src/elements/Radios/components/Radio";
import {
  FlowUpdateProduct,
  RouteGetMerchflow_merchflowId_FlowUpdateProducts,
} from "@CommonApi/merchflow/_merchflowId_/flow-update/products";
import { filterProducts } from "../store/utils";

const Label = styled.div`
  background: ${Color.primaryActive};
  color: ${Color.white};
  padding: 8px;
`;
const TextBox = styled.div`
  border: 1px solid ${Color.spaceGray};
  min-height: 36px;
  padding: 8px;
`;

interface Props {
  addableProducts: FlowUpdateProduct[];
  removableProducts: FlowUpdateProduct[];
  setStep: (step: MerchflowReviewUpdateStep) => void;
  setProductChange: Dispatch<SetStateAction<ProductChange[]>>;
}

export const Step3ASwapProducts = ({
  addableProducts,
  removableProducts,
  setStep,
  setProductChange,
}: Props) => {
  const { isInlineLoading } = useInlineLoaders();

  const [productsToSwap, setProductsToSwap] = useState<{
    productToAdd: string | null;
    productToRemove: string | null;
  }>({
    productToAdd: null,
    productToRemove: null,
  });
  const [searchToAdd, setSearchToAdd] = useState("");
  const [searchToRemove, setSearchToRemove] = useState("");

  const isProductsLoading = isInlineLoading(RouteGetMerchflow_merchflowId_FlowUpdateProducts);
  const isModified =
    productsToSwap.productToAdd !== null || productsToSwap.productToRemove !== null;

  const filteredAddableProducts = useMemo(
    () => filterProducts(addableProducts, searchToAdd),
    [addableProducts, searchToAdd],
  );

  const filteredRemovableProducts = useMemo(
    () => filterProducts(removableProducts, searchToRemove),
    [removableProducts, searchToRemove],
  );

  const onSave = () => {
    if (!productsToSwap.productToAdd || !productsToSwap.productToRemove) return;

    setProductChange((s) =>
      s.concat({
        productToAdd: addableProducts.find(
          (product) => product.product_code === productsToSwap.productToAdd,
        )!,
        productToRemove: removableProducts.find(
          (product) => product.product_code === productsToSwap.productToRemove,
        )!,
      }),
    );
    setStep("STEP_3_PRODUCT_REVIEW");
  };

  return (
    <Flex column flexGrow gap="20px">
      <Flex column flexGrow gap="20px">
        <Text color={Color.primary} variant="h5">
          Please select an available product to tbe added and the product you would like it to
          replace
        </Text>

        {isProductsLoading ? (
          <PageStatus icon="spinner" label="Loading available products..." />
        ) : (
          <Flex flexGrow column gap="10px">
            <Flex justify="between">
              <Search
                width="260px"
                search={searchToRemove}
                setSearch={setSearchToRemove}
                placeholder="Search for removable products"
              />

              <Search
                width="260px"
                search={searchToAdd}
                setSearch={setSearchToAdd}
                placeholder="Search for addable products"
              />
            </Flex>

            <Flex flexGrow gap="4px">
              <Scroller>
                <SubgridTable
                  columns={[
                    {
                      header: "",
                      id: "selected",
                      width: "40px",
                      justify: "center",
                    },
                    {
                      header: "Removables",
                      id: "product_code",
                      renderer: (data) => `${data.row.product_code} - ${data.row.name}`,
                    },
                  ]}
                  data={filteredRemovableProducts.map((item) => ({
                    ...item,
                    selected: (
                      <Radio
                        value={item.product_code}
                        isChecked={item.product_code === productsToSwap.productToRemove}
                        setIsChecked={(productCode) => {
                          setProductsToSwap((productsToSwap) => ({
                            ...productsToSwap,
                            productToRemove: productCode,
                          }));
                        }}
                      />
                    ),
                  }))}
                />
              </Scroller>

              <Flex column width="500px" gap="20px">
                <Flex column gap="5px">
                  <Label>OUT</Label>
                  <TextBox>{productsToSwap.productToRemove || ""}</TextBox>
                </Flex>

                <Flex column gap="5px">
                  <Label>IN</Label>
                  <TextBox>{productsToSwap.productToAdd || ""}</TextBox>
                </Flex>
              </Flex>

              <Scroller gutter="stable both-edges">
                <SubgridTable
                  columns={[
                    {
                      header: "",
                      id: "selected",
                      width: "40px",
                      justify: "center",
                    },
                    {
                      header: "Addables",
                      id: "product_code",
                      renderer: (data) => `${data.row.product_code} - ${data.row.name}`,
                    },
                  ]}
                  data={filteredAddableProducts.map((item) => ({
                    ...item,
                    selected: (
                      <Radio
                        value={item.product_code}
                        isChecked={productsToSwap.productToAdd === item.product_code}
                        setIsChecked={(productCode) => {
                          setProductsToSwap((productsToSwap) => ({
                            ...productsToSwap,
                            productToAdd: productCode,
                          }));
                        }}
                      />
                    ),
                  }))}
                />
              </Scroller>
            </Flex>
          </Flex>
        )}
      </Flex>

      <Flex justify="between" gap="10px">
        <Button
          color={isModified ? "red" : "greenSmoke"}
          variant="inverted"
          onClick={() => setStep("STEP_3_PRODUCT_REVIEW")}
        >
          {isModified ? "Cancel" : "Back"}
        </Button>

        <Button
          isDisabled={!productsToSwap.productToAdd || !productsToSwap.productToRemove}
          onClick={onSave}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};
