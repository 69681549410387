import { Color } from "src/elements/Color/Color";

export const getProgressColor = (percent?: number | null) => {
  return (
    (percent && percent >= 80 && Color.primary) ||
    (percent && percent >= 50 && Color.yellow) ||
    (percent && Color.red) ||
    Color.textDisabled
  );
};
