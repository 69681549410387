import { useSetAtom } from "jotai";
import { merchflowNotesModalAtom } from "./atoms";
import { useModals } from "src/components/Modals";
import { useEffect } from "react";

export const useMerchflowNotesModal = () => {
  const { openModal } = useModals();

  const setData = useSetAtom(merchflowNotesModalAtom);

  useEffect(() => {
    setData(null);
  }, []);

  const openMerchflowNotesModal = (data: {
    merchflowId: number;
    notes: string;
    onSave?: (notes: string) => void;
  }) => {
    setData(data);
    openModal("MerchflowNotesModal");
  };

  return { openMerchflowNotesModal };
};
