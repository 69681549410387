import { useSetAtom } from "jotai";
import { filtersSidebarIsVisibleAtom } from "./atoms";

export const useFiltersSidebar = () => {
  const setIsVisible = useSetAtom(filtersSidebarIsVisibleAtom);

  const openFilters = () => {
    setIsVisible(true);
  };

  return { openFilters };
};
