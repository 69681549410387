import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import { omniAtom } from "src/utils/atoms";

const prefix = "modals/deleteProduct";

export const pogDeleteProductDataAtom = omniAtom({
  prefix,
  key: "data",
  value: null as {
    item: PlanogramItem;
    availableStores: ResponseMerchFlowApplyFilters["filter_config"];
  } | null,
});
