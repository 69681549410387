import { useSetAtom } from "jotai";
import { formatUnknown } from "src/omni-common/utils/formatUnknown";

import { toastsAtom } from "./atoms";
import { TOAST_DEFAULT_DURATION, TOAST_DEFAULT_TYPE } from "./constants";
import { ToastAction, ToastDuration, ToastType } from "./types";
import { v4 as uuid } from "uuid";
import get from "lodash/get";

export const useToasts = () => {
  const setToasts = useSetAtom(toastsAtom);

  const toast = (toast: {
    title: string;
    message?: string;
    error?: unknown;
    duration?: ToastDuration;
    type?: ToastType;
    actions?: ToastAction[];
  }) => {
    const extractedError = extractError({
      error: toast.error,
      type: toast.type,
      message: toast.message,
    });

    if (extractedError !== null) {
      console.warn(`${toast.title}:`, extractedError);
    }

    const id = uuid();
    const message =
      (typeof toast.error === "object" &&
        get(toast.error, "response.status") === 404 &&
        "API endpoint not found") ||
      (typeof extractedError === "object" &&
        extractedError !== null &&
        get(extractedError, "message")) ||
      // String error.
      (typeof extractedError === "string" && extractedError) ||
      // Object error.
      (extractedError !== null && JSON.stringify(extractedError)) ||
      // Actual Message.
      toast.message ||
      // Empty message.
      "";

    setToasts((toasts) => [
      ...toasts,
      {
        id,
        title: toast.title,
        message,
        duration: toast.duration || (extractedError !== null && 60_000) || TOAST_DEFAULT_DURATION,
        type: toast.type || (extractedError !== null && "error") || TOAST_DEFAULT_TYPE,
        state: "open",
        height: 0,
        actions: toast.actions || [],
      },
    ]);

    return id;
  };

  const closeToast = (id: string) => {
    setToasts((toasts) => {
      return toasts.map((toast) => {
        if (toast.id === id && toast.state === "visible") {
          return { ...toast, state: "close" };
        }

        return toast;
      });
    });
  };

  const extractError = ({
    error,
    type,
    message,
  }: {
    error?: unknown;
    type?: ToastType;
    message?: string;
  }) => {
    // Network error.
    if (get(error, "response.data")) {
      return get(error, "response.data");
    }

    // Other error.
    if (error !== undefined) {
      return JSON.stringify(formatUnknown(error));
    }

    // Message as error.
    if (type === "error" && message !== undefined) {
      return message;
    }

    return null;
  };

  return { toast, closeToast };
};
