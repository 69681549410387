import { zIndexLevel } from "src/utils/zIndexLevels";
import { Text } from "../Text/Text";
import { Flex } from "../Box/Box";
import { Color } from "../Color/Color";
import { Icon } from "../Icon/Icon";

interface Props {
  size?: "default" | "medium" | "big";
  label?: string;
  color?: string;
  isCentered?: boolean;
  isFullscreen?: boolean;
}

export const Spinner = ({ size = "default", label, color, isCentered, isFullscreen }: Props) => (
  <Flex
    width={isCentered || isFullscreen ? "100%" : "min-content"}
    height={isCentered || isFullscreen ? "100%" : "min-content"}
    justify="center"
    align="center"
    gap="10px"
    column
    position={isFullscreen ? "fixed" : "static"}
    background={isFullscreen ? "rgba(255,255,255,0.7)" : undefined}
    top={isFullscreen ? "0" : undefined}
    left={isFullscreen ? "0" : undefined}
    zIndex={isFullscreen ? zIndexLevel.FULLSCREEN_LOADER : undefined}
  >
    <Icon
      name="spinner"
      size={((size === "big" || isFullscreen) && 40) || (size === "medium" && 32) || 18}
      color={color}
    />

    {label && (
      <Text variant="h3" color={Color.spaceGray}>
        {label}
      </Text>
    )}
  </Flex>
);
