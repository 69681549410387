import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useMemo, useRef } from "react";
import { useMenu } from "src/components/Menu";

import { PlanogramItem } from "src/omni-common/types/merchflow/pog/pog";
import { getShelfHeight } from "src/omni-common/utils/pog/shelf";
import { Transition } from "src/utils";
import { Key } from "src/utils/keys";
import styled from "styled-components";

import { usePOG } from "../../store/actions";
import { baseHighlightsAtom, highlightsModeAtom } from "../../store/atoms";
import { OnItemHover, PogAtom } from "../../store/types";
import { Dropper } from "./Dropper";
import { Facing } from "./Facing";
import { getHighlightColor } from "./store/highlight";
import { onDragStart, onDropOverToItem } from "./store/utils";
import { usePogNewProductModal } from "src/modals/PogNewProduct/store/hooks";
import { usePogDeleteProductModal } from "src/modals/PogDeleteProduct/store/hooks";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import {
  planogramHoveredItemAtom,
  planogramSelectedItemAtom,
  planogramSidepanelTabAtom,
} from "src/components/PlanogramExtensions/store/atoms";
import { Color, Flex } from "src/elements";
import { PlanogramSidepanelTab } from "src/components/PlanogramExtensions/store/types";
import { PlanogramShelf } from "@CommonTypes/merchflow/pog/shelf";

const outlineThickness = "2px";

const ItemContainer = styled(Flex)<{ shelfType?: PlanogramShelf["type"] }>`
  align-items: ${({ shelfType }) => (shelfType === "HANGCELL" ? "flex-start" : "flex-end")};
  height: min-content;
  flex-grow: 1;

  &[data-placeholder] .planogram-item::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: ${Color.greenSmoke};
    border: 1px dashed ${Color.greenSmokeActive};
    border-radius: 2px;
  }

  &[data-draggable] {
    position: fixed;
    left: var(--xLeft);
    top: var(--yTop);

    &,
    * {
      pointer-events: none !important;
    }
  }
`;

const DropperContainer = styled(Flex)<{ shelfType?: PlanogramShelf["type"] }>`
  height: 0;
  flex-grow: 1;
  align-items: ${({ shelfType }) => (shelfType === "HANGCELL" ? "flex-start" : "flex-end")};
`;

const ItemsElement = styled.div<{
  item: PlanogramItem;
  isEditable: boolean;
  isNotHighlighted: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ item }) => `repeat(${item.facings / item.facingsRows}, auto)`};
  grid-template-rows: ${({ item }) => `repeat(${item.facingsRows}, auto)`};
  position: relative;
  grid-auto-flow: column;
  user-select: none;
  cursor: ${({ isEditable }) => (isEditable && "grab") || "default"};
  box-sizing: border-box;
  align-self: flex-end;
  pointer-events: ${({ isEditable }) => isEditable && "all"};
  border-radius: 3px;
  opacity: ${({ isNotHighlighted }) => isNotHighlighted && "0.5"};
`;

const ItemOutliner = styled(Flex)`
  position: absolute;
  width: calc(100% + 2 * ${outlineThickness});
  height: calc(100% + 2 * ${outlineThickness});
  top: -${outlineThickness};
  left: -${outlineThickness};
  border-radius: 3px;
  pointer-events: none;
  z-index: 1;

  background-image: linear-gradient(90deg, black 50%, white 50%),
    linear-gradient(90deg, black 50%, white 50%), linear-gradient(0deg, black 50%, white 50%),
    linear-gradient(0deg, black 50%, white 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size:
    15px ${outlineThickness},
    15px ${outlineThickness},
    ${outlineThickness} 15px,
    ${outlineThickness} 15px;
  background-position:
    left top,
    right bottom,
    left bottom,
    right top;
  animation: border-dance 1s infinite linear;

  @keyframes border-dance {
    0% {
      background-position:
        left top,
        right bottom,
        left bottom,
        right top;
    }

    100% {
      background-position:
        left 15px top,
        right 15px bottom,
        left bottom 15px,
        right top 15px;
    }
  }
`;

const Tray = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: #808080;
  border: 1px solid #4c4c4c;
`;

const Highlighter = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: yellow;
  border-radius: 3px;
  animation: pulsate infinite ${Transition.turtle};

  @keyframes pulsate {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.4;
    }
  }
`;

const Highlight = styled.div<{ highlightColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ highlightColor }) => highlightColor};
  opacity: 0.5;
`;

interface Props {
  currentStoreCode?: string;
  item: PlanogramItem;
  scaleX: number;
  scaleY: number;
  isHighlighted?: boolean;
  isScrolledToView?: boolean;
  isEditable: boolean;
  pogAtom: PogAtom;
  refItemContainer?: React.RefObject<HTMLDivElement>;
  onItemHover?: OnItemHover;
  setIsModified: (modified: boolean) => void;
  isDuplicateDisabled?: boolean;
  availableStores: ResponseMerchFlowApplyFilters["filter_config"] | null;
}

export const Item = ({
  currentStoreCode,
  item,
  scaleX,
  scaleY,
  isEditable,
  isHighlighted,
  isScrolledToView,
  pogAtom,
  refItemContainer: forwardedRefItemContainer,
  setIsModified,
  isDuplicateDisabled,
  availableStores,
}: Props) => {
  const pog = useAtomValue(pogAtom);
  const { dropItemNextToAnotherItem } = usePOG(pogAtom);
  const { openPogDeleteProductModal } = usePogDeleteProductModal();
  const { openPogNewProductModal } = usePogNewProductModal();
  const { openMenu } = useMenu();

  const setHoveredItem = useSetAtom(planogramHoveredItemAtom);
  const [highlightsMode] = useAtom(highlightsModeAtom);
  const [baseHighlights] = useAtom(baseHighlightsAtom);
  const [selectedItem, setSelectedItem] = useAtom(planogramSelectedItemAtom);
  const setSidepanelTab = useSetAtom(planogramSidepanelTabAtom);

  const refItemContainer = useRef<HTMLDivElement>(null);
  const refItem = useRef<HTMLDivElement>(null);

  const shelfHeight = item.shelf ? getShelfHeight(item.shelf) : 0;

  const highlight = useMemo(() => {
    if (!highlightsMode) return false;

    if (baseHighlights) {
      for (const highlight of baseHighlights.filter((highlight) => highlight.enabled)) {
        const highlightColor = getHighlightColor(highlight, item, pog);
        if (highlightColor) {
          return highlightColor;
        }
      }
    }

    return false;
  }, [highlightsMode, baseHighlights]);

  const getRef = () => forwardedRefItemContainer || refItemContainer;

  useEffect(() => {
    if (isHighlighted && isScrolledToView) {
      getRef().current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [isHighlighted, isScrolledToView]);

  const onPointerDown = (event: React.MouseEvent<HTMLDivElement>) => {
    onDragStart({
      event,
      refItemContainer: getRef(),
      refItem,
      item,
      setSidepanelTab,
      editItem,
    });
  };

  const onDropOverLeft = () => {
    setIsModified(true);
    onDropOverToItem({
      refItemContainer: getRef(),
      side: "left",
      onDrop: onDropLeft,
    });
  };

  const onDropOverRight = () => {
    setIsModified(true);
    onDropOverToItem({
      refItemContainer: getRef(),
      side: "right",
      onDrop: onDropRight,
    });
  };

  const onDropLeft = (droppedItem: PlanogramItem) => {
    setIsModified(true);
    dropItemNextToAnotherItem({
      uniqueId: item.uniqueId,
      side: "left",
      item: droppedItem,
    });
  };

  const onDropRight = (droppedItem: PlanogramItem) => {
    setIsModified(true);
    dropItemNextToAnotherItem({
      uniqueId: item.uniqueId,
      side: "right",
      item: droppedItem,
    });
  };

  const editItem = () => {
    setSelectedItem(item);
    setSidepanelTab(PlanogramSidepanelTab.PRODUCT_DETAILS);
  };

  const onPointerEnter = () => {
    if (item.shelf) {
      setHoveredItem(item);
    }
  };

  const onPointerLeave = () => {
    setHoveredItem(null);
  };

  const onItemMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    openMenu({
      event,
      menu: {
        title: item.productCode,
        options: [
          ...(availableStores
            ? [
                {
                  label: "Delete",
                  keybind: { key: Key.DELETE },
                  onClick: () => {
                    openPogDeleteProductModal({
                      item,
                      availableStores,
                    });
                  },
                },
                ...(currentStoreCode && !isDuplicateDisabled
                  ? [
                      {
                        label: "Duplicate",
                        keybind: {
                          key: Key.D,
                        },
                        onClick: () => {
                          openPogNewProductModal({
                            availableStores,
                            currentStoreCode,
                            planogramItem: item,
                            pog,
                          });
                        },
                      },
                    ]
                  : []),
              ]
            : []),
        ],
      },
    });
  };

  return (
    <ItemContainer ref={getRef()} shelfType={item.shelf?.type} onContextMenu={onItemMenu}>
      <DropperContainer shelfType={item.shelf?.type}>
        <Dropper
          side="static"
          width="auto"
          height={`calc(${shelfHeight}px * ${scaleY})`}
          onDropOver={onDropOverLeft}
          onDrop={onDropLeft}
          shelfType={item.shelf?.type}
        />
      </DropperContainer>

      <ItemsElement
        ref={refItem}
        className="planogram-item"
        data-id={item.uniqueId}
        item={item}
        draggable={false}
        onPointerDown={onPointerDown}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        isEditable={isEditable}
        isNotHighlighted={highlightsMode && !highlight}
      >
        {item === selectedItem && <ItemOutliner />}

        {isHighlighted && <Highlighter />}

        {Array.from(Array(item.facings).keys()).map((facing) => (
          <Facing key={facing} item={item} scaleX={scaleX} scaleY={scaleY} />
        ))}

        {item.merchandisingStyle === "TRAY" && <Tray />}

        <Dropper
          side="left"
          width="50%"
          height={`calc(${shelfHeight}px * ${scaleY})`}
          onDropOver={onDropOverLeft}
          onDrop={onDropLeft}
          shelfType={item.shelf?.type}
        />

        <Dropper
          side="right"
          width="50%"
          height={`calc(${shelfHeight}px * ${scaleY})`}
          onDropOver={onDropOverRight}
          onDrop={onDropRight}
          shelfType={item.shelf?.type}
        />

        {highlight && <Highlight highlightColor={highlight} />}
      </ItemsElement>
    </ItemContainer>
  );
};
