import { omniAtom } from "src/utils/atoms";
import { EditableHighlight } from "./types";

const prefix = "modals/highlight";

export const highlightModalDataAtom = omniAtom({
  prefix,
  key: "data",
  value: null as null | EditableHighlight,
});

export const savedHighlightAtom = omniAtom({
  prefix,
  key: "savedHighlight",
  value: null as null | EditableHighlight,
});
