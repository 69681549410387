import { S2SolutionDb } from "@CommonApi/merchflow/_merchflowId_/s2/template/_templateId_";
import { PogNonCircular } from "./pog/pog";

export interface OneStoreView {
  store: string;
  template_id: string;
  status: OneStoreViewStatus;
  filter_config_id?: number;
  project_id?: number | null;
  s2_solution?: S2SolutionDb;
  base_pog?: {
    base_pog_id: number;
    base_pog_data: PogNonCircular;
  };
  store_pog?: {
    store_pog_id: number;
    store_pog_data: PogNonCircular;
    store_pog_trigger_id: number;
    thumbnail: OneStoreViewThumbnail;
  };
  store_pog_amount?: number;
}

export type OneStoreViewThumbnail =
  | {
      status: "IN_PROGRESS" | "ERROR" | "NOT_STARTED";
      url?: never;
    }
  | {
      status: "DONE";
      url: string;
    };

export enum OneStoreViewStatus {
  NONE = "NONE",
  S2 = "S2",
  BASE_POG = "BASE_POG",
  NO_STORE_POGS_APPROVED = "NO_STORE_POGS_APPROVED",
  ONE_STORE_POG_APPROVED = "ONE_STORE_POG_APPROVED",
  MULTIPLE_STORE_POGS_APPROVED = "MULTIPLE_STORE_POGS_APPROVED",
  GENERATING_STORE_POG = "GENERATING_STORE_POG",
}
