import React from "react";
import { Flex } from "src/elements/Box/Box";

interface Props {
  width: string;
  children: React.ReactNode;
}

export const InputOuterContainer = ({ width, children }: Props) => (
  <Flex direction="column" gap="3px" width={width}>
    {children}
  </Flex>
);
