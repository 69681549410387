import { useAtomValue } from "jotai";
import ReactJson from "react-json-view";

import { dynamicLogAtom } from "./store/atom";
import { Flex, Modal } from "src/elements";

export const DynamicLog = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const dynamicLog = useAtomValue(dynamicLogAtom);

  return (
    <Modal name="DynamicLog" title="Logs">
      <Flex column gap="20px" flexGrow>
        <Flex direction="column" gap="20px" flexGrow>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
          <ReactJson src={dynamicLog} collapsed={1} />
        </Flex>
      </Flex>
    </Modal>
  );
};
