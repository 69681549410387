import { useAtom, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { useModals } from "src/components/Modals";
import { Button, Flex, InlineMessage, Modal, Scroller, Spinner } from "src/elements";
import { PlanogramItem, Pog } from "src/omni-common/types/merchflow/pog/pog";

import { validateNewProduct } from "./store/utils";
import { NewProductForm } from "./components/NewProductForm";
import { NewProductStores } from "./components/NewProductStores";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { StoreSelection } from "./store/types";
import { newProductModalDataAtom } from "./store/atoms";
import { POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM } from "./store/constants";
import { newProductAtom } from "src/components/Planogram/store/atoms";

export const ModalPogNewProduct = () => {
  const [data, setData] = useAtom(newProductModalDataAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  });

  if (!data) {
    return null;
  }

  return (
    <ModalPogNewProductInner
      currentStoreCode={data.currentStoreCode}
      pog={data.pog}
      planogramItem={data.planogramItem}
      availableStores={data.availableStores}
    />
  );
};

interface Props {
  currentStoreCode: string;
  planogramItem: PlanogramItem;
  pog: Pog;
  availableStores: ResponseMerchFlowApplyFilters["filter_config"];
}

const ModalPogNewProductInner = ({
  currentStoreCode,
  pog,
  planogramItem: _planogramItem,
  availableStores,
}: Props) => {
  const { closeModal: _closeModal } = useModals();

  const setNewProduct = useSetAtom(newProductAtom);

  const [error, setError] = useState<string | null>(null);
  const [planogramItem, setPlanogramItem] = useState(_planogramItem);
  const [step, setStep] = useState<"DETAILS" | "STORES">("DETAILS");

  const [filteredStores, setFilteredStores] = useState<
    ResponseMerchFlowApplyFilters["filter_config"]["stores"]
  >([]);
  const [storeSelection, setStoreSelection] = useState<StoreSelection>(StoreSelection.ALL_STORES);
  const [selectedFilters, setSelectedFilters] = useState<
    ResponseMerchFlowApplyFilters["filter_config"]
  >({
    baySizes: [],
    states: [],
    fixtures: {
      section_height: [],
      section_width: [],
    },
    clusters: [],
    stores: [],
  });

  const closeModal = () => {
    _closeModal("ModalPogNewProduct");
  };

  const continueToStoreSelection = () => {
    const validation = validateNewProduct(planogramItem);

    if (validation === true) {
      setStep("STORES");
      setError(null);
    } else {
      setError(validation);
    }
  };

  const onSaveProduct = () => {
    setNewProduct({
      ...planogramItem,
      unitDepth: planogramItem.unitDepth * POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
      unitHeight: planogramItem.unitHeight * POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
      unitWidth: planogramItem.unitWidth * POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
      trayDepth: planogramItem.trayDepth * POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
      trayHeight: planogramItem.trayHeight * POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
      trayWidth: planogramItem.trayWidth * POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
      caseDepth: planogramItem.caseDepth * POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
      caseHeight: planogramItem.caseHeight * POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
      caseWidth: planogramItem.caseWidth * POG_NEW_PRODUCT_DIMENSION_MULTIPLIER_TO_CM,
      newItemStoreCodes: affectedStores,
    });

    closeModal();
  };

  const affectedStores = useMemo(() => {
    // All stores.
    if (storeSelection === StoreSelection.ALL_STORES && availableStores) {
      return availableStores.stores.map((store) => store.store);
    }

    // Specific stores.
    if (storeSelection === StoreSelection.SPECIFIC_STORES) {
      return [currentStoreCode, ...filteredStores.map((store) => store.store)];
    }

    // Current store.
    return currentStoreCode ? [currentStoreCode] : [];
  }, [availableStores, storeSelection, filteredStores]);

  return (
    <Modal
      name="ModalPogNewProduct"
      title="New Product"
      width="500px"
      confirmModal={{
        title: "Delete Newly Created Product?",
        description: "This will delete currently inserted new product information!",
        buttons: [
          { label: "Keep Editing", color: "primary", variant: "inverted" },
          { label: "Delete New Product", color: "red", onClick: closeModal },
        ],
      }}
    >
      <Flex direction="column" gap="20px" padding="16px 3px 16px 16px" minHeight="0">
        <Scroller>
          {step === "DETAILS" && (
            <NewProductForm
              planogramItem={planogramItem}
              setPlanogramItem={setPlanogramItem}
              pog={pog}
            />
          )}

          {step === "STORES" && availableStores && (
            <NewProductStores
              currentStoreCode={currentStoreCode}
              availableStores={availableStores}
              filteredStores={filteredStores}
              setFilteredStores={setFilteredStores}
              storeSelection={storeSelection}
              setStoreSelection={setStoreSelection}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          )}

          {step === "STORES" && !availableStores && (
            <Flex justify="center">
              <Spinner />
            </Flex>
          )}
        </Scroller>

        <Flex column justify="center" gap="10px">
          {step === "DETAILS" && (
            <>
              {error && <InlineMessage status="error">{error}</InlineMessage>}

              <Button onClick={continueToStoreSelection} color={(error && "red") || "primary"}>
                Continue to Store Selection
              </Button>
            </>
          )}

          {step === "STORES" && (
            <Flex gap="10px">
              <Button variant="inverted" onClick={() => setStep("DETAILS")}>
                Back to Details
              </Button>

              <Button onClick={onSaveProduct} isDisabled={affectedStores.length === 0}>
                Save ({affectedStores.length}
                {affectedStores.length === 1 ? " store" : " stores"})
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
