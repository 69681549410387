import { useSetAtom } from "jotai";
import { basePlanogramRulesAtom, BasePlanogramRulesProps } from "./atoms";
import { useModals } from "src/components/Modals";

export const useBasePlanogramRulesModal = () => {
  const { openModal } = useModals();
  const setBasePlanogramRules = useSetAtom(basePlanogramRulesAtom);

  const openBasePlanogramRulesModal = (data: BasePlanogramRulesProps) => {
    setBasePlanogramRules(data);
    openModal("ModalBasePlanogramRules");
  };

  return { openBasePlanogramRulesModal };
};
