import { useAtomValue, useSetAtom } from "jotai";
import { settingsAtom } from "src/components/FeatureFlagProvider/store/atoms";
import { useModals } from "src/components/Modals";
import { useToasts } from "src/components/Toasts";
import { reportFiltersAtom, reportIdAtom } from "src/modals/PowerBiReportModal/store/atoms";
import { Filter } from "src/pages/merchandise/store/types";

export const usePowerBiReportModal = () => {
  const { toast } = useToasts();
  const { openModal } = useModals();

  const setFilters = useSetAtom(reportFiltersAtom);
  const setReportId = useSetAtom(reportIdAtom);
  const settings = useAtomValue(settingsAtom);

  const openBasePogReport = (filters: Filter[]) => {
    if (!settings?.base_pog_report_id) {
      return toast({
        title: "Report can't be opened",
        message: "Base pog report id missing",
        type: "error",
      });
    }

    setReportId(settings.base_pog_report_id);
    setFilters(filters);
    openModal("PowerBiReportModal");
  };

  const openAllStoresReport = ({
    filterConfigId,
    basePogId,
  }: {
    filterConfigId: number;
    basePogId: number;
  }) => {
    if (!settings?.store_pog_report_id) {
      return toast({
        title: "Report can't be opened",
        message: "Store pog report id missing",
        type: "error",
      });
    }

    setReportId(settings.store_pog_report_id);
    setFilters([
      {
        table: "gen_id_table",
        column: "filter_config_id",
        value: [filterConfigId],
      },
      {
        table: "base_pog_id",
        column: "base_pog_id",
        value: [basePogId],
      },
    ]);
    openModal("PowerBiReportModal");
  };

  const openOneStoreReport = (filters: Filter[]) => {
    if (!settings?.single_store_pog_report_id) {
      return toast({
        title: "Report can't be opened",
        message: "Single store pog report id missing",
        type: "error",
      });
    }

    setReportId(settings.single_store_pog_report_id);
    setFilters(filters);
    openModal("PowerBiReportModal");
  };

  const openCategoryRangeReviewReport = (projectId: number) => {
    if (!settings?.category_range_review_report_id) {
      return toast({
        title: "Report can't be opened",
        message: "Category range review report id missing",
        type: "error",
      });
    }

    setReportId(settings.category_range_review_report_id);
    setFilters([
      {
        table: "project_id",
        column: "project_id",
        value: [projectId],
      },
    ]);
    openModal("PowerBiReportModal");
  };

  return {
    openBasePogReport,
    openAllStoresReport,
    openOneStoreReport,
    openCategoryRangeReviewReport,
  };
};
