export const enum Key {
  UP = "ArrowUp",
  DOWN = "ArrowDown",
  LEFT = "ArrowLeft",
  RIGHT = "ArrowRight",
  DELETE = "Delete",
  ESCAPE = "Escape",
  CTRL = "Control",
  SHIFT = "Shift",
  ALT = "Alt",
  META = "Meta",
  N = "n",
  D = "d",
}
