import { useAtom } from "jotai";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { IReportEmbedConfiguration, models, service } from "powerbi-client";
import { useMemo } from "react";
import { useInlineLoaders } from "src/components/InlineLoader";
import { useToasts } from "src/components/Toasts";
import { powerBiReportsAtom } from "src/pages/merchandise/store/atoms";
import { Filter } from "src/pages/merchandise/store/types";

type EmbedReport = {
  reportId: string;
  reportName: string;
  embedUrl: string;
};

export type ReportData = {
  accessTokens: string;
  embedUrl: EmbedReport[];
  expiry: string;
};

type Props = {
  reportId: string;
  filters?: Filter[];
};

const convertFilter = (filters: Filter[]) => {
  return filters.map((f) => ({
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: f.table,
      column: f.column,
    },
    operator: "In",
    values: f.value,
  }));
};
export const usePowerBiReport = ({ reportId, filters }: Props) => {
  const { addInlineLoader, removeInlineLoader } = useInlineLoaders();
  const { toast } = useToasts();

  const [reportData] = useAtom(powerBiReportsAtom);

  const reportIndex =
    reportData?.embedUrl?.findIndex((report) => report.reportId === reportId) || 0;

  const reportConfig = useMemo(
    () =>
      _isEmpty(reportData)
        ? null
        : ({
            type: "report",
            embedUrl: _get(reportData, `embedUrl[${reportIndex}].embedUrl`, ""),
            tokenType: models.TokenType.Embed,
            accessToken: _get(reportData, "accessToken", ""),
            ...(filters ? { filters: convertFilter(filters) } : {}),
          } as IReportEmbedConfiguration),
    [reportData, filters],
  );

  const eventHandlersMap = new Map([
    [
      "loaded",
      () => {
        addInlineLoader("powerBiReportApi");
      },
    ],
    [
      "rendered",
      () => {
        removeInlineLoader("powerBiReportApi");
      },
    ],
    [
      "error",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          toast({ title: "Powerbi Report failed", error: event.detail });
        }
      },
    ],
  ]);

  return { reportConfig, eventHandlersMap };
};
