import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";

export const RouteGetMerchflows_merchflowId_BasePog_basePogId_ =
  "/merchflows/:merchflowId/base-pog/:basePogId";

export interface ResponseGetMerchflows_merchflowId_BasePog_basePogId_ {
  id: number;
  template_id: string | null;
  pog: PogNonCircular | null;
  favorited: boolean;
  merchflow_id: number;
  filter_config_id: number;
  s2_solution_id: number | null;
  store_id: number | null;
  store_code: string | null;
  status: "IN_PROGRESS" | "ERROR" | "DONE";
  error_logs: object;
}
