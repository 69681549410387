import { useAtomValue } from "jotai";
import { featureFlagsAtom } from "./atoms";
import { FeatureFlag } from "./types";

export const useIsFeatureFlagEnabled = (name: FeatureFlag) => {
  const featureFlags = useAtomValue(featureFlagsAtom);

  if (featureFlags === null) {
    return false;
  }

  return featureFlags[name] || false;
};
