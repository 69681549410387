import { Color } from "src/elements";
import { createGlobalStyle } from "styled-components";
import { Transition } from "./transitions";

export const GlobalStyle = createGlobalStyle`
  #root button.jotai-devtools-trigger-button {
    width: 70px !important;
    height: 44px !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    bottom: 64px !important;
    left: 10px !important;
    z-index: 99991 !important;
    transition: background-color ${Transition.hover};

    &::after {
      display: block;
      content: "DEV";
      color: ${Color.spaceGray};
      font-size: 9px;
    }

    img, span {
      display: none;
    }

    &:hover {
      background-color: ${Color.lightGray} !important;
    }
  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: 'Mulish', sans-serif;
    font-size: 15px; 
    background: #fdfdfd;
  }
  
  input,
  textarea,
  select,
  button {
    font-family: 'Mulish', sans-serif;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
  }
  input[type="number"]:hover,
  input[type="number"]:focus {
    appearance: textfield;
  }

  #versions tr:not(.last) :before {
    display: none;
  }

  body[data-dragging], body[data-resizing], body[data-dnd]  {
    #app * {
      user-select: none;
    }
  }

  body {
    &[data-dragging="move"] *, &[data-dnd] * {
      cursor: grabbing !important;
    }

    &[data-dragging="vertical"] {
      cursor: n-resize;
    }

    &[data-resizing="vertical"] {
      cursor: n-resize;
    }

    &[data-resizing="horizontal"] {
      cursor: e-resize;
    }

    &[data-resizing="top-right"] {
      cursor: ne-resize;
    }

    &[data-resizing="top-left"] {
      cursor: nw-resize;
    }

    &[data-resizing] * {
      pointer-events: none !important;
    }
  }

  // Disable planogram dropper pointer events if not doing dnd.
  body[data-dnd] .item-dropper {
    pointer-events: all;
  }
`;
