import { useModals } from "src/components/Modals";
import { EditableHighlight } from "./types";
import { useSetAtom } from "jotai";
import { highlightModalDataAtom } from "./atoms";

export const useHighlightModal = () => {
  const { openModal } = useModals();
  const setHighlightModalData = useSetAtom(highlightModalDataAtom);

  const openHighlightModal = (data: EditableHighlight) => {
    setHighlightModalData(data);
    openModal("ModalHighlight");
  };

  return { openHighlightModal };
};
