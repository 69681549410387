import { useRef } from "react";
import { Color, Flex, HorizontalDivider, Icon, Radios, Text } from "src/elements";
import { AnchorLayer } from "src/elements/AnchorLayer/AnchorLayer";
import { useAnchorLayer } from "src/elements/AnchorLayer/store/hooks";
import styled from "styled-components";

const FilterableElement = styled(Flex)`
  padding: 5px;
  background-color: ${Color.lightGray};
  align-items: center;
  gap: 5px;
  cursor: default;
`;

const SelectedFilter = styled(Flex)`
  padding: 3px 5px;
  background-color: ${Color.lightGrayHover};
  border-radius: 10px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: ${Color.greenSmoke};
  }

  &:active {
    background-color: ${Color.greenSmokeHover};
  }
`;

const Dropdown = styled(Flex)`
  width: 100%;
  flex-direction: column;
  background-color: ${Color.white};
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const ClearButton = styled(Flex)`
  &:hover .inner {
    fill: ${Color.greenSmoke};
  }

  &:active .inner {
    fill: ${Color.greenSmokeHover};
  }
`;

const SortButton = styled(Flex)`
  width: 18px;
  height: 18px;
  background-color: ${Color.white};
  border-radius: 3px;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${Color.greenSmoke};
  }

  &:active {
    background-color: ${Color.greenSmokeHover};
  }
`;

interface Props<T> {
  label: string;
  options: { label: string; value: T }[];
  value: T;
  setValue: (filter: T) => void;
  direction?: "ASCENDING" | "DESCENDING";
  setDirection?: (direction: "ASCENDING" | "DESCENDING") => void;
  clearable?: T;
}

export const Filterable = <T,>({
  label,
  options,
  value,
  setValue,
  direction,
  setDirection,
  clearable,
}: Props<T>) => {
  const refContainer = useRef(null);
  const refFilter = useRef(null);

  const { refLayer, isRendering, isVisible, layerState, hideAnchorLayer, isUsingClick } =
    useAnchorLayer({
      refTrigger: refFilter,
      refAnchor: refContainer,
      isUsingClick: true,
    });

  const toggleDirection = () => {
    if (direction && setDirection) {
      setDirection(direction === "ASCENDING" ? "DESCENDING" : "ASCENDING");
    }
  };

  return (
    <FilterableElement
      ref={refContainer}
      borderRadius={isVisible ? "10px 10px 0 0" : "10px"}
      boxShadow={isVisible ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : undefined}
    >
      <Text variant="small2" color={Color.textSecondary} whiteSpace="nowrap">
        {label.replaceAll(":", "")}:
      </Text>

      <SelectedFilter ref={refFilter}>
        <Text variant="small1" color={Color.textMain} whiteSpace="nowrap">
          {options.find((option) => option.value === value)!.label}
        </Text>
      </SelectedFilter>

      {clearable && (
        <ClearButton onClick={() => setValue(clearable)}>
          <Icon name="clearCircle" color={Color.white} colorInner={Color.primary} />
        </ClearButton>
      )}

      {direction !== undefined && (
        <SortButton onClick={toggleDirection}>
          <Icon
            name={direction === "ASCENDING" ? "arrowUp" : "arrowDown"}
            color={Color.primary}
            size={10}
          />
        </SortButton>
      )}

      <AnchorLayer
        refLayer={refLayer}
        isRendering={isRendering}
        isUsingParentWidth
        layerState={layerState}
        isUsingClick={isUsingClick}
        hideAnchorLayer={hideAnchorLayer}
      >
        <Dropdown>
          <HorizontalDivider />

          <Flex padding="5px">
            <Radios
              options={options}
              value={value}
              variant="small2"
              setValue={(value) => {
                setValue(value);
                hideAnchorLayer();
              }}
              direction="column"
            />
          </Flex>
        </Dropdown>
      </AnchorLayer>
    </FilterableElement>
  );
};
