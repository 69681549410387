import { Button, Flex, Scroller, SubgridTable } from "src/elements";
import { useInlineLoaders } from "src/components/InlineLoader";
import { RoutePostMerchflow_merchflowId_FlowUpdate } from "@CommonApi/merchflow/_merchflowId_/flow-update";
import { MerchflowReviewUpdateStep, ProductChange } from "../store/types";
import { productToReadableFormat } from "../store/utils";

interface Props {
  changedProducts: ProductChange[];
  setStep: (step: MerchflowReviewUpdateStep) => void;
}

export const Step3Overview = ({ changedProducts, setStep }: Props) => {
  const { isInlineLoading } = useInlineLoaders();

  const next = () => setStep("STEP_4_PLANOGRAM_RULES");

  return (
    <Flex width="100%" column>
      <Flex gap="20px" margin="20px 0" flexGrow>
        <Flex column gap="10px">
          <Button color="greenSmoke" size="big" onClick={() => setStep("STEP_3A_SWAP_PRODUCTS")}>
            Swap Products
          </Button>

          <Button color="greenSmoke" size="big" onClick={() => setStep("STEP_3B_REMOVE_PRODUCTS")}>
            Remove Products
          </Button>

          <Button color="greenSmoke" size="big" onClick={() => setStep("STEP_3C_ADD_PRODUCTS")}>
            Add Products
          </Button>
        </Flex>

        <Scroller maxHeight="300px">
          <SubgridTable
            columns={[
              {
                id: "index",
                header: "Product Change",
                width: "1fr",
              },
              {
                id: "type",
                header: "Type",
                width: "1fr",
                renderer: (data) =>
                  (data.row.productToAdd === undefined && "Remove") ||
                  (data.row.productToRemove === undefined && "Add") ||
                  "Swap",
              },
              {
                id: "details",
                header: "Details",
                width: "3fr",
                renderer: (data) =>
                  (data.row.productToAdd === undefined &&
                    productToReadableFormat(data.row.productToRemove)) ||
                  (data.row.productToRemove === undefined &&
                    productToReadableFormat(data.row.productToAdd)) ||
                  (data.row.productToAdd !== undefined &&
                    data.row.productToRemove !== undefined &&
                    `IN: ${productToReadableFormat(
                      data.row.productToAdd,
                    )}, OUT: ${productToReadableFormat(data.row.productToRemove)}`),
              },
            ]}
            data={changedProducts || []}
          />
        </Scroller>
      </Flex>

      <Flex justify="right">
        <Button
          isLoading={isInlineLoading(RoutePostMerchflow_merchflowId_FlowUpdate)}
          onClick={next}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};
