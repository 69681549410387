export const RouteGetMerchflow_merchflowId_StorePogTriggerAllFinished =
  "/merchflow/:merchflowId/store-pog-trigger/all-finished";

export type ResponseGetMerchflow_merchflowId_StorePogTriggerAllFinished =
  MerchflowStorePogTrigger[];

export interface MerchflowStorePogTrigger {
  store_pog_trigger_id: number;
  created_at: string;
  trigger_type: "FLOW_UPDATE" | "STORE_PROP";
}
