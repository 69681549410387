import { OptionalPaginationQueryParams, Pagination } from "@CommonTypes/backend/pagination";
import { S2Solution } from "@CommonTypes/merchflow/pog/solution";

export const RouteGetMerchflow_merchflowId_S2Template_templateId_ =
  "/merchflow/:merchflowId/s2/template/:templateId";

export type ParamsGetMerchflow_merchflowId_S2Template_templateId_ = OptionalPaginationQueryParams;

export type ResponseGetMerchflow_merchflowId_S2Template_templateId_ = Pagination<S2SolutionDb>;

export interface S2SolutionDb {
  s2_solution_id: number;
  s2_solution_data: Partial<S2Solution>;
}
