import { useEffect } from "react";
import * as THREE from "three";
import { getPositionRelativeToMesh } from "../store/utils";

interface ItemData {
  uniqueId: string;
  width: number;
  height: number;
  depth: number;
  x: number;
  y: number;
  z: number;
}

interface Props {
  parentMesh: THREE.Mesh;
  item: ItemData;
}

export const Item = ({ parentMesh, item }: Props) => {
  useEffect(() => {
    const geometry = new THREE.BoxGeometry(item.width, item.height, item.depth);
    const material = new THREE.MeshBasicMaterial({
      color: `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
        Math.random() * 255,
      )}, ${Math.floor(Math.random() * 255)})`,
    });

    const itemMesh = new THREE.Mesh(geometry, material);

    const position = getPositionRelativeToMesh({
      mesh: parentMesh,
      width: item.width,
      height: item.height,
      depth: item.depth,
      x: item.x,
      y: item.y,
      z: item.z,
    });

    itemMesh.position.copy(position);

    parentMesh.add(itemMesh);

    return () => {
      parentMesh.remove(itemMesh);
    };
  }, []);

  return null;
};
