import { useSetAtom } from "jotai";
import { newMergeRuleDataAtom } from "./atoms";
import { useModals } from "src/components/Modals";

interface Props {
  availableVariants: string[];
  mergeRules: string[][];
}

export const useNewMergeRuleModal = () => {
  const { openModal } = useModals();
  const setNewMergeRuleModalData = useSetAtom(newMergeRuleDataAtom);

  const openNewMergeRuleModal = (data: Props) => {
    setNewMergeRuleModalData(data);
    openModal("ModalNewMergeRule");
  };

  return { openNewMergeRuleModal };
};
