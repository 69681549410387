import { Highlight } from "@CommonApi/merchflows/_merchflowId_/highlights/base-pogs";
import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import { omniAtom } from "src/utils/atoms";

const prefix = "planogram/extensions";

export const highlightsModeAtom = omniAtom({
  prefix,
  key: "highlights/mode",
  value: false,
});

export const baseHighlightsAtom = omniAtom({
  prefix,
  key: "highlights/base",
  value: null as Highlight[] | null,
});

export const deletedProductAtom = omniAtom({
  prefix,
  key: "deletedProduct",
  value: null as { item: PlanogramItem; storeCodes: string[] } | null,
});

export const newProductAtom = omniAtom({
  prefix,
  key: "newProduct",
  value: null as PlanogramItem | null,
});
